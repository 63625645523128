import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalAddFacilityBook.module.scss';

export const ModalAddFacilityBook = ({...props}: any) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const backToMemberPage = (otherParams: any) => {
    history.push(PrivateRoutes.facilities.list);
    dispatch(
      changeShowModal({
        nameModal: 'modalAddFacilityBook',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  const toMemberRentalPage = (otherParams: any) => {
    history.push(PrivateRoutes.booking.list);
    dispatch(
      changeShowModal({
        nameModal: 'modalAddFacilityBook',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  return (
    <div className={s.modalBox}>
      <div className={s.title}>Done! </div>
      <div className={s.description}>
        The booking was successful. The amount of points automatically deducted
        from the memeber's balance
      </div>
      <div className={s.control}>
        <Button
          text="Back to Facility list"
          type="submit"
          onClick={() => backToMemberPage(otherParams)}
          class_type="secondary"
        />
        <Button
          text="Go to booking list"
          type="submit"
          onClick={() => toMemberRentalPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
