import { AxiosInstance } from "axios";

export type getPromotionT = {
    id: string,
}
export const getPromotion = (axios: AxiosInstance) => ({ ...props }: getPromotionT) => {
    const { id } = props;
    return axios.get(`/facilities/promotions/${id}/`);
}

export const getHeaderSiteOption = (axios: AxiosInstance) => ({ ...props }) => {
    return axios.get(`sites/?no_page`);
}

export type deletePromotionT = {
    id: string,
}
export const deletePromotion = (axios: AxiosInstance) => ({ ...props }: deletePromotionT) => {
    const { id } = props;
    return axios.delete(`/facilities/promotions/${id}/`);
}
export type updatePromotionT = {
    facilityId?: number
    facility_id: number,
    offer: number,
    promoted_from: string,
    promoted_to: string
}

export const updatePromotion = (axios: any) => ({ ...props }: updatePromotionT, id: string) => {
    return axios.patch(`facilities/promotions/${id}/`, { ...props });
}

export type getPromotionByFacilityIdT = {
    params: string,
}
export const getPromotionByFacilityId = (axios: AxiosInstance) => ({ ...props }: getPromotionByFacilityIdT) => {
    const { params } = props;
    return axios.get(`/facilities/promotions/?${params}`);
}