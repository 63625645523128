import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PrivateRoutes } from '../../../../utils/constants/routes';
import { getUrlWithParams } from '../../../../utils/helpersFunction';
import s from './TabNavigation.module.scss';

const navigationItems = [
  {
    path: PrivateRoutes.members.tabComponents?.general,
    title: 'General info',
  },
  {
    path: PrivateRoutes.members.tabComponents?.rental,
    title: 'Rental & Access Details',
  },
  {
    path: PrivateRoutes.members.tabComponents?.paymentHistory,
    title: 'Payment History',
  },
  {
    path: PrivateRoutes.members.tabComponents?.creditMemo,
    title: 'Credit Memo',
  },
  {
    path: PrivateRoutes.members.tabComponents?.points,
    title: 'W+S points',
  },
];

export const TabNavigation = () => {
  const {params} = useRouteMatch();
  const location = useLocation();

  return (
    <div className={s.tab_navigation}>
      {navigationItems &&
        navigationItems.map((item, index) => {
          const pathLink = getUrlWithParams(item.path, params);
          return (
            <Link
              key={item.title}
              className={`${s.tab_link} ${
                pathLink == location.pathname ? s.active : null
              }`}
              to={pathLink}
            >
              {item.title}
            </Link>
          );
        })}
    </div>
  );
};
