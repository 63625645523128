import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addTools } from '../../../api';
import { syncCreditMemo } from '../../../api/SyncCreditMemo';
import { AddToolsForm } from '../../../components/Forms/AddToolsForm/AddToolsForm';
import { AddToolsCreditMemoForm } from '../../../components/Forms/AddToolsForm/SyncCreditMemoForm';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './Invoices.module.scss';

export type initValues = {
  invoices_type: any;
  sync_from: string;
  sync_to: string;
};

export type initValuesCreditMemo = {
  sync_from: string;
  sync_to: string;
};

export const Invoices = () => {
  const history = useHistory();
  const historyCreditMemo = useHistory();
  const dispatch = useDispatch();

  const initValues: initValues = {
    invoices_type: '',
    sync_from: '',
    sync_to: '',
  };

  const initValuesCreditMemo: initValuesCreditMemo = {
    sync_from: '',
    sync_to: '',
  };

  const onSubmit = (history: any) => (values: initValues) => {
    const dataTools = {
      invoices_type: values.invoices_type.value,
      sync_from: moment(values.sync_from).format('YYYY-MM-DD'),
      sync_to: moment(values.sync_to).format('YYYY-MM-DD'),
    };
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const axios = axiosInstance(history);
    addTools(axios)(dataTools)
      .then(async (res) => {
        const siteData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        dispatch(
          changeShowModal({
            nameModal: 'newTools',
            stateModal: true,
            otherParams: siteData,
          }),
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error save Tools');
      });
  };

  const onSubmitCreditMemo = (historyCreditMemo: any) => (values: initValuesCreditMemo) => {
    const dataTools = {
      sync_from: moment(values.sync_from).format('YYYY-MM-DD'),
      sync_to: moment(values.sync_to).format('YYYY-MM-DD'),
    };
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const axios = axiosInstance(historyCreditMemo);
    syncCreditMemo(axios)(dataTools)
      .then(async (res) => {
        const siteData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        dispatch(
          changeShowModal({
            nameModal: 'syncCreditMemo',
            stateModal: true,
            otherParams: siteData,
          }),
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error save Tools');
      });
  };

  return (
    <>
      <div>
        <div className={s.title}>Invoice data sync</div>
        <div className={s.boxForm}>
          <AddToolsForm initialValues={initValues} onSubmit={onSubmit(history)} />
        </div>
      </div>

      <div>
        <div className={s.title}>Credit Memo sync</div>
        <div className={s.boxForm}>
          <AddToolsCreditMemoForm initialValues={initValuesCreditMemo} onSubmit={onSubmitCreditMemo(historyCreditMemo)} />
        </div>
      </div>
    </>
  );
};
