import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    deletePromotion,
    getPromotion,
    updatePromotion,
    updatePromotionT
} from '../../../api';
import { AddNewPromotion } from '../../../components/Forms/AddNewPromotion/AddNewPromotion';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './GetPromotion.module.scss';

const defaultInitValues: any = {
  facilityId: '',
  offer: '',
  promoted_from: '',
  promoted_to: '',
};
export const GetPromotion = ({ ...props }) => {
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();
  const [infoPromotion, setInfoPromotion] = useState();
  const [initValues, setInitValues] = useState(defaultInitValues);

  useEffect(() => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    getPromotion(axios)({ id: id })
      .then((res: any) => {
        setInfoPromotion(res.data);

        setInitValues({
          ...initValues,
          allInfo: res.data,
          facilityId: {
            item: res.data.facility,
            value: res.data.facility.id,
            label: `Small meeting room, Facility ID: ${res.data.facility.id}`,
          },
          offer: String(res.data.offer),
          promoted_to: new Date(moment(res.data.promoted_to, "YYYY-MM-DD").toDate()),
          promoted_from: new Date(moment(res.data.promoted_from, "YYYY-MM-DD").toDate()),
        });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        console.error('Promotion not found');
      });
  }, []);

  const deletePromotions = ({ values }: any) => {
    dispatch(
      changeShowModal({
        nameModal: 'deletePromotion',
        stateModal: true,
        otherParams: { values, deleteFunc: deletePromotion(axios) },
      }),
    );
  };
  const onUpdate = ({ ...values }: updatePromotionT, id: string) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const facility: any = values['facilityId'];
    const infoPromotion: any = {
      facility_id: facility.item.id,
      offer: Number(values['offer']),
      promoted_from: moment(values['promoted_from']).format('YYYY-M-DD'),
      promoted_to: moment(values['promoted_to']).format('YYYY-M-DD'),
    };
    updatePromotion(axios)(infoPromotion, id)
      .then(async (res: { data: any }) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        const userData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error updating Promotion info ');
      });
  };

  return (
    <>
      {' '}
      {!!initValues['facilityId'] && (
        <div>
          <div className={s.title}>Promo # {id}</div>
          <div className={s.boxForm}>
            <AddNewPromotion
              type="edit"
              infoPromotion={infoPromotion}
              initialValues={initValues}
              defaultValue={JSON.parse(JSON.stringify(initValues))}
              onDelete={deletePromotions}
              onSubmit={(props: any) => {
                onUpdate({ ...props }, id);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
