import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteDoorInput.module.scss';

type otherParamsT = {
  deleteFunc: any;
  id: string;
  data: any;
  refresh?: any;
};

export const ModalDeleteDoorInput = ({...props}) => {
  // Create an instance of Notyf
  const notyf = new Notyf();

  const {otherParams} = props;
  const dispatch = useDispatch();
  const history: any = useHistory();

  const onBack = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteDoorInput',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const deleteDoor = (otherParams: any) => {
    const {deleteFunc, refresh, data}: otherParamsT = otherParams;
    dispatch(
      changeShowModal({
        nameModal: 'deleteDoorInput',
        stateModal: false,
        otherParams: undefined,
      }),
    );
    deleteFunc(data.id);
    popUp({
      props: 'The action was successful',
      type: 'success',
      isCustomMessage: true,
    });
    if (refresh != undefined) {
      refresh();
    }
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to delete this Door Number?
      </div>
      <div className={s.description}>
        This Door Number will be deleted immediately. You can not undo this
        action
      </div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onBack()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete Door Number"
          type="submit"
          onClick={() => deleteDoor(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
