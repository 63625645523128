import { Form, Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { WPSLogoWhite } from "../../assets/WPSLogoWhite";
import { Button } from "../../components/FormComponents/Button/Button";
import { Input } from "../../components/FormComponents/Input/Input";
import { resetMemberPassword } from "../../utils/utils";
import s from "./PasswordReset.module.scss";
import React, { useState } from "react";
import { Lock } from "../../assets/Lock";

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  password_repeat: Yup.string()
    .required("Password repeat is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const initValues = {
  password: "",
  password_repeat: "",
};

const PasswordReset = () => (
  <Form>
    <div className={s.form}>
      <div className={s.inputs_group}>
        <div className={s.password_field}>
          <Input
            type="password"
            name="password"
            placeholder="Enter new password"
            title="New Password"
          />
        </div>
        <div className={s.input_warning}>
          <Lock />
          <span>
            Minimum of 6 characters, with upper and lowecase and a number, or a
            symbol.
          </span>
        </div>
        <div className={s.password_field}>
          <Input
            type="password"
            name="password_repeat"
            placeholder="•••••••••"
            title="Repeat new password"
          />
        </div>
        <div className={s.input_warning}>
          <span>Both passwords must match.</span>
        </div>
      </div>
      <div className={s.reset_password_button}>
        <Button text="Set new password" type="submit" class_type="main" />
      </div>
    </div>
  </Form>
);

export const PasswordResetPage = () => {
  const history = useHistory();
  const [passIsReset, setPassIsReset] = useState(false);
  const { uid, token } = useParams<{ uid: string; token: string }>();
  const onSubmit = (history: any) => async (values: any) => {
    const checkUpdate = await resetMemberPassword(
      values,
      uid,
      token,
      history,
      setPassIsReset
    ).then((res: boolean) => {
      // console.log("res: ", res);
      // console.log("passIsReset: ", passIsReset);
      // setPassIsReset(true);
    });
  };

  return (
    <div className={s.wrap}>
      <div className={s.sign_in_window}>
        <div className={`${s.header} ${passIsReset && s.active}`}>
          <WPSLogoWhite width={271} height={45} />
          <div className={`${s.welcome_message} ${passIsReset && s.remove}`}>
            Password reset page.
          </div>
        </div>
        {passIsReset ? (
          <>
            <div className={s.title}>Password successfully changed</div>
            <div className={s.description}>
              Please note that link that you received through email is not
              reusable. Once you click on it you won't be able to use it again
              for password recovery.
            </div>
          </>
        ) : (
          <Formik
            validateOnChange
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit(history)}
          >
            <PasswordReset />
          </Formik>
        )}
      </div>
    </div>
  );
};

export default PasswordResetPage;
