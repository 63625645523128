import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { CirclingArrow } from '../../../assets/CirclingArrow';
import { Power } from '../../../assets/Power';
import { Button } from '../../FormComponents/Button/Button';
import { Checkbox } from '../../FormComponents/Checkbox/Checkbox';
import { Input } from '../../FormComponents/Input/Input';
import s from '../style/formStyle.module.scss';

type AddMemberFromT = {
  initValues: any;
  onSubmit: any;
  onDeactivate?: any;
  onUpdate?: any;
  onReset?: any;
  type?: string;
  infoMember?: any;
  [key: string]: any;
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is a required field')
    .email('Not a valid email'),
  first_name: Yup.string().required('First name is a required field'),
  // last_name: Yup.string().required('Last name is a required field'),
  // salesforce_id: Yup.string().required('Salesforce id is a required field'),
  phone_number: Yup.string().required('Phone Number is a required field'),
  // correspondence_address: Yup.string().required(
  //   'Correspondence address is a required field',
  // ),
  can_see_facilities_from_other_sites: Yup.boolean(),
});

export const AddMemberForm = ({ ...props }: AddMemberFromT) => {
  const {
    initValues,
    defaultValue,
    onSubmit,
    onDeactivate,
    type,
    infoMember,
    onUpdate,
    onReset,
  } = props;
  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
  const [dataMember, setdataMember] = useState(infoMember);
  const isEdit = type === 'edit';
  const [isReset, setIsReset] = useState(false);
  const [password, setPassword] = useState('');

  const onResetPassword = async (id: any) => {
    var length = 10,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    password = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    // let pass = await generatePassword();
    setPassword(password)
    setIsReset(true)
    onReset({id, password});
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      infoMember={dataMember}
    >
      {({ values, ..._ }) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        return (
          <Form>
            <div className={s.form}>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <Input
                    type="text"
                    placeholder="Enter User's first name"
                    name="first_name"
                    title="First Name *"
                    typeEdit={isEdit}
                  />
                </div>
                <div className={s.InputBox}>
                  <Input
                    type="text"
                    placeholder="Enter User's last name"
                    name="last_name"
                    title="Last Name"
                    typeEdit={isEdit}
                  />
                </div>
              </div>
              <div className={s.InputBox}>
                <Input
                  type="text"
                  placeholder="Enter Correct Salesforce ID"
                  name="salesforce_id"
                  title="Salesforce ID"
                  typeEdit={isEdit}
                />
              </div>
              <div className={s.InputBox}>
                <Input
                  type="text"
                  placeholder="Enter Correspondence Address"
                  name="correspondence_address"
                  title="Correspondence Address"
                  typeEdit={isEdit}
                />
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>

                  <Input
                    type="text"
                    placeholder="example@gmail.com"
                    name="email"
                    title="Email Address *"
                    disableEdit={true}
                    typeEdit={isEdit}
                  />
                </div>
                <div className={s.InputBox}>
                  <Input
                    type="number"
                    placeholder="+044 ___ __ __"
                    name="phone_number"
                    title="Phone Number *"
                    typeEdit={isEdit}
                  />
                </div>
              </div>
              <div className={s.InputBox}>
                <Input
                  type="text"
                  placeholder="Enter User’s Company Name"
                  name="company_name"
                  title="Company Name"
                  typeEdit={isEdit}
                />
              </div>
              {isReset ? 
                <div className={s.InputBox}>
                  <Input
                    type="text"
                    placeholder="Password"
                    name="password"
                    title="Password"
                    value={password}
                    typeEdit={isEdit}
                  />
                </div>
              : ""}
              

              <div className={s.bottomFormContainer}>
                <div className={s.InputBox}>
                  <Checkbox
                    title="Allow user to browse & book facilities in other sites"
                    name="can_see_facilities_from_other_sites"
                  />
                </div>
                {!isEdit && (
                  <div className={`${s.InputBox} ${s.user_activated_checkbox}`}>
                    <Checkbox
                      title="Activate immediately"
                      name="activated"
                    />
                  </div>
                )}

                <div className="ButtonBox">
                  {isEdit ? (
                    <div className={s.groupButton}>
                      <Button
                        text="Save Changes"
                        type="submit"
                        class_type={isSaveChangeParams ? 'main' : 'disabled'}
                        onClick={() => {
                          onUpdate({ ...initValues, ...values }, infoMember.id);
                        }}
                      />

                      <Button
                        left_icon={<CirclingArrow />}
                        text="Reset Password"
                        type="submit"
                        class_type="secondary"
                        onClick={() => {
                          // onReset(infoMember.id);
                          onResetPassword(infoMember.id);
                        }}
                      />
                      <Button
                        left_icon={<Power />}
                        text={
                          infoMember.is_active
                            ? 'Deactivate user'
                            : 'Activate user'
                        }
                        class_type={infoMember.is_active ? 'red' : 'green'}
                        onClick={() => {
                          onDeactivate({
                            id: infoMember.id,
                            is_active: infoMember.is_active,
                          });
                          infoMember.is_active = !infoMember.is_active;
                          setdataMember({
                            ...dataMember,
                            is_active: !infoMember.is_active,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <Button
                      text="Add rental & role"
                      type="submit"
                      class_type="main"
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddMemberForm;
