import { Form, Formik } from "formik";
import moment from "moment";
import { equals } from "ramda";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { saveFacilitiesT } from "../../../api";
import { DeleteIcon } from "../../../assets/DeleteIcon";
import { getHeaderInfo } from "../../../store/selectors/selectors";
import axiosInstance from "../../../utils/axios";
import { AsyncSelect } from "../../FormComponents/AsyncSelect/AsyncSelect";
import { Button } from "../../FormComponents/Button/Button";
import { Checkbox } from "../../FormComponents/Checkbox/Checkbox";
import { DataPicker } from "../../FormComponents/DataPicker/DataPicker";
import { Input } from "../../FormComponents/Input/Input";
import { InputFile } from "../../FormComponents/InputFile/InputFile";
import { InputNotEdit } from "../../FormComponents/InputNotEdit/InputNotEdit";
import s from "./AddNewAdvertising.module.scss";

type AddNewAdvertisingT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidationSchema: any = () => {
  return Yup.object({
    title: Yup.string().required("The field is required"),
    link: Yup.string().required("The field is required"),
    promoted_from: Yup.string().required("The field is required"),
    promoted_to: Yup.string().required("The field is required"),
    ads_image: Yup.array().required("The field is required"),
    sites_list: Yup.array().when("allSites", (allSites): any => {
      if (!allSites) {
        return Yup.array().required("The field is required");
      }
    }),
  });
};

export const AddNewAdvertising = ({ ...props }: AddNewAdvertisingT) => {
  const {
    initialValues,
    defaultValue,
    onSubmit,
    onDelete,
    type,
    infoPromotion,
  } = props;
  const { siteData } = useSelector(getHeaderInfo);
  const history = useHistory();
  const axios = axiosInstance(history);

  const [startDate, setStartDate] = useState(initialValues.promoted_from);
  const [endDate, setEndDate] = useState(initialValues.promoted_to);
  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
  const isEdit = type === "edit";
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
      infoPromotion={infoPromotion}
    >
      {({ values, ...formikProps }) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
            promoted_from: new Date(
              moment(defaultValue?.promoted_from).toDate()
            ),
            promoted_to: new Date(moment(defaultValue?.promoted_to).toDate()),
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        return (
          <Form>
            <div className={s.form}>
              <div className={s.InputBox}>
                <div className={s.title}>Promo Picture</div>
                <div className={s.subtitle}>Add a picture for advertising</div>
                <InputFile
                  placeholder=""
                  name="ads_image"
                  typeEdit={isEdit}
                  typeFile="image"
                  initialValues={initialValues.ads_image}
                  setValue={formikProps.setFieldValue}
                />
              </div>

              <div className={s.InputBox}>
                <div className={s.subtitle}>LINK</div>
                <Input
                  type="text"
                  placeholder=""
                  name="link"
                  typeEdit={isEdit}
                />
              </div>
              <div className={`${s.InputBox}`}>
                <div className={s.title}>Sites list</div>
                <div className={s.subtitle}>
                  SELECT SITES WHERE THE PROMOTIONAL ADS SHOULD APPEAR *
                </div>
                {isEdit ? (
                  <span
                    title={`${isEdit && "this field cannot be edited"}`}
                    className={`${isEdit && s.notEdit}`}
                  >
                    <InputNotEdit
                      value={initialValues.sites_list[0]}
                      isInteracts={true}
                    />
                  </span>
                ) : (
                  <>
                    <div
                      className={`${s.site__wrapper} ${
                        values.allSites && s.active
                      }`}
                    >
                      <AsyncSelect
                        noOptionsMessage={() => "No data found"}
                        loadOptions={() => ({
                          options: siteData,
                        })}
                        loadingMessage={() => "loading Site ..."}
                        placeholder=""
                        name="sites_list"
                        isMulti
                        title={initialValues.sites_list.label}
                        typeEdit={isEdit}
                      />
                    </div>
                    <Checkbox
                      defaultChecked={false}
                      name="allSites"
                      title="Select All Sites"
                    />
                  </>
                )}
              </div>
              <div className={s.InputBox}>
                <div className={s.title}>Promo Title</div>
                <div className={s.subtitle}>SITE ADVERTISING OFFER *</div>
                <Input
                  type="text"
                  placeholder=""
                  name="title"
                  typeEdit={isEdit}
                />
              </div>

              <div className={`${s.lineParams} ${s.data__wrapped}`}>
                <div className={`${s.title} ${s.line_title}`}>
                  CAMPAIGN PERIOD
                </div>

                <div className={s.InputBox}>
                  <div className={s.subtitle}>FROM *</div>
                  <DataPicker
                    placeholderText=""
                    name="promoted_from"
                    dateFormat="yyyy-M-dd"
                    selected={startDate}
                    onChangeCustom={setStartDate}
                    startDate={startDate}
                    maxDate={endDate}
                    endDate={endDate}
                    typeEdit={isEdit}
                  />
                </div>

                <div className={s.InputBox}>
                  <div className={s.subtitle}>TO *</div>
                  <DataPicker
                    placeholderText=""
                    name="promoted_to"
                    dateFormat="yyyy-M-dd"
                    onChangeCustom={setEndDate}
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    typeEdit={isEdit}
                  />
                </div>
              </div>

              <div className={s.button}>
                {isEdit ? (
                  <div className={s.groupButton}>
                    <Button
                      text="Publish Promo Advertising"
                      type="submit"
                      class_type={isSaveChangeParams ? "main" : "disabled"}
                    />
                    <Button
                      left_icon={<DeleteIcon />}
                      text="Delete Advertising"
                      onClick={() => onDelete()}
                      class_type="red"
                    />
                  </div>
                ) : (
                  <Button
                    text="Publish Promo Advertising"
                    type="submit"
                    class_type="main"
                  />
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
