import { AxiosInstance } from "axios";

export type getMemberT = {
  id: string,
}

export const getMember = (axios: AxiosInstance) => ({ ...props }: getMemberT) => {
  const { id } = props;
  return axios.get(`/users/${id}/`);
}

export const getMembers = (axios: AxiosInstance) => () => {
  return axios.get('/users/')
}

export type saveMemberT = {
  /* eslint-disable camelcase */
  email: string
  first_name: string
  last_name: string
  salesforce_id: string
  company_name: string
  phone_number: string
  correspondence_address: string
  /* eslint-enable camelcase */
}



export const addMember = (axios: AxiosInstance) => ({ ...props }: saveMemberT) => {
  return axios.post(`/users/`, { ...props });
}


export type deleteMemberT = {
  id: string,
}

export const deleteMember = (axios: AxiosInstance) => ({ ...props }: deleteMemberT) => {
  const { id } = props;
  return axios.delete(`/users/${id}/`);
}

export type switchMemberIsActiveT = {
  id: string,
  // eslint-disable-next-line camelcase
  is_active: boolean,
}

export const switchMemberIsActive = (axios: AxiosInstance) => ({ ...props }: switchMemberIsActiveT) => {
  const { id, is_active } = props;
  return axios.patch(`/users/${id}/`, { is_active: !is_active });
}

export type updateMemberT = {
  /* eslint-disable camelcase */
  email?: string
  first_name?: string
  last_name?: string
  salesforce_id?: string
  company_name?: string
  phone_number?: string
  correspondence_address?: string
  can_see_facilities_from_other_sites?: false,
  /* eslint-enable camelcase */
}

export const updateMember = (axios: AxiosInstance) => ({ ...props }: updateMemberT, id: string) => {
  return axios.patch(`/users/${id}/`, { ...props });
}

export type memberResetPasswordT = {
  id: string,
  password: string
}

export type resetPasswordConfirmT = {
  email: string
}

export const resetPasswordConfirm = (axios: AxiosInstance) => ({ ...props }: resetPasswordConfirmT) => {
  return axios.post(`/users/reset_password/`, { ...props });
}

export const memberResetPassword = (axios: AxiosInstance) => ({ ...props }: memberResetPasswordT) => {
  const { id, password } = props;
  return axios.post(`/users/${id}/send_new_password/`,{ password: password });
}

type getMemberPointsT = {
  id: string
}
export const getMemberPoints = (axios: AxiosInstance) => ({ ...props }: getMemberPointsT) => {
  const { id } = props;
  return axios.get(`/points/balance/${id}/`);
}

export type ChangeMemberPointsT = {
  user_points_id: number;
  operation: '+' | '-';
  amount: number;
  description?: string;
}

export const ChangeMemberPoints = (axios: AxiosInstance) => ({ ...props }: ChangeMemberPointsT) => {
  return axios.post(`/points/transactions/`, { ...props });
}

type deleteRentalDetailsT = {
  id: string
}

export const deleteRentalDetails = (axios: any) => ({ ...props }: deleteRentalDetailsT) => {
  const { id } = props;
  return axios.delete(`/units/rental/${id}/`);
}


type getRentalDetailsT = {
  id: number
}
export const getRentalDetails = (axios: any) => ({ ...props }: getRentalDetailsT) => {
  const { id } = props;
  return axios.get(`/units/rental/?user__id=${id}`);
}



type getRentalTenantInfoT = {
  id: string
}
export const getRentalTenantInfo = (axios: any) => ({ ...props }: getRentalTenantInfoT) => {
  const { id } = props;
  return axios.get(`/units/rental/?user_role=TE&search=${id}`);
}


export type updateRentalDetailsT = {
  site: string;
  role: string;
  unit_id: string;
  doors_numbers: string;
  leased_from: string;
  leased_to: string;
  details?: string;
}

export const updateRentalDetails = (axios: any) => ({ ...props }: updateRentalDetailsT, id: number) => {
  return axios.patch(`units/rental/${id}/`, { ...props });
}


export type createRentalDetailsT = {
  site: string;
  role: string;
  unit_id: number;
  doors_numbers: string;
  leased_from: string;
  leased_to: string;
  details?: string;
}

export const createRentalDetails = (axios: any) => ({ ...props }: createRentalDetailsT) => {
  return axios.post(`units/rental/`, { ...props });
}


type getRentalUnitOptionT = {
  page_number: string,
  search: string
}
export const getRentalUnitOption = (axios: any) => ({ page_number, search = '' }: getRentalUnitOptionT) => {

  return axios.get(`units/rental/?search=${search}&page_number=${page_number}&future_and_active&user_role=TE`,
    {
      params: {
        page: page_number,
        search: search,
      }
    });

}
