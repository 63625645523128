import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { Arrow } from '../../../assets/Arrow';
import { HeaderUpdateSelect } from '../../../store/actions/SideMenu';
import { getHeaderInfo } from '../../../store/selectors/selectors';
import s from './SiteSelectorDropDown.module.scss';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.menuIsOpen ? '1px solid #E8E8EF' : '1px solid #FFFFFF',
    borderBottom: 'none',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: state.isSelected
      ? '0px 4px 4px rgba(168, 168, 205, 0.3);'
      : 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    minHeight: '48px',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    boxShadow: '0px 4px 4px rgba(168, 168, 205, 0.3)',

    border: '1px solid #E8E8EF',
    borderRadius: '8px',
    borderTop: 'none',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#9E00FF',
    display: 'inline',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,

    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    transition: '.3s',
  }),
  option: (provided: any) => ({
    ...provided,
    transition: '.3s',
    color: '#7A7A96',
    background: '#FFFFFF',
    '&:hover': {
      background: '#F7F7F7',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#9E00FF',
  }),
};

const CustomValueContainer = ({children, ...props}: any) => (
  <components.ValueContainer {...props}>
    <div className={s.value_container}>
      <div className={s.label}>Site:</div>
      <div className={s.selected_value}>{children}</div>
    </div>
  </components.ValueContainer>
);

const CustomOption = (props: any) => {
  const {isSelected} = props;

  if (isSelected) return null;
  return <components.Option {...props} />;
};

const CustomDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Arrow />
  </components.DropdownIndicator>
);

export const SiteSelectorDropDown = () => {
  const {selected, siteData} = useSelector(getHeaderInfo);
  const dispatch = useDispatch();
  const selectAllOption = {
    label: 'All sites',
    value: 'all',
  };
  const selectOptions = [...[selectAllOption], ...siteData];

  function changeSelect(e: any) {
    const selected = e;
    dispatch(
      HeaderUpdateSelect({
        selected: {
          value: selected.value,
          label: selected.label,
        },
      }),
    );
  }

  useEffect(() => {
    if (selected.value == 'all' || selected.value == '') {
      changeSelect(selectAllOption);
    }
  }, []);
  return (
    <div className={s.dropdown}>
      {selected.value != '' && (
        <Select
          // {...props}
          onChange={(e) => changeSelect(e)}
          defaultValue={selected}
          // defaultValue={{
          //   value: '18 New Industrial Road',
          //   label: '18 New Industrial Road'
          // }}
          styles={customStyles}
          options={selectOptions}
          components={{
            Option: CustomOption,
            DropdownIndicator: CustomDropdownIndicator,
            ValueContainer: CustomValueContainer,
          }}
        />
      )}
    </div>
  );
};

export default SiteSelectorDropDown;
