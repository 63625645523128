export const TYPE_REDUCER = {
  EXAMPLE_ACTION: '[config]::example action',
  SAVE_SESSION_TOKEN: '[auth]::store_session_token',
  SELECT_SIDE_MENU: '[sideMenu]::select_side_menu',
  CHANGE_SHOW_MODALS: '[configuration]::show/hidden modals',
  DELETE_PROMOTION: '[configuration===promotion]::delete promotion',
  UPDATE_ROWS: '[promotionList]::update rows',
  VIRTUAL_FETCH: '[promotionList]::fetching data from server',
  CHANGE_PAGE_NUMBER: '[promotionList]::change page',
  START_LOADING: '[promotionList]::start loading',
  FETCH_INIT: '[promotionList]::init first query',
  UPDATE_PREV_QUERY: '[promotionList]::prev pagination',
  UPDATE_QUERY: '[promotionList]::update query',
  UPDATE_NEXT_QUERY: '[promotionList]::next pagination',
  HEADER_UPDATE_SELECT: '[header]::update header active select',
  HEADER_UPDATE_SEARCH: '[header]::update header search query',
  HEADER_GET_SITE_DATA: '[header]::get header site data options',
  MEMBERS_INFO: '[header]::save members info data',
  CURRENT_DATA_INFO: '[table]::save current data info',
};

// export const BACK_END_API_ENDPOINT = "https://wps.dev2ezasia.com/api";
export const BACK_END_API_ENDPOINT = "https://wpsapp.workstore.com.sg/api";