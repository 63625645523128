import { format } from 'date-fns';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import TimeRange from 'react-timeline-range-slider';
import s from './TimePicker.module.scss';
import './TimePicker.scss';
export type TimePickerT = {
  title: string;
  name: string;
  excludeTimes: any;
  TotalHour?: any;
  setTotalHour?: any;
  firstLoadHour: any;
  selectedIterval: any;
};

type errorHandlerT = {
  error: boolean;
  time: any;
};

export const TimePicker = ({ ...props }: TimePickerT) => {
  const { title, name, excludeTimes, TotalHour, setTotalHour, firstLoadHour, selectedIterval } = props;
  const [field, meta, otherParams, , ...propsVa] = useField(name);
  const [interval, setInterval] = useState(selectedIterval);
  const [info, setInfo] = useState({
    error: true,
    start: format(interval[0], 'HH-mm-ss'),
    end: format(interval[1], 'HH-mm-ss'),
  });

  let error = null;
  let isError = false;

  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }

  const errorHandler = ({ ...props }: errorHandlerT) => {
    const { error, time } = props;
    setInfo({
      error,
      start: format(time[0], 'HH-mm-ss'),
      end: format(time[1], 'HH-mm-ss'),
    });
  };
  const onChangeCallback = (selectedInterval: any) => {
    const start = Number(format(selectedInterval[0], 'HH'));
    const end = Number(format(selectedInterval[1], 'HH'));
    setInterval(selectedInterval);
    setTotalHour({
      ...TotalHour,
      hours: end - start,
    });
  };

  useEffect(() => {
    if (!info.error) {
      otherParams.setValue({
        start: info.start,
        end: info.end,
      });
    } else {
      otherParams.setValue('');
    }
  }, [interval]);




  return (
    <div className={s.time__block}>
      <div className={s.input_title}>
        {title}
      </div>

      <TimeRange
        containerClassName={`${s.time_container} ${info.error && 'error'
          } time_container`}
        error={info.error}
        step={3600000}
        ticksNumber={36}
        selectedInterval={interval}
        timelineInterval={firstLoadHour}
        onUpdateCallback={errorHandler}
        onChangeCallback={onChangeCallback}
        disabledIntervals={excludeTimes}
      />
      <div className={`${s.hint} ${isError ? s.error : null}`}>
        {isError && error}
      </div>
    </div>
  );
};
