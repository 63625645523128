import React, { useState } from 'react';
import s from '../style/formStyle.module.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../FormComponents/Input/Input';
import { equals } from 'ramda';
import { Button } from '../../FormComponents/Button/Button';
import { TextArea } from '../../FormComponents/Input/TextArea';

const validationSchema = Yup.object({
    question: Yup.string().required('Question is a required field'),
    response: Yup.string().required('Answer is a required field')
})

export const AddHelpForm = (props: any) => {
    const {
        onSubmit,
        type,
        infoMember,
    } = props;
    const initValues = {
        question: '',
        response: ''
    }
    const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
    const isEdit = type === 'edit';

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            infoMember={infoMember}
        >
            {({ values, ..._ }) => {
                if (isEdit) {
                    const defaultTest = {
                        ...initValues,
                    };

                    const similarObjects: boolean = equals(values, defaultTest);

                    if (!similarObjects && !isSaveChangeParams)
                        setIsSaveChangeParams(true);
                    if (similarObjects && isSaveChangeParams)
                        setIsSaveChangeParams(false);
                }

                return (
                    <Form>
                        <div className={s.form}>
                            <div className={s.InputBox}>
                                <Input
                                    type="text"
                                    placeholder="Write a question that users will be able to see in mobile app"
                                    name="question"
                                    title="Question *"
                                    typeEdit={isEdit}
                                />
                            </div>
                            <div className={s.InputBox}>
                                <TextArea
                                    type="text"
                                    placeholder="Give an answer to your question"
                                    name="response"
                                    title="Answer *"
                                    typeEdit={isEdit}
                                />

                            </div>
                        </div>
                        <div className={s.ButtonBox}>
                            <Button
                                text="Publish FAQ"
                                type="submit"
                                class_type="main"
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    )
}