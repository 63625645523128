import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';
import style from './ModalMemberResetPassword.module.scss';

export const ModalMemberResetPassword = ({ ...props }: any) => {
    const { otherParams } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const backToMemberPage = (otherParams: any) => {
        history.push(`/members/${otherParams['id']}/general`);
        dispatch(changeShowModal({ 'nameModal': "memberResetPassword", 'stateModal': false, otherParams: undefined }))
    }


    return (
        <div className={s.modalBox}>
            <div className={s.title}>Done!</div>
            <div className={s.description}>We have sent a new access code to the user's email address.</div>
            <div className={`${s.control} ${style.control}`}>
                <Button text="Back to Member’s Details" type="submit" class_type="main"
                    onClick={() => backToMemberPage(otherParams)}
                />
            </div>
        </div>
    )
}
