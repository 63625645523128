import { INotyfNotificationOptions, Notyf } from 'notyf';
import * as R from 'ramda';

export const numberWithCommas = (x: any): any => {
  return String(x.toFixed(3));
}

export const getPrice = (price: number): string => numberWithCommas(price / 100);

export const getDependsProp = (arrayProps: any, dependencyArray: any) => (
  arrayProps.filter((prop: any) => R.includes(prop.id, dependencyArray)));


export function getTruePathName(pathName: string) {
  let result = ''
  let removeExcessSlash;

  const lastSymbol = pathName.slice(-1);
  if (lastSymbol == '/') {
    removeExcessSlash = pathName.substr(0, pathName.lastIndexOf('/'));
  } else {
    removeExcessSlash = pathName;
  }

  let resultArray = removeExcessSlash.split('/')

  resultArray.map((pathItem) => {
    let CheckIdPage = Number.isInteger(
      Number(pathItem),
    );
    if (CheckIdPage && pathItem != '') {
      result += '/:id'
    } else if (pathItem != '') {
      result += `/${pathItem}`
    }
  })
  return result;
}

export function getUrlWithParams(path: any, params: any): string {
  let result: string = '';
  let paramsArray = Object.keys(params)
  paramsArray.map((paramName): any => {
    result = path.replace(`:${paramName}`, params[paramName]);
  })

  return result;
}

type popUpT = {
  props: any,
  type?: 'error' | 'success',
  isCustomMessage?: boolean
}

export const popUp = ({ ...data }: popUpT) => {
  const { props, type = 'error', isCustomMessage = false } = data;
  const notyf = new Notyf({
    duration: 5000,
  });
  const validErrorData = !isCustomMessage ? props[0] ? props[0].response.data : props.response.data : null;
  const notification = isCustomMessage ? props : validErrorData;
  const checkTypeMessage = typeof notification;
  let message: any = [];
  if (notification && checkTypeMessage == 'string' && !isCustomMessage) {
    message = notification.length > 50 ? notification.substring(0, 50 - 3) + '...' : notification
  } else if (notification && !isCustomMessage) {
    Object.keys(notification).map((error: string) => {
      message.push(notification[error])
    });
  } else if (isCustomMessage) {
    message = notification
  }
  const returnErrorMessage = () => {
    if (checkTypeMessage == 'string') {
      notyf.error(message);
    } else {
      message.map((item: string | Partial<INotyfNotificationOptions> | any) => {
        const checkItemType = typeof item;
        if (checkItemType == 'string') {
          notyf.error(item);
        } else {
          item.map((subItem: string | Partial<INotyfNotificationOptions>) => {
            notyf.error(subItem);
          })
        }
      })
    }
  }
  const returnSuccessMessage = () => {
    if (checkTypeMessage == 'string') {
      notyf.success(message);
    } else {
      message.map((item: string | Partial<INotyfNotificationOptions>) => {
        notyf.success(item);
      })
    }
  }
  if (type == 'error') {
    returnErrorMessage()
  } else if (type == 'success') {
    returnSuccessMessage()
  }
};
