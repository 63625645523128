import moment from 'moment';
import { Notyf } from 'notyf';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    deleteAdvertising,
    getAdvertisingById,
    updateAdvertisingApi
} from '../../../api';
import { AddNewAdvertising } from '../../../components/Forms/AddNewAdvertising/AddNewAdvertising';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './GetAdvertising.module.scss';

export const GetAdvertising = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const axios = axiosInstance(history);
  const [isShowForm, setIsShowForm]: any = useState(false);
  const notyf = new Notyf({
    duration: 5000,
  });

  const deleteAdvertisingHuddler = (id: any) => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteAdvertising',
        stateModal: true,
        otherParams: { id, deleteFunc: deleteAdvertising(axios) },
      }),
    );
  };

  const updateAdvertising = (props: any) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const sitesList: any[] = [];
    props.sites_list.map((site: { value: any }) => {
      sitesList.push(site.value);
    });
    const formData = new FormData();
    formData.append('title', props.title);
    formData.append('link', props.link);

    if (props.ads_image[0].oldPhoto != true) {
      formData.append('ads_image', props.ads_image[0], props.ads_image[0].name);
    }

    formData.append(
      'promoted_from',
      moment(props.promoted_from).format('YYYY-M-DD'),
    );
    formData.append(
      'promoted_to',
      moment(props.promoted_to).format('YYYY-M-DD'),
    );
    // formData.append('sites_list', sitesList.join(','));

    updateAdvertisingApi(axios)({ formData, id })
      .then((res: any) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        dispatch(
          changeShowModal({
            nameModal: 'newAdvertising',
            stateModal: true,
            otherParams: res.data,
          }),
        );
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error get advertising');
      });
  };

  const initialValues: any = {
    title: '',
    link: '',
    ads_image: [{ name: '' }],
    promoted_from: '',
    promoted_to: '',
    sites_list: '',
  };

  const [initValues, setInitValues] = useState(initialValues);

  useEffect(() => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    getAdvertisingById(axios)({ id: id })
      .then((res: any) => {
        const advertisingData = res.data;
        setInitValues({
          ...initValues,
          ...{
            title: advertisingData.title,
            link: advertisingData.link,
            promoted_from: new Date(
              moment(advertisingData.promoted_from).toDate(),
            ),
            sites_list: [advertisingData.site_target_str],
            promoted_to: new Date(moment(advertisingData.promoted_to).toDate()),
            ads_image: [{ name: advertisingData.ads_image, oldPhoto: true }],
          },
        });
        setIsShowForm(true);
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        console.error('advertising not found');
      });
  }, []);

  return (
    <div>
      <div className={s.title}>Sites Advertising №{id}</div>
      <div className={s.boxForm}>
        {isShowForm && (
          <AddNewAdvertising
            type="edit"
            initialValues={initValues}
            defaultValue={JSON.parse(JSON.stringify(initValues))}
            onSubmit={(props: any) => updateAdvertising(props)}
            onDelete={(props: any) => deleteAdvertisingHuddler(id)}
          />
        )}
      </div>
    </div>
  );
};
