import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const AddNotificationModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const backToSitesPage = () => {
        history.push(`/notifications`);
        dispatch(changeShowModal({ 'nameModal': "addNotification", 'stateModal': false, otherParams: undefined }))
    }
    
    return (
        <div className={s.modalBox}>
            <div className={s.title}> Done! </div>
            <div className={s.description}>Push-notification is sent to User’s app successfully</div>
            <Button text="Back to Notifications list" type="submit" class_type="secondary" onClick={() => backToSitesPage()} />
        </div>
    )
}
