import { AxiosInstance } from "axios"

export type getSiteT = {
  id: string
}

export const getSite = (axios: AxiosInstance) => ({ id }: getSiteT) => {
  return axios.get(`/sites/${id}/`)
}

type deleteSiteT = {
  id: string
}

export const deleteSite = (axios: AxiosInstance) => ({ id }: deleteSiteT) => {
  return axios.delete(`/sites/${id}/`)
}

export type saveSiteT = {
  name: string,
  code: string,
  database_id: string,
  braintree_merchant_id: string,
  status: any
}

export const addSite = (axios: AxiosInstance) => (props: saveSiteT) => {
  return axios.post(`/sites/`, { ...props });
}

export type updateSiteT = {
  name?: string,
  code?: string,
  database_id?: string,
  braintree_merchant_id?: string,
  status: any
}

export const updateSite = (axios: AxiosInstance) => (id: string, body: updateSiteT) => {
  return axios.patch(`/sites/${id}/`, body)
}
