import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { resetPasswordConfirm } from '../../api';
import { WPSLogoWhite } from '../../assets/WPSLogoWhite';
import { Button } from '../../components/FormComponents/Button/Button';
import { Input } from '../../components/FormComponents/Input/Input';
import { changeShowModal } from '../../store/actions/SideMenu';
import axiosInstance from '../../utils/axios';
import { popUp } from '../../utils/helpersFunction';
import s from './ResetRequestPage.module.scss';

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .email('Must be valid email'),
});

const initValues = {
  email: '',
};

const SignInForm = () => (
  <Form>
    <div className={s.form}>
      <div className={s.inputs_group}>
        <Input
          type="text"
          name="email"
          placeholder="Enter your Email"
          title="Your email"
        />
      </div>
      <div className={s.login_button}>
        <Button text="Email me a recovery link" type="submit" class_type="main" />
      </div>
    </div>
  </Form>
);

export const ResetRequestPage = () => {
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();
  const [resetAccess, setResetAccess] = useState(false)


  const onSubmit = (values: any) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    resetPasswordConfirm(axios)({ email: values.email })
      .then(async (res: { statusText: any; }) => {
        const responce = await res.statusText;
        setResetAccess(true)
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error updating Users info ');
      });
  };

  return (
    <div className={s.wrap}>
      <div className={s.sign_in_window}>
        <div className={s.header}>
          <WPSLogoWhite width={271} height={45} />
        </div>
        <div className={s.title}>
          Recover Password
        </div>
        {!resetAccess ? (
          <Formik
            validateOnChange
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <SignInForm />
          </Formik>
        ) : (
          <>
            <div className={s.subtitle}>
              An email has been sent. Please click the link when you get it.
            </div>
            <Link to="/login">
              <Button text="Login" class_type="main" />
            </Link>
          </>
        )}

      </div>
    </div >
  );
};

export default ResetRequestPage;
