import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const ModalAddPromotion = ({...props}: any) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPromotionList = () => {
    history.push(PrivateRoutes.promotions.list);
    dispatch(
      changeShowModal({
        nameModal: 'addPromotion',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  const goPromotionPage = (otherParams: any) => {
    history.push(`/promotions/${otherParams['id']}`);
    dispatch(
      changeShowModal({
        nameModal: 'addPromotion',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  return (
    <div className={s.modalBox}>
      <div className={s.title}> Done! </div>
      <div className={s.description}>
        The promotion has been successfully published
      </div>
      <div className={s.control}>
        <Button
          text="Back to Promotions list"
          type="submit"
          onClick={() => onBackPromotionList()}
          class_type="secondary"
        />
        <Button
          text="Go to Promotion Page"
          type="submit"
          onClick={() => goPromotionPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
