import debounce from 'lodash.debounce';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Search } from '../../../assets/Search';
import { HeaderUpdateSearch } from '../../../store/actions/SideMenu';
import s from './SearchInput.module.scss';

export const SearchInput = ({...props}) => {
  const {placeholder} = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(HeaderUpdateSearch({search: ''}));
  }, []);

  const debouncedCallback = debounce((e) => {
    let search = e.target.value;
    dispatch(HeaderUpdateSearch({search}));
  }, 1000);

  return (
    <div className={s.search_input}>
      <div className={s.search}>
        <Search width="18" height="18" />
      </div>
      <input
        onChange={debouncedCallback}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
