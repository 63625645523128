export const Member = ({...props}) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00089 10.3573C9.02513 10.3573 10.0264 10.0535 10.878 9.4845C11.7296 8.91547 12.3934 8.10668 12.7853 7.16041C13.1773 6.21414 13.2798 5.17289 13.08 4.16833C12.8802 3.16378 12.387 2.24103 11.6627 1.51679C10.9385 0.792543 10.0157 0.299327 9.01119 0.0995082C8.00664 -0.10031 6.96539 0.00224381 6.01912 0.394202C5.07285 0.78616 4.26406 1.44992 3.69502 2.30154C3.12599 3.15316 2.82227 4.15439 2.82227 5.17863C2.82383 6.55161 3.36994 7.8679 4.34078 8.83874C5.31162 9.80958 6.62792 10.3557 8.00089 10.3573ZM8.00089 1.47961C8.73249 1.47961 9.44766 1.69655 10.056 2.10301C10.6643 2.50946 11.1384 3.08717 11.4183 3.76308C11.6983 4.43898 11.7716 5.18273 11.6288 5.90027C11.4861 6.61781 11.1338 7.27692 10.6165 7.79423C10.0992 8.31155 9.44008 8.66385 8.72254 8.80657C8.005 8.9493 7.26125 8.87605 6.58534 8.59608C5.90943 8.31611 5.33172 7.842 4.92527 7.2337C4.51882 6.62539 4.30187 5.91023 4.30187 5.17863C4.30305 4.19795 4.69314 3.25777 5.38659 2.56433C6.08004 1.87088 7.02021 1.48079 8.00089 1.47961Z"
        fill="#A8A8CD"
      />
      <path
        d="M15.9549 13.8136C15.6197 12.2585 14.8342 10.8365 13.6963 9.72472C13.6278 9.65325 13.5455 9.59628 13.4545 9.55717C13.3636 9.51807 13.2656 9.49764 13.1666 9.49708C13.0676 9.49653 12.9694 9.51586 12.878 9.55394C12.7866 9.59203 12.7037 9.64808 12.6344 9.71877C12.565 9.78947 12.5105 9.87337 12.4742 9.96551C12.4379 10.0576 12.4204 10.1561 12.4229 10.2551C12.4253 10.3542 12.4476 10.4517 12.4885 10.5419C12.5293 10.6321 12.5878 10.7132 12.6606 10.7804C13.5916 11.6902 14.2348 12.8535 14.5101 14.1258C14.5273 14.2054 14.5263 14.2878 14.5069 14.3669C14.4875 14.446 14.4504 14.5197 14.3984 14.5823C14.3452 14.649 14.2777 14.7028 14.2009 14.7399C14.124 14.7769 14.0398 14.7961 13.9545 14.7961H2.04662C1.96132 14.7961 1.87711 14.7769 1.80026 14.7399C1.72341 14.7028 1.6559 14.649 1.60274 14.5823C1.55069 14.5197 1.51359 14.446 1.49423 14.3669C1.47488 14.2878 1.47378 14.2054 1.49103 14.1258C1.76634 12.8535 2.40949 11.6902 3.34054 10.7804C3.4133 10.7132 3.47184 10.6321 3.51267 10.5419C3.5535 10.4517 3.57581 10.3542 3.57825 10.2551C3.5807 10.1561 3.56324 10.0576 3.52691 9.96551C3.49058 9.87337 3.43612 9.78947 3.36677 9.71877C3.29741 9.64808 3.21456 9.59203 3.12313 9.55394C3.03171 9.51586 2.93357 9.49653 2.83453 9.49708C2.73549 9.49764 2.63758 9.51807 2.54658 9.55717C2.45559 9.59628 2.37338 9.65325 2.30481 9.72472C1.16695 10.8365 0.381447 12.2585 0.0461902 13.8136C-0.0182679 14.1095 -0.0152269 14.4162 0.0550874 14.7107C0.125402 15.0053 0.261182 15.2803 0.452343 15.5152C0.644231 15.7532 0.887094 15.945 1.16302 16.0767C1.43895 16.2083 1.74091 16.2763 2.04662 16.2757H13.953C14.2587 16.2757 14.5605 16.2072 14.8362 16.075C15.1119 15.9429 15.3544 15.7505 15.5458 15.5122C15.7371 15.2779 15.8731 15.0036 15.944 14.7095C16.0148 14.4155 16.0185 14.1093 15.9549 13.8136Z"
        fill="#A8A8CD"
      />
    </svg>
  );
};
