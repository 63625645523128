import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteFacilitiesBook } from '../../../api';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { dataRows, 
  dataSorting, 
  headerColumn 
} from '../../../utils/tablesData/InvoicesData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';
import s from './ListInvoices.module.scss'

export const ListInvoices = () => {
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();
  // const bookHandler = ({e, props, refresh}: any) => {
  //   e.stopPropagation();
  //   dispatch(
  //     changeShowModal({
  //       nameModal: 'facilityDeleteBooking',
  //       stateModal: true,
  //       otherParams: {props, refresh, deleteFunc: deleteFacilitiesBook(axios)},
  //     }),
  //   );
  // };

  // const hiddenEvent = (dataRow: any) => {
  //   let result;
  //   const lastHourArray = dataRow?.booking.booked_from?.split(':');
  //   const lastHour = Number(lastHourArray[0]) * 60 + Number(lastHourArray[1]);
  //   const date = new Date();

  //   const currentDay = moment(date).format('YYYY-M-DD');
  //   const currentHour = date.getHours() * 60 + date.getMinutes();

  //   const checkInterval =
  //     currentHour >= lastHour && dataRow.booking.date == currentDay;

  //   const bookingNewDate = new Date(dataRow.booking.date);
  //   const currentNewDate = new Date(currentDay);

  //   const checkNewDate = +bookingNewDate <= +currentNewDate;

  //   if (checkInterval) {
  //     result = true;
  //   } else {
  //     if (dataRow.booking.date == currentDay) {
  //       result = false;
  //     } else {
  //       result = checkNewDate;
  //     }
  //   }

  //   return result;
  // };

  
  const defaultContent: DefaultContentT = {
    rowClickable: false,
    // openInfoModalIfClickOnRow: true,
    title: 'Invoice List',
    tableName: 'PaymentHistoryData',
    tableAPI: '/units/rental/invoices/',
    filterBySiteName: 'site__name',
    // customizeCoLumn: {
    //   total: {color: '#5FA784'},
    // },
    // setButton: {
    //   column: 'total',
    //   text: 'Cancel',
    //   style: s.book,
    //   // hiddenEvent: hiddenEvent,
    //   clickEvent: bookHandler,
    // },
    customizeCell: {
      Unpaid: {color: '#EC5D5D'},
      Paid: {color: '#5FA784'},
      Processing: {color: '#eac64a'},
    },
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
