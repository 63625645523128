import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { saveFacilitiesT } from "../../../api";
import { Power } from "../../../assets/Power";
import { AsyncSelect } from "../../FormComponents/AsyncSelect/AsyncSelect";
import { Button } from "../../FormComponents/Button/Button";
import { DataPicker } from "../../FormComponents/DataPicker/DataPicker";
import { Input } from "../../FormComponents/Input/Input";
import s from "./DoorPinForm.module.scss";

type DoorPinFormT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidationSchema: any = () => {
  return Yup.object({
    pin: Yup.string().required("The field is required"),
  });
};

export const DoorPinForm = ({ ...props }: DoorPinFormT) => {
  const { initialValues, onSubmit, onDeactivate, onChangePinInput } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [oldPin, setOldPin] = useState();

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, ...formikProps }) => {
        if (oldPin !== values.pin) {
          onChangePinInput();
          setOldPin(values.pin);
        }
        return (
          <Form>
            <div className={s.form}>
              <div className={s.InputBox}>
                <Input
                  type="number"
                  placeholder="Enter Correct Door Number"
                  name="pin"
                  title="Door Number *"
                  typeEdit={false}
                />
              </div>
              <div className={s.button}>
                <Button
                  text="Request Passcode"
                  type="submit"
                  class_type="main"
                />
                <div className={`${!values.pin ? s.disable : ""} ${s.delete}`}>
                  <Button
                    left_icon={<Power />}
                    text={"Reset Passcode"}
                    class_type={"red"}
                    onClick={() => {
                      onDeactivate(values);
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
