import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import {
    ChangeMemberPoints,
    ChangeMemberPointsT,
    getMemberPoints
} from '../../../../../api';
import { SvgError } from '../../../../../assets/SvgError';
import { MemberPoints } from '../../../../../components/Forms/MemberPoints/MemberPoints';
import { changeShowModal } from '../../../../../store/actions/SideMenu';
import axiosInstance from '../../../../../utils/axios';
import { popUp } from '../../../../../utils/helpersFunction';
import s from './Points.module.scss';
type memberDataT = {
  /* eslint-disable camelcase */
  points: number;
  description: string;
  /* eslint-enable camelcase */
};

export const Points = () => {
  const {params}: any = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const axios = axiosInstance(history);
  const [points, setPoints] = useState('0');

  const [error, setError] = useState('');

  const loadPoints = () => {
    dispatch(changeShowModal({nameModal: 'loader', stateModal: true}));
    getMemberPoints(axios)({id: params.id})
      .then(async (res: {data: any}) => {
        const userData = await res.data;
        setPoints(userData.balance);
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
      })
      .catch((...e: any) => {
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        popUp({props: e});
        setPoints('0');
        // setError('This account does not have active rental');
        setError(
          e[0].response.data.detail
            ? e[0].response.data.detail
            : 'This account does not have active rental',
        );
      });
  };

  useEffect(() => {
    loadPoints();
  }, []);

  const initValues: memberDataT = {
    points: 0,
    description: '',
  };

  const onSubmit = (history: any) => (values: memberDataT) => {
    let operation = String(values.points).slice(0, 1);
    let value = Number(String(values.points).slice(1));
    let valuesJson: ChangeMemberPointsT = {
      user_points_id: Number(params.id),
      operation: operation == '-' ? '-' : '+',
      amount: operation == '-' ? value : values.points,
    };
    if (values.description != '') {
      valuesJson.description = values.description;
    }
    dispatch(changeShowModal({nameModal: 'loader', stateModal: true}));
    ChangeMemberPoints(axios)(valuesJson)
      .then(async (res: {data: any}) => {
        const userData = await res.data;
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        loadPoints();
      })
      .catch((...e: any) => {
        popUp({props: e});
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        new Error('Error updating Users Points ');
      });
  };

  return (
    <div className={s.container}>
      <div className={s.title}>Current user ballance</div>
      <div className={s.points}>
        <span className={s.balance}>{points}</span>
        <span className={s.points_title}>W+S points</span>
      </div>
      {error != '' && (
        <div className={s.error}>
          <span className={s.error_svg}>
            <SvgError />
          </span>
          <div className={s.error_text}>{error}</div>
        </div>
      )}
      <div className={`${s.form} ${error != '' ? s.deactivate : null}`}>
        <MemberPoints initValues={initValues} onSubmit={onSubmit(history)} />
      </div>
    </div>
  );
};
