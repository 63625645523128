import React from 'react';
import {TabComponents} from './TabComponents/TabComponents';
import {TabNavigation} from './TabNavigation/TabNavigation';
import s from './GetMember.module.scss';

export const GetMember = () => {
  return (
    <div className={s.wrapper}>
      <TabNavigation />
      <TabComponents />
    </div>
  );
};
