import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { getFacilitiesTypesOption, saveFacilitiesT } from '../../../api';
import { DeleteIcon } from '../../../assets/DeleteIcon';
import { getHeaderInfo } from '../../../store/selectors/selectors';
import axiosInstance from '../../../utils/axios';
import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
import { loadFacilityTypes } from '../../FormComponents/AsyncSelect/LoadingData/LoadingData';
import { Button } from '../../FormComponents/Button/Button';
import { Input } from '../../FormComponents/Input/Input';
import { TextArea } from '../../FormComponents/Input/TextArea';
import { InputFile } from '../../FormComponents/InputFile/InputFile';
import s from './AddNewFacilities.module.scss';

type AddNewFacilitiesT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidationSchema: any = () => {
  return Yup.object({
    facility_name: Yup.string().required('The field is required'),
    site: Yup.object().required('The field is required'),
    type: Yup.object().required('The field is required'),
    points: Yup.number().required('The field is required'),
    door_number: Yup.number().required('The field is required'),
    description: Yup.string().required('The field is required'),
    capacity_from: Yup.number().required('The field is required'),
    capacity_to: Yup.number()
      .required('The field is required')
      .when('capacity_from', (capacity_from, schema) => {
        if (capacity_from) {
          return schema.min(
            capacity_from,
            'Capacity to must be greater than capacity from',
          );
        }
      }),
    photo: Yup.array().required('The field is required'),
  });
};

export const AddNewFacilities = ({...props}: AddNewFacilitiesT) => {
  const {initialValues, defaultValue, onSubmit, onDelete, type, infoPromotion} =
    props;
  const {siteData} = useSelector(getHeaderInfo);
  const history = useHistory();
  const axios = axiosInstance(history);
  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
  const isEdit = type === 'edit';

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
      infoPromotion={infoPromotion}
    >
      {({values, ...formikProps}) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        return (
          <Form>
            <div className={s.form}>
              <InputFile
                placeholder="Drag & Drop your jpg or png files here"
                name="photo"
                title="Upload Facility Photo *"
                typeEdit={isEdit}
                initialValues={initialValues.photo}
                setValue={formikProps.setFieldValue}
              />
              <div className={s.InputBox}>
                <Input
                  type="text"
                  placeholder="Enter Facility name, for example: small meeting room or large Multi-purpose room"
                  name="facility_name"
                  title="Facility Name *"
                  typeEdit={isEdit}
                />
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <div className={s.input_title}>Site *</div>
                  <AsyncSelect
                    noOptionsMessage={() => 'No data found'}
                    loadOptions={() => ({
                      options: siteData,
                    })}
                    loadingMessage={() => 'loading Site ...'}
                    placeholder="Select the site where facility is located"
                    name="site"
                    title={initialValues.site.label}
                    typeEdit={isEdit}
                  />
                </div>
                <div className={s.InputBox}>
                  <Input
                    type="number"
                    placeholder="Enter Correct door number"
                    name="door_number"
                    title="Door Number *"
                    typeEdit={isEdit}
                  />
                </div>
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <div className={s.input_title}>Facility type *</div>
                  <AsyncSelect
                    noOptionsMessage={() => 'No data found'}
                    loadOptions={loadFacilityTypes(
                      getFacilitiesTypesOption(axios),
                    )}
                    loadingMessage={() => 'loading ...'}
                    placeholder="Choose facility type, for ex. meeting room"
                    name="type"
                    title={initialValues.type.label}
                    typeEdit={isEdit}
                  />
                </div>
                <div className={s.InputBox}>
                  <Input
                    type="number"
                    placeholder="Enter booking price"
                    name="points"
                    title="Points per Hour *"
                    typeEdit={isEdit}
                  />
                </div>
              </div>
              <div className={s.InputBox}>
                <TextArea
                  type="text"
                  placeholder="Write a short description that users will see in mobile app"
                  name="description"
                  title="Facility Description *"
                  typeEdit={isEdit}
                />
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <Input
                    type="number"
                    placeholder="Min. number of people that can fit in a room"
                    name="capacity_from"
                    title="Capacity (From) *"
                    typeEdit={isEdit}
                  />
                </div>
                <div className={s.InputBox}>
                  <Input
                    type="number"
                    placeholder="Max. number of people that can fit in a room"
                    name="capacity_to"
                    title="Capacity (To) *"
                    typeEdit={isEdit}
                  />
                </div>
              </div>
              <div className={s.button}>
                {isEdit ? (
                  <div className={s.groupButton}>
                    <Button
                      text="Update Facility"
                      type="submit"
                      class_type={isSaveChangeParams ? 'main' : 'disabled'}
                    />
                    <Button
                      left_icon={<DeleteIcon />}
                      text="Delete Facility Page"
                      onClick={() => onDelete({values})}
                      class_type="red"
                    />
                  </div>
                ) : (
                  <Button
                    text="Publish Facility"
                    type="submit"
                    class_type="main"
                  />
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
