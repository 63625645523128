import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { findNotificationUserId } from '../../../api';
import axiosInstance from '../../../utils/axios';
import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
import { loadingDataNotificationUserInfo } from '../../FormComponents/AsyncSelect/loadingDataNotificationUserInfo';
import { Button } from '../../FormComponents/Button/Button';
import { Checkbox } from '../../FormComponents/Checkbox/Checkbox';
import { Input } from '../../FormComponents/Input/Input';
import { TextArea } from '../../FormComponents/Input/TextArea';
import s from '../style/formStyle.module.scss';

const validationSchema = Yup.object({
    all: Yup.boolean(),
    addressee: Yup.array().when('all', {
        is: (all: boolean) => !all,
        then: Yup.array().required("Select addressee or click 'Send to everyone'").min(1, "Select addressee or click 'Send to everyone'")
    }),
    title: Yup.string().required('Title is a required field'),
    body: Yup.string().required('Notification Body is a required field'),
})

export const AddNotificationForm = (props: any) => {
    const history = useHistory();
    const axios = axiosInstance(history)
    const {
        initValues,
        onSubmit,
        type,
        infoMember,
    } = props;

    const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
    const isEdit = type === 'edit';

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            infoMember={infoMember}
        >
            {({ values, ..._ }) => {
                if (isEdit) {
                    const defaultTest = {
                        ...initValues,
                    };

                    const similarObjects: boolean = equals(values, defaultTest);

                    if (!similarObjects && !isSaveChangeParams)
                        setIsSaveChangeParams(true);
                    if (similarObjects && isSaveChangeParams)
                        setIsSaveChangeParams(false);
                }

                return (
                    <Form>
                        <div className={s.form}>
                            <div className={s.InputBox}>
                                <div className={s.input_title}>Addressee *</div>
                                <AsyncSelect
                                    isMulti
                                    noOptionsMessage={() => 'No data found'}
                                    loadOptions={loadingDataNotificationUserInfo(findNotificationUserId(axios))}
                                    loadingMessage={() => 'loading ...'}
                                    placeholder="Search by User Name, Email"
                                    name="addressee"
                                    title="Addressee *"
                                    disabled={values.all}
                                />
                                <Checkbox defaultChecked={values.all} name="all" title="Send to everyone" />
                            </div>
                            <div className={s.InputBox}>
                                <Input
                                    type="text"
                                    placeholder="Write the subject of your Notification"
                                    name="title"
                                    title="Title *"
                                    typeEdit={isEdit}
                                />
                            </div>
                            <div className={s.InputBox}>
                                <TextArea
                                    type="text"
                                    placeholder="Write your message to the user"
                                    name="body"
                                    title="Notification Body *"
                                    typeEdit={isEdit}
                                />
                            </div>
                        </div>
                        <div className={s.ButtonBox}>
                            <Button
                                text="Send"
                                type="submit"
                                class_type="main"
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    )
}