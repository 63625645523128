import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import React, { useState } from 'react';
import * as Yup from 'yup';
// import {CirclingArrow} from '../../../assets/CirclingArrow';
// import {Power} from '../../../assets/Power';
import { Button } from '../../FormComponents/Button/Button';
// import {Checkbox} from '../../FormComponents/Checkbox/Checkbox';
import { Input } from '../../FormComponents/Input/Input';
import { TextArea } from '../../FormComponents/Input/TextArea';
import s from '../style/formStyle.module.scss';

type MemberPointsT = {
  initValues: any;
  // defaultValue?: saveFacilitiesT
  onSubmit: any;
  onDeactivate?: any;
  onUpdate?: any;
  onReset?: any;
  type?: string;
  infoMember?: any;
  [key: string]: any;
};

const validationSchema = Yup.object({
  points: Yup.string().required('Points is a required field'),
});

export const MemberPoints = ({...props}: MemberPointsT) => {
  const {initValues, defaultValue, onSubmit, type, infoMember} = props;
  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
  const isEdit = type === 'edit';

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      infoMember={infoMember}
    >
      {({values, ..._}) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        return (
          <Form>
            <div className={s.form}>
              <div className={s.InputBox}>
                <Input
                  type="number"
                  placeholder=""
                  name="points"
                  title="Transfer W+S Points to user account *"
                  typeEdit={isEdit}
                />
                <div className={s.hint}>
                  Enter “+” before amount to send points or “-” to deduct points
                </div>
              </div>
              <div className={s.InputBox}>
                <TextArea
                  type="text"
                  placeholder="Write a short description that users will see in mobile app"
                  name="description"
                  title="Add Description"
                  typeEdit={isEdit}
                />
              </div>

              <div className="ButtonBox">
                <Button text="Confirm*" type="submit" class_type="main" />
              </div>
              <div className={s.hint_confirm}>
                *If this user is a Subuser, W+S points will be sent to the user’s account instead.
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
