import axios, { AxiosResponse } from "axios";
import { Notyf } from "notyf";
import 'notyf/notyf.min.css';
import axiosInstance from "./axios";
import { BACK_END_API_ENDPOINT } from "./constants";

export const isAuthenticated = () => {
  return !!localStorage.getItem('refresh_token');
};

type TokensT = {
  access: string,
  refresh: string
}

export const saveTokenCombination = (tokens: TokensT) => {
  localStorage.setItem("access_token", tokens.access);
  localStorage.setItem("refresh_token", tokens.refresh);
};

export const clearAuthTokens = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export const getUserMe = () => {
  const axios = axiosInstance()

  type UserMeT = {
    "id": number,
    "email": string,
    "first_name": string,
    "last_name": string,
    "salesforce_id": string,
    "company_name": string,
    "phone_number": string,
    "correspondence_address": string,
    "image": string,
    "is_staff": boolean
  }

  type UserMeErrorT = {
    "is_staff": boolean
  }

  return axios.get("/users/me/")
    .then((response: AxiosResponse) => response.data)
    .then((data: UserMeT): UserMeT => {
      return data
    })
    .catch((error): UserMeErrorT => {
      console.log(error)
      return {
        "is_staff": false
      }
    });
}

export const authorizeCredentials = ({ ...credentials }, history: any) => {
  const { login, password } = credentials;
  axios.post(`${BACK_END_API_ENDPOINT}/auth/jwt/create/`, {
    email: login,
    password: password,
  }).then((response: AxiosResponse) => response.data)
    .then(async (data: any) => {
      saveTokenCombination(data);
      const user = await getUserMe();
      if (user.is_staff !== true) {
        clearAuthTokens()
        const notyf = new Notyf();
        notyf.error("You do not have permission to perform this action.")
        return
      }
      history.go('/members');
      return
    })
    .catch((error) => console.log(error));
}

