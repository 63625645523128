export const WPSLogoWhite = ({ ...props }) => (
  <svg width="121" height="20" viewBox="0 0 121 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M54.1537 13.1589L58.204 12.5961C58.3395 12.1285 58.2545 11.65 58.2693 11.1775C58.2841 10.6761 58.2693 10.1747 58.2693 9.67335C58.2693 9.19125 58.2693 8.71879 58.2693 8.2174L59.9784 7.9884V12.3442L64.099 11.791V13.5591C62.7435 13.8363 61.3881 13.9303 60.0018 14.1617V18.5006C59.6925 18.5549 59.4177 18.6079 59.1393 18.6501C58.8608 18.6923 58.6119 18.7176 58.3174 18.7549C58.1941 18.2849 58.2742 17.8064 58.2607 17.3352C58.2471 16.8639 58.2607 16.4119 58.2607 15.9503V14.4558C57.5337 14.4148 56.8658 14.6209 56.1868 14.6872C55.5079 14.7535 54.8536 14.862 54.1081 14.9644C54.113 14.3329 54.076 13.764 54.1537 13.1589Z" fill="white" />
    <path d="M99.9406 13.3386C99.9406 15.3646 99.2518 17.1086 97.6893 18.4778C95.4134 20.4701 91.6982 20.5364 89.3422 18.5007C87.9337 17.2846 87.2043 15.7431 87.086 13.9207C86.9837 12.3539 87.2733 10.8871 88.1703 9.56251C89.2128 8.01737 90.6619 7.09536 92.5398 6.8302C94.7739 6.51442 96.6863 7.15682 98.2081 8.76825C99.4182 10.0446 99.9468 11.6018 99.9406 13.3386ZM97.1361 13.7785L98.0479 12.6938L97.2728 11.914L93.4024 12.4274C93.3408 12.3479 93.3075 12.296 93.2656 12.2526C92.5201 11.4789 91.6181 11.409 90.7642 12.0791C90.4534 12.3118 90.2056 12.6153 90.0424 12.9632C89.8791 13.3111 89.8054 13.6928 89.8277 14.075C89.8585 14.9391 90.4278 15.5888 91.2571 15.7153C91.8387 15.8045 92.3525 15.5948 92.7777 15.2212C93.0292 14.9731 93.2666 14.7115 93.4887 14.4378C94.053 14.4727 94.4473 14.1413 94.7714 13.7218L95.2507 13.9966L95.7005 13.611L96.1515 13.852L96.6444 13.4699L97.1361 13.7785Z" fill="#B456BB" />
    <path d="M119.794 16.0046C120.196 16.8194 120.578 17.5931 120.976 18.4019C120.837 18.491 120.711 18.5839 120.573 18.6574C118.785 19.6023 116.887 20.1037 114.843 19.9747C113.502 19.8903 112.28 19.4926 111.235 18.6224C110.445 17.9707 109.867 17.1072 109.573 16.1384C108.932 14.0786 109.047 12.0405 110.066 10.123C111.199 7.98966 113.033 6.81695 115.496 6.74825C118.302 6.67473 120.259 8.3874 120.843 10.9751C121.013 11.732 121.041 12.9915 120.928 13.8255H112.598C112.328 14.8078 112.797 16.0396 113.664 16.6832C114.204 17.0885 114.857 17.3257 115.537 17.3641C116.749 17.4437 117.846 17.1086 118.881 16.5289C119.166 16.3746 119.445 16.2071 119.794 16.0046ZM117.646 11.8429C117.708 11.3694 117.646 10.8884 117.466 10.4448C117.369 10.126 117.181 9.8409 116.925 9.62244C116.668 9.40398 116.353 9.26116 116.017 9.21058C115.554 9.12121 115.076 9.1402 114.621 9.26603C113.564 9.54444 112.711 10.7123 112.754 11.8429H117.646Z" fill="#B456BB" />
    <path d="M66.2793 18.5765C66.628 17.7136 66.9644 16.8771 67.307 16.025C67.8196 16.2962 68.2928 16.5638 68.7856 16.8072C69.4442 17.1313 70.1454 17.3649 70.8693 17.5015C71.435 17.5943 72.0141 17.573 72.5711 17.4388C72.6106 17.4272 72.6493 17.4131 72.6869 17.3966C73.1132 17.2459 73.4755 17.0206 73.5334 16.5397C73.5864 16.0925 73.3338 15.7587 72.9838 15.5284C72.6723 15.3364 72.3421 15.1749 71.9981 15.0463C71.0123 14.645 70.0265 14.2714 69.0407 13.8616C68.4965 13.6226 68.0061 13.2806 67.5978 12.8552C66.3002 11.5427 66.4506 9.08757 68.0192 7.86303C68.7728 7.26705 69.6812 6.88811 70.6426 6.76866C72.7707 6.5035 74.8039 6.82892 76.6941 7.86303C76.7473 7.89989 76.7979 7.94016 76.8457 7.98355C76.513 8.79831 76.1753 9.64198 75.8204 10.5266C75.5173 10.3567 75.2659 10.2265 75.0281 10.0783C74.2239 9.59274 73.3226 9.28161 72.385 9.16591C71.8536 9.09846 71.3135 9.15886 70.8114 9.34188C70.6178 9.40606 70.4363 9.50093 70.2742 9.6227C69.7221 10.0542 69.7086 10.6906 70.284 11.0931C70.6439 11.345 71.0727 11.5077 71.483 11.6861C72.6043 12.1682 73.738 12.6334 74.8556 13.1324C76.6423 13.9255 77.1155 16.4456 76.2111 17.9209C75.6787 18.7863 74.8753 19.3154 73.9154 19.6335C72.6019 20.0698 71.26 20.0771 69.9094 19.8505C68.6297 19.6472 67.3992 19.2154 66.2793 18.5765Z" fill="#B456BB" />
    <path d="M79.8491 9.47207H78.0452C77.9121 9.12496 77.9922 8.78387 77.9774 8.45243C77.9626 8.12098 77.9774 7.81364 77.9774 7.43399L79.8491 7.08808C79.8652 5.86595 79.8491 4.64744 79.8602 3.43977C80.3273 3.32768 82.0893 3.30719 82.9408 3.40482C82.9544 4.00745 82.9408 4.62213 82.9408 5.23681C82.9408 5.85149 82.9408 6.43242 82.9408 7.08326H85.7121V9.4552H82.9913C82.9728 9.66371 82.947 9.81677 82.9457 9.97105C82.9457 11.6584 82.9457 13.3458 82.9457 15.0331C82.9538 15.4144 82.9822 15.7949 83.0308 16.1733C83.0998 16.794 83.4423 17.017 84.056 16.8085C84.4257 16.6879 84.7534 16.4734 85.0984 16.2986C85.1872 16.254 85.2685 16.1986 85.404 16.1191C85.7491 16.8916 86.0805 17.6353 86.4293 18.409C85.0479 19.3491 83.653 20.1048 81.9304 19.9698C80.8325 19.8843 80.1116 19.2322 79.9588 18.162C79.8954 17.7462 79.862 17.3265 79.859 16.9061C79.8491 14.6764 79.859 12.4466 79.859 10.2169L79.8491 9.47207Z" fill="#B456BB" />
    <path d="M101.436 7.12427H104.187C104.241 7.98483 104.293 8.82007 104.355 9.79271C104.524 9.54202 104.617 9.38895 104.724 9.24432C105.121 8.71039 105.497 8.15959 105.923 7.64856C106.898 6.48669 108.458 6.83381 109.163 7.83176C108.833 8.81163 108.494 9.8144 108.144 10.8449C107.996 10.781 107.889 10.7376 107.783 10.6894C107.542 10.5737 107.272 10.5255 107.005 10.5502C106.737 10.5749 106.482 10.6715 106.267 10.8292C105.475 11.3525 104.926 12.1609 104.74 13.0782C104.663 13.4713 104.627 13.8711 104.633 14.2714C104.622 15.8383 104.633 17.4051 104.633 18.9719V19.5517C104.14 19.6553 102.281 19.6722 101.48 19.5963C101.37 19.1636 101.337 8.07522 101.436 7.12427Z" fill="#B456BB" />
    <path d="M90.8157 14.2968C90.4374 14.0557 90.3549 13.9014 90.4461 13.6303C90.4852 13.5269 90.5534 13.4364 90.6428 13.3693C90.7321 13.3021 90.8391 13.261 90.9513 13.2506C91.0549 13.2531 91.1538 13.2932 91.2288 13.3632C91.3037 13.4332 91.3493 13.528 91.3567 13.6291C91.3789 13.981 91.194 14.1907 90.8157 14.2968Z" fill="#B456BB" />
    <path d="M11.583 19.601C11.2412 18.4247 10.9011 17.2516 10.5627 16.0817L9.50788 12.4659C9.15054 13.3096 9.01499 14.1123 8.76854 14.8764C8.5221 15.6405 8.31755 16.4577 8.08589 17.2471C7.85423 18.0366 7.61641 18.814 7.37982 19.6034H4.06636C2.71748 15.4678 1.36202 11.3093 0 7.12782H3.45024C4.23517 10.0506 5.0238 12.9878 5.8383 16.019C5.92086 15.7779 5.98371 15.6273 6.03053 15.467C6.63925 13.3674 7.23811 11.2655 7.85669 9.16952C7.97992 8.7513 8.02551 8.35477 7.87148 7.93775C7.78153 7.6967 7.73594 7.43516 7.67063 7.18326C8.09944 7.07238 9.8985 7.04586 10.9311 7.13867C11.6803 9.99392 12.358 12.8841 13.1097 15.755H13.2329L15.674 7.12902H18.9332C17.5778 11.2884 16.2334 15.432 14.8792 19.601H11.583Z" fill="#91CB3F" />
    <path d="M53.5307 19.6071H49.8056L47.8784 16.512C47.2303 15.4694 46.5834 14.4257 45.881 13.3904C45.7898 13.7953 45.8169 14.1991 45.812 14.5956C45.812 15.0175 45.812 15.4393 45.812 15.8612V19.5974H42.5527V0H45.8058V12.3563L45.913 12.3948L49.8093 7.12185H53.4789L48.8975 12.8721C50.4255 15.0946 51.946 17.3038 53.5307 19.6071Z" fill="#91CB3F" />
    <path d="M26.3694 19.9735C22.563 20.029 19.7449 18.1259 19.0992 14.7138C18.7419 12.8191 19.0413 11.0173 20.1873 9.43356C21.666 7.39307 23.7139 6.54096 26.2671 6.81093C28.8203 7.08091 31.1295 8.99847 31.7308 11.8802C32.0648 13.4784 31.8984 15.0428 31.1443 16.5084C29.9626 18.802 28.0058 19.9096 26.3694 19.9735ZM27.5449 16.0094C27.6509 14.9801 27.6201 11.0438 27.5104 10.6232L26.4729 10.7135C26.4594 10.5087 26.4557 10.3713 26.4384 10.2314C26.3694 9.67341 26.2536 9.57217 25.6991 9.61435C25.4954 9.63326 25.2933 9.66547 25.094 9.71077C24.6418 9.80478 24.5137 9.93495 24.4447 10.3821C24.4114 10.593 24.4003 10.8064 24.3806 11.004L23.3024 11.2173V16.5952L27.5449 16.0094ZM22.876 11.3089C22.1022 11.3174 21.7029 11.6054 21.6894 12.2105C21.6623 13.4531 21.6574 14.6969 21.6894 15.9383C21.7042 16.6482 22.1823 16.8808 22.881 16.5602L22.876 11.3089ZM27.9861 10.6063V15.8576C28.7254 15.9106 29.1444 15.6165 29.1629 14.9597C29.1974 13.7399 29.1986 12.5166 29.1703 11.2969C29.1518 10.5689 28.7353 10.3435 27.9861 10.6063Z" fill="#91CB3F" />
    <path d="M41.4392 7.75217C41.1927 8.80195 40.7811 9.80111 40.4608 10.8039C40.4056 10.8112 40.3496 10.8112 40.2944 10.8039C39.353 10.4061 38.8576 10.4857 38.1306 11.1763C37.7541 11.5192 37.4545 11.9348 37.2506 12.3968C37.0468 12.8588 36.9432 13.3572 36.9464 13.8604C36.9464 15.5658 36.9464 17.2725 36.9464 18.9779V19.601H33.6934V7.12665H36.424C36.6039 7.53282 36.5472 7.97032 36.5694 8.40663C36.5916 8.84293 36.5694 9.2503 36.5694 9.80593C36.753 9.60103 36.8491 9.51184 36.923 9.40819C37.2705 8.91283 37.6032 8.40663 37.9581 7.91729C38.0938 7.74104 38.2483 7.57943 38.419 7.43519C39.2815 6.67106 40.3228 6.81931 41.0436 7.40626C41.1718 7.5063 41.2876 7.61718 41.4392 7.75217Z" fill="#91CB3F" />
    <path d="M26.044 10.7581C25.7002 11.0811 25.2517 10.8232 24.8845 11.0329C24.707 10.7268 24.7724 10.4616 24.8179 10.1555L26.0502 9.95422L26.044 10.7581Z" fill="#91CB3F" />
  </svg>
);

export default WPSLogoWhite;
