import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { PrivateRoutes } from '../../../../utils/constants/routes';
import { General } from './General/General';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { Points } from './Points/Points';
import { ChangeMemberRental } from './Rental/ChangeMemberRental/ChangeMemberRental';
import { CreditMemo } from './CreditMemo/CreditMemo';
import s from './TabComponents.module.scss';

export const TabComponents = () => {
  const { params } = useRouteMatch();

  return (
    <>
      <div className={s.tab_content}>
        <Route path={PrivateRoutes.members.tabComponents?.general} exact>
          <General />
        </Route>
        <Route path={PrivateRoutes.members.tabComponents?.rental} exact>
          <ChangeMemberRental type='edit' />
        </Route>
        <Route path={PrivateRoutes.members.tabComponents?.add} exact>
          <ChangeMemberRental type='noEdit' />
        </Route>
        <Route path={PrivateRoutes.members.tabComponents?.paymentHistory} exact>
          <PaymentHistory />
        </Route>
        <Route path={PrivateRoutes.members.tabComponents?.creditMemo}>
          <CreditMemo />
        </Route>
        <Route path={PrivateRoutes.members.tabComponents?.points} exact>
          <Points />
        </Route>
        {/* <Redirect to={getUrlWithParams(PrivateRoutes.members.tabComponents?.general, params)} /> */}
      </div>
    </>
  );
};
