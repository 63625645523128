import {Checkbox as CheckboxAntd} from 'antd';
import 'antd/dist/antd.css';
import {useField} from 'formik';
import s from './Checkbox.module.scss';
import './CheckboxAntd.css';
import React from 'react';

export const Checkbox = ({...props}: any) => {
  const {title} = props;
  const [field] = useField(props);

  return (
    <div className={s.wrap}>
      <div className={s.checkbox}>
        <CheckboxAntd checked={field.value} {...field} />
      </div>
      <div className={s.title}>{title}</div>
    </div>
  );
};
