import { Form, Formik } from "formik";
import { equals } from "ramda";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from 'yup';
import { getTableData, updateHelp } from "../../../api";
import { changeShowModal, UpdateRows } from "../../../store/actions/SideMenu";
import axiosInstance from "../../../utils/axios";
import { Button } from '../../FormComponents/Button/Button';
import { Input } from '../../FormComponents/Input/Input';
import { TextArea } from "../../FormComponents/Input/TextArea";
import s from '../style/formStyle.module.scss';

type HelpFormT = {
    id: number,
    initValues: any;
    type?: string;
    infoMember?: any;
    [key: string]: any;
};

const validationSchema = Yup.object({
    question: Yup.string().required('Question is a required field'),
    response: Yup.string().required('Response is a required field'),
})

export const HelpForm = (props: HelpFormT) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        initValues,
        type,
        infoMember,
        id
    } = props;
    const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
    const isEdit = type === 'edit';

    const onSubmit = (value: typeof initValues) => {
        dispatch(changeShowModal({ 'nameModal': "loader", 'stateModal': true }))
        const axios = axiosInstance(history)
        updateHelp(axios)(id, value).then(async () => {

            getTableData(axios)({ params: 'no_page', tableAPI: '/help/' })
            .then(async (res: any) => {
                const HelpData = await res.data;
                
                dispatch(
                    UpdateRows({
                        nameTable: 'Help',
                        otherParams: { rows: HelpData },
                    }),
                );
                dispatch(changeShowModal({ 'nameModal': "loader", 'stateModal': false }))
                setIsSaveChangeParams(false)
            })
            
        }).catch(() => {
            new Error("Error save Site ")
            setIsSaveChangeParams(false)
        });
    }
    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            infoMember={infoMember}
        >
            {({values, ..._}) => {
                if (isEdit) {
                    const defaultTest = {
                        ...initValues,
                    };

                    const similarObjects: boolean = equals(values, defaultTest);

                    if (!similarObjects && !isSaveChangeParams)
                        setIsSaveChangeParams(true);
                    if (similarObjects && isSaveChangeParams)
                        setIsSaveChangeParams(false);
                }

                return (
                <Form>
                    <div className={s.form}>
                        <div className={s.InputBox}>
                            <Input
                                type="text"
                                placeholder="This site name will be displayed to users in the mobile app"
                                name="question"
                                title="Question"
                                typeEdit={isEdit}
                            />
                        </div>
                        <div className={s.InputBox}>
                            <TextArea
                                type="text"
                                placeholder="Enter the address where site is located"
                                name="response"
                                title="Answer"
                                typeEdit={isEdit}
                            />
                        </div>
                    </div>
                    <div className={s.ButtonBox}>
                        {isSaveChangeParams && (
                            <Button
                                text="Update FAQ"
                                type="submit"
                                class_type="main"
                            />
                        )}
                    </div>     
                </Form>
                );
            }}
        </Formik>
    )
}