import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectSideMenuMain } from '../../store/actions/SideMenu';
import { MenuT } from '../../store/interface';
import {
    getSelectedChildCell,
    getSelectedMainCell,
    getSideMenu
} from '../../store/selectors/selectors';
import { InnerSideCell } from './SideCell/InnerSideCell/InnerSideCell';
import { SideCell } from './SideCell/SideCell';
import s from './SidePanel.module.scss';
import { SidePanelHeader } from './SidePanelHeader/SidePanelHeader';

 
export const SidePanel = () => {
  const sideCells = useSelector(getSideMenu);
  const selectedMainCell = useSelector(getSelectedMainCell);
  const selectedChildCell = useSelector(getSelectedChildCell);
  const dispatch = useDispatch();

  const handleMainClick = (
    mainSelected: string,
    childSelected: string | null = null,
  ) => () => {
    dispatch(SelectSideMenuMain(mainSelected, childSelected));
    window.sessionStorage.setItem('selectCell', JSON.stringify({mainSelected, childSelected}))
  };

  useEffect(() => {
    const selectCell = window.sessionStorage.getItem('selectCell')

    if(selectCell) {
      const {mainSelected, childSelected} = JSON.parse(selectCell);
      dispatch(SelectSideMenuMain(mainSelected, childSelected));
    } else {
      dispatch(SelectSideMenuMain('members', null));
    }
  })

  return (
    <div className={s.side_panel}>
      <div className={s.side_panel_item}>
        <SidePanelHeader />
      </div>
      <div className={s.side_panel_item}>
        {sideCells.map((sideCell: MenuT) => {
          return (
            <SideCell
              Icon={<sideCell.menuData.itemIcon />}
              label={`${sideCell.menuData.label}`}
              isActive={sideCell.id === selectedMainCell}
              onClick={handleMainClick(sideCell.id)}
              link={sideCell.link}
              key={sideCell.id}
            >
              {sideCell.menuData &&
                sideCell.menuData.subMenu &&
                sideCell.menuData.subMenu.map((innerSideCell: MenuT) => {
                  // TODO use selectedChildCell and update
                  return (
                    <InnerSideCell
                      label={`${innerSideCell.menuData.label}`}
                      isActive={innerSideCell.id === selectedChildCell}
                      onClick={handleMainClick(sideCell.id, innerSideCell.id)}
                      key={innerSideCell.id}
                      link={innerSideCell.link}
                    />
                  );
                })}
            </SideCell>
          );
        })}
      </div>
    </div>
  );
};

export default SidePanel;
