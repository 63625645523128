import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SignInPage } from "./page/SignIn/SignInPage";
import { isAuthenticated } from "./utils/auth";
import "./App.scss";
import s from "./App.module.scss";
import { SidePanel } from "./components/SidePanel/SidePanel";
import { Header } from "./components/Header/Header";
import { PageContent } from "./components/PageContent/PageContent";
import PasswordResetPage from "./page/PasswordReset/PasswordReset";
import ResetRequestPage from "./page/ResetRequestPage/ResetRequestPage";

function App() {
  if (isAuthenticated()) {
    return (
      <div className={s.rootWrap}>
        <SidePanel />
        <div className={s.mainContent}>
          <div className={s.header}>
            <Header />
          </div>
          <PageContent />
        </div>
      </div>
    );
  }
  return (
    <Switch>
      <Route path="/login" exact>
        <SignInPage />
      </Route>
      <Route path="/password-reset" exact>
        <ResetRequestPage />
      </Route>
      <Route path="/password-reset/:uid/:token" exact>
        <PasswordResetPage />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
}

export default App;
