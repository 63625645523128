import React from 'react';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import {
  columnExtensions,
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/MembersData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';

export const ListMembers = () => {
  const defaultContent: DefaultContentT = {
    title: 'Users',
    tableName: 'Members',
    tableAPI: '/users/',
    filterBySiteName: 'rental__unit__site__name',
    dataRows: dataRows,
    redirectOnDetailPage: 'general',
    dataSorting: dataSorting,
    columnExtensions: columnExtensions,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
