import {Form, Formik} from 'formik';
import {Link, useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Eye} from '../../assets/Eye';
import {WPSLogoWhite} from '../../assets/WPSLogoWhite';
import {Button} from '../../components/FormComponents/Button/Button';
import {Input} from '../../components/FormComponents/Input/Input';
import {authorizeCredentials} from '../../utils/auth';
import s from './SignInPage.module.scss';
import React from 'react';

const validationSchema = Yup.object({
  login: Yup.string()
    .required('Email is required')
    .email('Must be valid email'),
  password: Yup.string().required('Password is required'),
});

const initValues = {
  login: '',
  password: '',
};

const SignInForm = () => (
  <Form>
    <div className={s.form}>
      <div className={s.inputs_group}>
        <Input
          type="text"
          name="login"
          placeholder="Enter your Email"
          title="Login"
        />
        <div className={s.password_field}>
          <Input
            type="password"
            name="password"
            placeholder="•••••••••"
            title="Password"
            right_icon={<Eye />}
          />
          <Link to="/password-reset">Forgot Password?</Link>
        </div>
      </div>
      <div className={s.login_button}>
        <Button text="Login" type="submit" class_type="main" />
      </div>
    </div>
  </Form>
);

export const SignInPage = () => {
  const history = useHistory();
  const onSubmit = (history: any) => (values: any) => {
    authorizeCredentials(values, history);
  };

  return (
    <div className={s.wrap}>
      <div className={s.sign_in_window}>
        <div className={s.header}>
          <WPSLogoWhite width={271} height={45} />
          <div className={s.welcome_message}>
            Welcome to Admin panel.
            <br />
            Enter your details below
          </div>
        </div>
        <Formik
          validateOnChange
          initialValues={initValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit(history)}
        >
          <SignInForm />
        </Formik>
      </div>
    </div>
  );
};

export default SignInPage;
