import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { BACK_END_API_ENDPOINT } from './constants';

export const axiosInstance = (...props) => {
  const { history } = props;

  const instance = axios.create({
    baseURL: BACK_END_API_ENDPOINT,
    // TODO double check this
    timeout: 5000000,
    headers: {
      Authorization: localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : null,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });

  instance.interceptors.response.use(
    (response) => response,

    async (error) => {
      const originalRequest = error.config;
      if (error.message.match(/timeout of [\d]+ms exceeded/g)){
        console.warn(`Request failed, ${error.message}`);
        return Promise.reject(error);
      }
      if (typeof error.response === 'undefined') {
        alert(
          'A server/network error occurred. '
          + 'Looks like CORS might be the problem. '
          + 'Sorry about this - we will get it fixed shortly.',
        );
        return Promise.reject(error);
      }
      if (
        error.response.status === 401
        && originalRequest.url === `${BACK_END_API_ENDPOINT}${'auth/jwt/refresh/'}`
      ) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        history.push('/login/');
        return Promise.reject(error);
      }
      if (
        error.response.data.code === 'token_not_valid'
        && error.response.status === 401
      ) {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          const decodedToken = jwtDecode(refreshToken);
          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          if (decodedToken.exp > now) {
            return axiosInstance(history)
              .post('auth/jwt/refresh/', { refresh: refreshToken })
              .then((response) => {
                localStorage.setItem('access_token', response.data.access);

                // axiosInstance().defaults.headers.Authorization = `Bearer ${response.data.access}`;
                originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
                console.log('Refresh token was updated');
                return axiosInstance(history).request(originalRequest);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          console.log('Refresh token is expired', decodedToken.exp, now);
          // TODO Check location
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          history.push('/login');
        } else {
          console.log('Refresh token not available.');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          // TODO Check location
          history.push('/login');
        }
      }
      // specific error handling done elsewhere
      return Promise.reject(error);
    },
  );

  return instance;
};

export default axiosInstance;
