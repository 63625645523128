import { useField } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowRight } from '../../../assets/ArrowRight';
import { InputNotEdit } from '../InputNotEdit/InputNotEdit';
import s from './DataPicker.module.scss';
import './DataPickerCustomStyle.scss';

const CustomHeaderDataPicer = ({...props}) => {
  const {monthDate, increaseMonth, decreaseMonth} = props;

  const monthName: string = moment(monthDate).format('MMMM');
  const yearName: string = moment(monthDate).format('YYYY');

  return (
    <div className={s.CustomHeaderDataPicer}>
      <div className={s.data}>
        {monthName} {yearName}
      </div>
      <div className={s.control}>
        <div className={s.prevMonth} onClick={() => decreaseMonth()}>
          <ArrowRight />
        </div>
        <div className={s.nextMonth} onClick={() => increaseMonth()}>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

// type DataPickerT = {
//     typeEdit: boolean,
//     [key: string]: any
// }

export const DataPicker = ({
  onChangeCustom,
  typeEdit,
  checkOnHover = false,
  ...props
}: any) => {
  const [field, meta, otherParams] = useField(props);
  let error: string | undefined;
  let isError: boolean = false;
  let classWrap: string = ` `;

  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }

  // if (props.onlyTime) {
  //   timeProps = {
  //     showTimeSelect: true,
  //     showTimeSelectOnly: true,
  //     timeCaption: false,
  //     timeIntervals: 60,
  //     timeFormat: 'HH:mm',
  //     minTime: setHours(setMinutes(new Date(), 0), 9),
  //     maxTime: setHours(setMinutes(new Date(), 0), 20),
  //     excludeTimes: [
  //       setHours(setMinutes(new Date(), 0), 12),
  //       setHours(setMinutes(new Date(), 0), 13),
  //     ],
  //   };
  // }

  if (isError) classWrap = 'errorWrap';

  const [isEdit, setIsEdit] = useState(typeEdit);
  return (
    <div className={s.wrap}>
      {isEdit ? (
        <InputNotEdit
          value={moment(props['selected']).format('YYYY-M-DD')}
          setIsEdit={() => setIsEdit(!isEdit)}
          isShowIcon={true}
        />
      ) : (
        <DatePicker
          renderCustomHeader={CustomHeaderDataPicer}
          wrapperClassName={classWrap}
          onFocus={(e) => checkOnHover && checkOnHover(true)}
          {...props}
          {...field}  
          autoComplete="off"
          onChange={(data) => {
            onChangeCustom(data);
            otherParams.setValue(data);
          }}
        />
      )}
      <div className={` ${isError ? s.error : null}`}> {isError && error} </div>
    </div>
  );
};
