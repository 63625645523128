import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const ModalAddTools = ({...props}) => {
  const {otherParams} = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const backToPage = () => {
    dispatch(
      changeShowModal({
        nameModal: 'newTools',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}> Done!</div>
      <div className={s.description}>Invoices data synced successfully</div>
      <Button
        text="Close"
        type="submit"
        class_type="secondary"
        onClick={() => backToPage()}
      />
    </div>
  );
};
