export const Promotion = ({...props}) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.2016 8.51938L1.55558 8.4476L2.2016 8.51938ZM1.16456 17.8527L1.81059 17.9245L1.16456 17.8527ZM16.8355 17.8527L16.1895 17.9245L16.8355 17.8527ZM15.7985 8.51938L16.4445 8.4476L15.7985 8.51938ZM1.55558 8.4476L0.518539 17.7809L1.81059 17.9245L2.84762 8.59116L1.55558 8.4476ZM2.48974 19.9833H15.5104V18.6833H2.48974V19.9833ZM17.4816 17.7809L16.4445 8.4476L15.1525 8.59116L16.1895 17.9245L17.4816 17.7809ZM14.4733 6.68329H3.52678V7.98329H14.4733V6.68329ZM16.4445 8.4476C16.3329 7.44317 15.4839 6.68329 14.4733 6.68329V7.98329C14.8215 7.98329 15.114 8.2451 15.1525 8.59116L16.4445 8.4476ZM15.5104 19.9833C16.6925 19.9833 17.6121 18.9558 17.4816 17.7809L16.1895 17.9245C16.2345 18.3293 15.9176 18.6833 15.5104 18.6833V19.9833ZM0.518539 17.7809C0.387999 18.9558 1.30765 19.9833 2.48974 19.9833V18.6833C2.08247 18.6833 1.76561 18.3293 1.81059 17.9245L0.518539 17.7809ZM2.84762 8.59116C2.88608 8.2451 3.17859 7.98329 3.52678 7.98329V6.68329C2.51617 6.68329 1.66718 7.44318 1.55558 8.4476L2.84762 8.59116ZM5.65005 5.33329V4.66663H4.35006V5.33329H5.65005ZM12.3501 4.66663V5.33329H13.6501V4.66663H12.3501ZM9.00006 1.31663C10.8502 1.31663 12.3501 2.81647 12.3501 4.66663H13.6501C13.6501 2.0985 11.5682 0.016626 9.00006 0.016626V1.31663ZM5.65005 4.66663C5.65005 2.81647 7.1499 1.31663 9.00006 1.31663V0.016626C6.43193 0.016626 4.35006 2.0985 4.35006 4.66663H5.65005Z"
      fill="#A8A8CD"
    />
  </svg>
);

export default Promotion;
