import moment from 'moment';
import { Notyf } from 'notyf';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addAdvertising } from '../../../api';
import { AddNewAdvertising } from '../../../components/Forms/AddNewAdvertising/AddNewAdvertising';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './NewAdvertising.module.scss';

export const NewAdvertising = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = axiosInstance(history);
  const notyf = new Notyf({
    duration: 5000,
  });
  const initialValues = {
    title: '',
    link: '',
    ads_image: '',
    promoted_from: '',
    promoted_to: '',
    sites_list: '',
  };

  const createAds = (props: any) => {
    dispatch(changeShowModal({nameModal: 'loader', stateModal: true}));
    const sitesList: any[] = [];
    if (!props.allSites) {
      props.sites_list.map((site: {value: any}) => {
        sitesList.push(site.value);
      });
    }

    const formData = new FormData();
    formData.append('title', props.title);
    formData.append('link', props.link);
    formData.append('ads_image', props.ads_image[0], props.ads_image[0].name);
    formData.append(
      'promoted_from',
      moment(props.promoted_from).format('YYYY-M-DD'),
    );
    formData.append(
      'promoted_to',
      moment(props.promoted_to).format('YYYY-M-DD'),
    );
    formData.append('sites_list', props.allSites ? 'all' : sitesList.join(','));
    addAdvertising(axios)(formData)
      .then((res: any) => {
        dispatch(
          changeShowModal({
            nameModal: 'newAdvertising',
            stateModal: true,
            otherParams: res.data,
          }),
        );
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
      })
      .catch((...e: any) => {
        popUp({props: e});
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        new Error('Error create Facility');
      });
  };

  return (
    <div>
      <div className={s.title}>Add New Promo Advertising</div>
      <div className={s.boxForm}>
        <AddNewAdvertising
          initialValues={initialValues}
          onSubmit={(props: any) => createAds(props)}
        />
      </div>
    </div>
  );
};
