import { TYPE_REDUCER } from "../../utils/constants";
import { PrivateRoutes } from "../../utils/constants/routes";

export const middleware = (store: any) => (next: any) => (action: any) => {


    switch (action.type) {
        case TYPE_REDUCER.DELETE_PROMOTION: {
            const { history } = action.payload
            history.push(PrivateRoutes.promotions.list)

            break;
        }
    }

    const result = next(action);
    return result;
};