import moment from "moment";
import { Notyf } from "notyf";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  addMember,
  createRentalDetails,
  deletePin,
  deleteRentalDetails,
  getRentalDetails,
  updateRentalDetails,
} from "../../../api";
import { Trashcan } from "../../../assets/Trashcan";
import { roleData } from "../../../components/Forms/MemberRentalForm/dataMembersForm";
import { MemberRentalForm } from "../../../components/Forms/MemberRentalForm/MemberRentalForm";
import { changeShowModal } from "../../../store/actions/SideMenu";
import { getMemberInfo } from "../../../store/selectors/selectors";
import axiosInstance from "../../../utils/axios";
import { popUp } from "../../../utils/helpersFunction";
import s from "./AddRental.module.scss";

export type doors_numbersT = {
  value: string;
  [key: string]: any;
};

export type roleT = {
  label: string;
  value: string;
};

export type InitValuesFormikT = {
  site_id: any;
  role: roleT | string;
  unit_id: string | number;
  doors_numbers: doors_numbersT[];
  leased_from: string;
  leased_to: string;
  details: string;
  [key: string]: any;
};

type formDataT = {
  id: number;
  isActiveRentals: boolean;
  initValues: InitValuesFormikT;
};

const getStringDoorFormat = (values: any) => {
  const doors_numbers: any = [];
  values.map((item: { value: any }) => {
    doors_numbers.push(item.value);
  });
  return doors_numbers.join();
};

const getObjectDoorFormat = (values: string) => {
  const doors_numbersArray: any = [];

  values.split(",").map((item: any, index: any) => {
    doors_numbersArray.push({ value: item });
  });

  return doors_numbersArray;
};

const getResults = (values: any, id: string, type: string) => {
  const results = values;

  results.doors_numbers = getStringDoorFormat(values.doors_numbers);
  // ${item.unit.unit_id},${item.unit.site.code},${item.leased_from},${item.leased_to}
  results.user_id = id;
  results.user_role = values.role.value;

  if (results.user_role == "SU" && type != "edit") {
    results.unit_id = results.details.item.unit.unit_id;
    results.site_id = results.details.item.unit.site.id;
    results.leased_from = moment(results.details.item["leased_from"]).format(
      "YYYY-M-DD"
    );
    results.leased_to = moment(results.details.item["leased_to"]).format(
      "YYYY-M-DD"
    );
  } else {
    results.site_id = values.site_id.value;
    results.leased_from = moment(values["leased_from"]).format("YYYY-MM-DD");
    results.leased_to = moment(values["leased_to"]).format("YYYY-MM-DD");
  }

  return results;
};

export const AddRental = ({ ...props }: any) => {
  const memberData = useSelector(getMemberInfo);
  const { type } = props;

  const defaultInitValues: InitValuesFormikT = {
    site_id: "",
    role: "",
    unit_id: "",
    doors_numbers: [{ value: "" }],
    leased_from: "",
    leased_to: "",
    details: "",
  };
  const [isShowForm, setIsShowForm]: any = useState({ show: false, type: "" });
  const [arrayUsers, setArrayUsers]: any = useState([]);

  const { params, path }: any = useRouteMatch();
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();

  const notyf = new Notyf({
    duration: 5000,
  });
  const [rentalForms, setRentalForms]: any = useState([
    {
      id: Number(params.id),
      isActiveRentals: false,
      initValues: defaultInitValues,
    },
  ]);

  useEffect(() => {
    if (type == "edit") {
      loadRentalForm("noEdit");
    } else {
      setIsShowForm({
        show: true,
        type: type,
      });
    }
  }, []);

  const loadRentalForm = (editType: string) => {
    setIsShowForm({
      show: false,
      type: "NoEdit",
    });
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    getRentalDetails(axios)({ id: Number(params.id) })
      .then(async (res: any) => {
        const userData = await res.data;
        const formData: any = [];

        if (userData.results.length > 0) {
          userData.results.map((itemForm: any) => {
            const doorObj = getObjectDoorFormat(itemForm.doors_numbers);
            formData.push({
              id: itemForm.id,
              isActiveRentals: false,
              initValues: {
                ...defaultInitValues,
                ...{
                  site_id: {
                    label: itemForm["unit"]["site"]["name"],
                    value: itemForm["unit"]["site"]["id"],
                  },

                  role: {
                    label: roleData[itemForm["user_role"]],
                    value: itemForm["user_role"],
                  },
                  unit_id: itemForm["unit"]["id"],
                  doors_numbers: doorObj,
                  leased_from: new Date(
                    moment(itemForm["leased_from"]).toDate()
                  ),
                  leased_to: new Date(moment(itemForm["leased_to"]).toDate()),
                },
              },
            });
            setRentalForms(formData);
            setIsShowForm({
              show: true,
              type: type,
            });
          });
        } else {
          setRentalForms([
            {
              id: Number(params.id),
              isActiveRentals: true,
              initValues: defaultInitValues,
            },
          ]);
          setIsShowForm({
            show: true,
            type: editType,
          });
        }
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error get rental details");
      });
  };

  const onDeleteMemberRental = ({ ...values }: any) => {
    const otherValues = {
      ...values,
      refresh: () => loadRentalForm("noType"),
    };
    dispatch(
      changeShowModal({
        nameModal: "deleteRentalDetails",
        stateModal: true,
        otherParams: { otherValues, deleteFunc: deleteRentalDetails(axios) },
      })
    );
  };

  const onSubmitMemberRental = ({ ...data }: any) => {
    const { values, reset } = data;
    const countOfErrors: string[] = [];
    const newUnitId =
      values.role.value == "SU"
        ? values.details.item.unit.unit_id
        : values.unit_id;

    const newSite =
      values.role.value == "SU"
        ? values.details.item.unit.site.name
        : values.site_id.label;

    const unitIdArray = arrayUsers.map((item: any, index: number) => {
      const currentUnitId =
        item.role.value == "SU" ? item.details.item.unit.unit_id : item.unit_id;
      const currentSite =
        item.role.value == "SU"
          ? item.details.item.unit.site.name
          : item.site_id.label;

      if (currentUnitId == newUnitId && currentSite == newSite) {
        countOfErrors.push(
          `Error, Unit Id must be unique. in Unit rental #${
            index + 1
          } and in current  to the users identical Unit Id`
        );
      }
    });

    if (countOfErrors.length >= 1) {
      countOfErrors.map((error: string) => {
        popUp({
          props: error,
          type: "error",
          isCustomMessage: true,
        });
      });
    } else {
      setArrayUsers([...arrayUsers, { ...values, customId: uuidv4() }]);
    }
    reset();
  };

  const onUpdateMemberRental = ({ ...data }: any) => {
    const { values, id } = data;
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const dataResult = getResults(values, params.id, isShowForm.type);

    updateRentalDetails(axios)(dataResult, id)
      .then(async (res: { data: any }) => {
        popUp({
          props: "The action was successful",
          type: "success",
          isCustomMessage: true,
        });
        const userData = await res.data;
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error updating Users rental ");
      });
  };

  function sendRequestForDeactivationPin(pin: string) {
    const arrayOfPinCodes = pin.split(",");
    arrayOfPinCodes.map((pin: any, index: number) => {
      const dataTools = {
        pin,
      };
      deletePin(axios)(dataTools)
        .then(async (res) => {
          const siteData = await res.data;
          console.log("siteData: ", siteData);
        })
        .catch((...e: any) => {
          popUp({ props: e });
          new Error("Error save Pin");
        });
    });
  }

  const onRegisterUsers = () => {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    addMember(axios)(memberData)
      .then(async (res) => {
        arrayUsers.map(async (values: any, index: any) => {
          const memberData = await res.data;
          const dataResult = getResults(values, memberData.id, isShowForm.type);
          createRentalDetails(axios)(dataResult)
            .then(async (res: { data: any }) => {
              sendRequestForDeactivationPin(dataResult.doors_numbers);
              dispatch(
                changeShowModal({
                  nameModal: "addMembers",
                  stateModal: true,
                  otherParams: { memberData },
                })
              );
              dispatch(
                changeShowModal({ nameModal: "loader", stateModal: false })
              );
            })
            .catch((...e: any) => {
              popUp({ props: e });
              dispatch(
                changeShowModal({ nameModal: "loader", stateModal: false })
              );
              new Error("Error submit Users rental ");
            });
        });
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error save Users ");
      });
  };

  const deleteUser = ({ ...props }) => {
    const { id, arrayUsers } = props;
    const newDoorValues = arrayUsers.filter((item: any) => {
      return item.customId == id ? false : true;
    });
    setArrayUsers(newDoorValues);
  };

  return (
    <>
      {isShowForm.show && (
        <div className={s.container}>
          {rentalForms.map((formData: formDataT, index: number) => {
            return (
              <div key={`${formData.id}-${index}`} className={s.boxForm}>
                {arrayUsers.length > 0 && (
                  <>
                    <div className={`${s.user__block}`}>
                      <span className={s.user_title}>User's units</span>
                      <div className={s.user_container}>
                        {arrayUsers.map((item: any, index: any) => {
                          const data = {
                            title: `Unit rental #${index + 1}`,
                            site:
                              item.role.value == "SU"
                                ? item.details.item.unit.site.name
                                : item.site_id.label,
                            role: item.role.label,
                            unitId:
                              item.role.value == "SU"
                                ? item.details.item.unit.unit_id
                                : item.unit_id,
                            doorsNumbers: getStringDoorFormat(
                              item.doors_numbers
                            ),
                            period:
                              item.role.value == "SU"
                                ? `${item.details.item.leased_from}-${item.details.item.leased_to}`
                                : `${moment(item["leased_from"]).format(
                                    "YYYY-M-DD"
                                  )} : ${moment(item["leased_to"]).format(
                                    "YYYY-M-DD"
                                  )}`,
                            customId: item.customId,
                          };
                          return (
                            <div key={data.customId} className={s.user}>
                              <div className={s.user_info}>
                                <div className={s.user_info_name}>
                                  Unit rental title
                                </div>
                                <div className={s.user_info_value}>
                                  {data.title}
                                </div>
                              </div>

                              <div className={s.user_info}>
                                <div className={s.user_info_name}>Site</div>
                                <div className={s.user_info_value}>
                                  {data.site}
                                </div>
                              </div>
                              <div className={s.user_info}>
                                <div className={s.user_info_name}>Role</div>
                                <div className={s.user_info_value}>
                                  {data.role}
                                </div>
                              </div>
                              <div className={s.user_info}>
                                <div className={s.user_info_name}>Unit ID</div>
                                <div className={s.user_info_value}>
                                  {data.unitId}
                                </div>
                              </div>
                              <div className={s.user_info}>
                                <div className={s.user_info_name}>
                                  Access (door numbers)
                                </div>
                                <div className={s.user_info_value}>
                                  {data.doorsNumbers}
                                </div>
                              </div>
                              <div className={s.user_info}>
                                <div className={s.user_info_name}>
                                  Lease Period (From – To)
                                </div>
                                <div className={s.user_info_value}>
                                  {data.period}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  deleteUser({ id: data.customId, arrayUsers })
                                }
                                className={`${s.delete}`}
                              >
                                <Trashcan />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                <MemberRentalForm
                  type={"notEdit"}
                  customTitle="Add a role and rental details to a new user"
                  customBtn={
                    arrayUsers.length <= 1 ? "Register user" : "Register users"
                  }
                  customIsActiveSubmit={arrayUsers.length >= 1 ? true : false}
                  rentalId={formData.id}
                  isActiveRentals={false}
                  initValues={formData.initValues}
                  defaultValue={JSON.parse(JSON.stringify(formData.initValues))}
                  onDelete={onDeleteMemberRental}
                  onSubmit={onSubmitMemberRental}
                  onRegisterUsers={onRegisterUsers}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
