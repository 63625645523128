import {FacilitiesBookingT} from '../../store/interface';
import {DataRowsT, DataSortingT, HeaderColumnT} from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  {columnName: 'booking__facility__site__name', width: 260, align: 'left'},
  {columnName: 'booking__facility__facility_type__name', width: 160, align: 'left'},
  {columnName: 'booking__creation_datetime', align: 'right'},
  {columnName: 'booking__date', align: 'right'},
  {columnName: 'booking__booked_to', align: 'right'},
  {columnName: 'user__email', align: 'left'},
  {columnName: 'total', align: 'right'},
  {columnName: '', width: 150, align: 'right'},
];

export const dataRows: DataRowsT[] = [
  {
    name: 'booking__facility__site__name',
    title: 'SITE',
    getCellValue: (row: FacilitiesBookingT) => row.booking.facility.site.name,
  },

  {
    name: 'booking__facility__facility_type__name',
    title: 'FACILITY NAME',
    getCellValue: (row: FacilitiesBookingT) =>
      row.booking.facility.facility_title,
  },
  {
    name: 'booking__creation_datetime',
    title: 'CREATION DATE',
    getCellValue: (row: FacilitiesBookingT) => {
      const text = row.booking.creation_datetime;
      const to = text.search('T');
      const result = text.substring(0, to);
      return result;
    },
  },
  {
    name: 'booking__date',
    title: 'BOOKING DATE',
    getCellValue: (row: FacilitiesBookingT) => row.booking.date,
  },
  {
    name: 'booking__booked_to',
    title: 'TIME: START-END',
    getCellValue: (row: FacilitiesBookingT) =>
      `${row.booking.booked_from}-${row.booking.booked_to}`,
  },
  {
    name: 'user__email',
    title: 'USER EMAIL',
    getCellValue: (row: FacilitiesBookingT) => row.user.email,
  },
  {
    name: 'total',
    title: 'POINTS AMOUNT',
    getCellValue: (row: FacilitiesBookingT) => row.total,
  },
  {
    name: '',
    title: '',
    getCellValue: (row: FacilitiesBookingT) => 'button',
  },
];

export const dataSorting: DataSortingT[] = [
  {columnName: 'booking__date', direction: 'desc'},
];
