import { AxiosInstance } from "axios";

export type addToolsT = {
  invoices_type: string;
  sync_from: string;
  sync_to: string;
};

export const addTools = (axios: AxiosInstance) => (props: addToolsT) => {
  return axios.post(`/utilities/force_sync_invoices/`, { ...props });
};

export type PinT = {
  pin: any;
};

export const genPin = (axios: AxiosInstance) => (props: PinT) => {
  return axios.get(`/utilities/door_action/${props.pin}`);
};

export const deletePin = (axios: AxiosInstance) => (props: PinT) => {
    return axios.delete(`/utilities/door_action/${props.pin}`);
  };