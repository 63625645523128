import React from 'react';

export const DeleteIcon = ({ fill='white', ...props}) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.375 2.96415H10.5625C10.4191 2.26872 10.0358 1.6435 9.47766 1.19441C8.91953 0.745326 8.22085 0.5 7.5 0.5C6.77915 0.5 6.08047 0.745326 5.52234 1.19441C4.96421 1.6435 4.58095 2.26872 4.4375 2.96415H0.625C0.45924 2.96415 0.300268 3.02897 0.183058 3.14436C0.065848 3.25974 0 3.41624 0 3.57941C0 3.74259 0.065848 3.89909 0.183058 4.01447C0.300268 4.12986 0.45924 4.19468 0.625 4.19468H1.25V13.6273C1.25099 14.389 1.55884 15.1192 2.10602 15.6578C2.65319 16.1963 3.39501 16.4992 4.16875 16.5H10.8313C11.605 16.4992 12.3468 16.1963 12.894 15.6578C13.4412 15.1192 13.749 14.389 13.75 13.6273V4.19468H14.375C14.5408 4.19468 14.6997 4.12986 14.8169 4.01447C14.9342 3.89909 15 3.74259 15 3.57941C15 3.41624 14.9342 3.25974 14.8169 3.14436C14.6997 3.02897 14.5408 2.96415 14.375 2.96415ZM7.5 1.73362C7.88767 1.73408 8.2657 1.85259 8.58226 2.0729C8.89881 2.29321 9.13839 2.60452 9.26813 2.96415H5.73187C5.86161 2.60452 6.10119 2.29321 6.41774 2.0729C6.7343 1.85259 7.11233 1.73408 7.5 1.73362ZM12.5 13.6273C12.4995 14.0628 12.3235 14.4803 12.0106 14.7882C11.6978 15.096 11.2736 15.2691 10.8313 15.2695H4.16875C3.72638 15.2691 3.30221 15.096 2.98935 14.7882C2.67649 14.4803 2.5005 14.0628 2.5 13.6273V4.19468H12.5V13.6273Z"
        fill={fill}
      />
    </svg>
  );
};
