import { AxiosInstance } from "axios";

export type deleteAdvertisingT = {
    id: string
}
export const deleteAdvertising = (axios: AxiosInstance) => ({ ...props }: deleteAdvertisingT) => {
    const { id } = props;
    return axios.delete(`/ads/${id}/`);
}



export const addAdvertising = (axios: any) => (formData: any) => {
    return axios.post(`/ads/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}



export const updateAdvertisingApi = (axios: any) => ({ ...props }: any) => {
    const { formData, id } = props;

    return axios.patch(`/ads/${id}/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}


export type getAdvertisingByIdT = {
    id: string,
}
export const getAdvertisingById = (axios: any) => ({ ...props }: getAdvertisingByIdT) => {
    const { id } = props;
    return axios.get(`/ads/${id}/`);
}