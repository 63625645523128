import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendNotification } from '../../../api';
import { AddNotificationForm } from '../../../components/Forms/AddNotification/AddNotificationForm';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './AddNotification.module.scss';

export const AddNotification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initValues = {
    addressee: [],
    title: '',
    body: '',
    all: false,
  };

  const onSubmit = (props: typeof initValues) => {
    const { body, title, all, addressee } = props;

    const userIds = addressee.map((item: any) => item['value']);

    const req = {
      body: body,
      title: title,
      users_list: all ? 'all' : userIds.join(','),
    };

    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const axios = axiosInstance(history);
    sendNotification(axios)(req)
      .then(async () => {
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        dispatch(
          changeShowModal({ nameModal: 'addNotification', stateModal: true }),
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error send Notification ');
      });
  };

  return (
    <div>
      <div className={s.title}>New Notification</div>
      <div className={s.boxForm}>
        <AddNotificationForm initValues={initValues} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
