import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteBooking.module.scss';

type otherParamsT = {
  deleteFunc: any;
  props: any;
  refresh: any;
};

export const ModalDeleteBooking = ({...props}) => {
  // Create an instance of Notyf
  const notyf = new Notyf();

  const {otherParams} = props;
  const dispatch = useDispatch();
  const history: any = useHistory();

  const onBackRental = () => {
    dispatch(
      changeShowModal({
        nameModal: 'facilityDeleteBooking',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goDeleteBooking = (otherParams: any) => {
    const {deleteFunc, props, refresh}: otherParamsT = otherParams;
    deleteFunc({id: props.row.booking.id})
      .then(async (res: {data: any}) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        if (refresh != undefined) {
          refresh();
        }
        await dispatch(
          changeShowModal({
            nameModal: 'facilityDeleteBooking',
            stateModal: false,
            otherParams: undefined,
          }),
        );
        await history.push(PrivateRoutes.booking.list);
      })
      .catch(({...e}: any) => {
        popUp({props: e});
        dispatch(
          changeShowModal({
            nameModal: 'facilityDeleteBooking',
            stateModal: false,
          }),
        );
      });
  };
  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to cancel this booking and refund the points?{' '}
      </div>

      <div className={s.info}>
        <div className={s.info_item}>
          <div className={s.info_name}>Door Number: </div>
          <div className={s.info_value}>
            {otherParams.props.row.booking.facility.door_number}
          </div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>User Email:</div>
          <div className={s.info_value}>{otherParams.props.row.user.email}</div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Booking Date: </div>
          <div className={s.info_value}>
            {otherParams.props.row.booking.date}
          </div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Time Start-End:</div>
          <div
            className={s.info_value}
          >{`${otherParams.props.row.booking.facility.open_from}-${otherParams.props.row.booking.facility.open_to}`}</div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Refundable points amount:</div>
          <div className={s.info_value}>{otherParams.props.row.total}</div>
        </div>
      </div>
      <div className={s.control}>
        <Button
          text="No, quit"
          type="submit"
          onClick={() => onBackRental()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete Facility Booking"
          type="submit"
          onClick={() => goDeleteBooking(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
