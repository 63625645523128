export type getFacilityIdT = {
    id: string,
}
export const getFacilityId = (axios: any) => ({ ...props }: getFacilityIdT) => {
    const { id } = props;
    return axios.get(`/facilities/${id}/`);
}

export type getFacilitiesBookDateT = {
    date: string,
    id: string
}
export const getFacilitiesBookDate = (axios: any) => ({ ...props }: getFacilitiesBookDateT) => {
    const { date, id } = props;
    return axios.get(`facilities/${id}/booking/booked_hours/${date}/`);
}


export type findFacilitiesUserIdT = {
    search: string,
    page_number: string,
}
export const findFacilitiesUserId = (axios: any) => ({ page_number, search = '' }: findFacilitiesUserIdT) => {

    return (
        axios.get(`users/?search=${search}&page_number=${page_number}`, {
            params: {
                page: page_number,
                search: search,
            }
        })
    )
}

export type FacilitiesBookT = {
    /* eslint-disable camelcase */
    facility_id: number,
    user: number,
    date: string,
    booked_from: string,
    booked_to: string,
    /* eslint-enable camelcase */
}

export const addFacilitiesBook = (axios: any) => ({ ...props }: FacilitiesBookT) => {
    return axios.post(`/facilities/booking/`, { ...props });
}

export type deleteFacilitiesBookT = {
    id: string
}
export const deleteFacilitiesBook = (axios: any) => ({ ...props }: deleteFacilitiesBookT) => {
    const { id } = props;
    return axios.delete(`/facilities/booking/${id}/`);
}

type getFacilitiesTypesOptionT = {
    page_number: string,
    search: string
}
export const getFacilitiesTypesOption = (axios: any) => ({ page_number, search = '' }: getFacilitiesTypesOptionT) => {

    return axios.get(`facilities/types/?search=${search}&page_number=${page_number}`,
        {
            params: {
                page: page_number,
                search: search,
            }
        });

}

type getFacilitiesOptionT = {
    page_number: string,
    search: string
}
export const getFacilitiesOption = (axios: any) => ({ page_number, search = '' }: getFacilitiesOptionT) => {

    return axios.get(`facilities/?search=${search}&page_number=${page_number}`,
        {
            params: {
                page: page_number,
                search: search,
            }
        });

}


export const addFacility = (axios: any) => (formData: any) => {
    return axios.post(`/facilities/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}


export const updateFacility = (axios: any) => ({ formData, id }: any) => {
    return axios.patch(`/facilities/${id}/`, formData, {

        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export type saveFacilitiesT = {
    facility_id: number,
    offer: number,
    promoted_from: string,
    promoted_to: string
}
export const saveFacilities = (axios: any) => ({ ...props }: saveFacilitiesT) => {
    return axios.post(`facilities/promotions/`, { ...props });
}

export type deleteFacilityT = {
    id: string,
}
export const deleteFacility = (axios: any) => ({ ...props }: deleteFacilityT) => {
    const { id } = props;
    return axios.delete(`/facilities/${id}/`);
}
