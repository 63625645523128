import { authState, HeaderInfoT, MenuT, SettingsT } from '../interface';
import { ModalsListT, ModalStatusT, NameModals, NameTables } from '../reducers/SideMenu';

export const getBearerToken = ({ ...state }) => {
  const Auth: authState = state.auth;
  return Auth.access_token;
};

export const getSideMenu = ({ ...state }) => {
  const SideMenu: MenuT[] = state.sideMenu.sideMenu;
  return SideMenu;
};

export const getSelectedMainCell = ({ ...state }) => {
  const Settings: SettingsT = state.sideMenu.settings;
  return Settings.sideMenuMainSelected;
};

export const getSelectedChildCell = ({ ...state }) => {
  const Settings: SettingsT = state.sideMenu.settings;
  return Settings.sideMenuChildSelected;
};

export const getStateModal = (nameModal: NameModals) => ({ ...state }) => {
  const modalList: ModalsListT = state.sideMenu.modalsList;
  const selectedModal: ModalStatusT = modalList[nameModal];
  return { name: nameModal, data: selectedModal };
}

export const getStateTable = (nameTable: NameTables) => ({ ...state }) => {
  const TableList: any = state?.sideMenu?.tablesList?.tables;
  const selectedTable: any = TableList[nameTable]
  return selectedTable;
};

export const getHeaderInfo = ({ ...state }) => {
  const headerInfo: HeaderInfoT = state.sideMenu.headerInfo;
  return headerInfo;
};
export const getMemberInfo = ({ ...state }) => {
  const headerInfo: any = state.sideMenu.memberInfo;
  return headerInfo;
};

export const getTableInfo = ({ ...state }) => {
  const memberInfo: any = state.sideMenu.tablesList.tablesInfo;
  return memberInfo;
};



