import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { ModalStatusT, NameModals } from '../../../store/reducers/SideMenu';
import { getStateModal } from '../../../store/selectors/selectors';
import { AddNotificationModal } from '../AddNotificationModal/AddNotificationModal';
import { FrameModals } from '../FrameModals/FrameModals';
import { ModalAddAdvertising } from '../ModalAddAdvertising/ModalAddAdvertising';
import { ModalAddFacility } from '../ModalAddFacility/ModalAddFacility';
import { ModalAddFacilityBook } from '../ModalAddFacilityBook/ModalAddFacilityBook';
import { ModalAddFaq } from '../ModalAddFaq/ModalAddFaq';
import { ModalAddMembers } from '../ModalAddMembers/ModalAddMembers';
import { ModalAddPromotion } from '../ModalAddPromotion/ModalAddPromotion';
import { ModalAddSite } from '../ModalAddSite/ModalAddSite';
import { ModalAddTools } from '../ModalAddTools/ModalAddTools';
import { ModalBookingInfo } from '../ModalBookingInfo/ModalBookingInfo';
import { ModalDeleteAdvertising } from '../ModalDeleteAdvertising/ModalDeleteAdvertising';
import { ModalDeleteBooking } from '../ModalDeleteBooking/ModalDeleteBooking';
import { ModalDeleteDoorInput } from '../ModalDeleteDoorInput/ModalDeleteDoorInput';
import { ModalDeleteFacility } from '../ModalDeleteFacility/ModalDeleteFacility';
import { ModalDeleteFaq } from '../ModalDeleteFaq/ModalDeleteFaq';
import { ModalDeletePromotion } from '../ModalDeletePromotion/ModalDeletePromotion';
import { ModalDeleteRental } from '../ModalDeleteRental/ModalDeleteRental';
import { ModalDeleteSite } from '../ModalDeleteSite/ModalDeleteSite';
import { ModalLoader } from '../ModalLoader/ModalLoader';
import { ModalMemberResetPassword } from '../ModalMemberResetPassword/ModalMemberResetPassword';
import { ModalUpdateSite } from '../ModalUpdateSite/ModalUpdateSite';
import { ModalSyncCreditMemo } from '../ModalSyncCreditMemo/ModalSyncCreditMemo';

export const ManagerForms = () => {
  const dispatch = useDispatch();

  const addPromotion: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('addPromotion'),
  );
  const deletePromotion: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deletePromotion'),
  );
  const addMembers: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('addMembers'),
  );
  const memberResetPassword: {name: NameModals; data: ModalStatusT} =
    useSelector(getStateModal('memberResetPassword'));
  const loader: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('loader'),
  );
  const addSite: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('newSite'),
  );
  const updateSite: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('updateSite'),
  );
  const deleteSite: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteSite'),
  );
  const addHelp: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('addHelp'),
  );
  const addNotification: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('addNotification'),
  );
  const deleteFaq: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteFaq'),
  );

  const closeFunc = (name: NameModals) => {
    dispatch(changeShowModal({nameModal: name, stateModal: false}));
  };

  const modalDeleteRental: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteRentalDetails'),
  );

  const modalAddFacilityBook: {name: NameModals; data: ModalStatusT} =
    useSelector(getStateModal('modalAddFacilityBook'));
  const facilityDeleteBooking: {name: NameModals; data: ModalStatusT} =
    useSelector(getStateModal('facilityDeleteBooking'));
  const addNewFacility: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('addNewFacility'),
  );
  const deleteFacility: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteFacility'),
  );

  const newTools: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('newTools'),
  );

  const syncCreditMemo: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('syncCreditMemo'),
  );

  const newAdvertising: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('newAdvertising'),
  );
  const deleteAdvertising: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteAdvertising'),
  );
  const deleteDoorInput: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('deleteDoorInput'),
  );

  const facilityBookingInfo: {name: NameModals; data: ModalStatusT} = useSelector(
    getStateModal('facilityBookingInfo'),
  );
  
  return (
    <>
      {addPromotion.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addPromotion.name)}>
          <ModalAddPromotion
            otherParams={addPromotion.data['otherParams']}
          ></ModalAddPromotion>
        </FrameModals>
      )}
      {deletePromotion.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deletePromotion.name)}>
          <ModalDeletePromotion
            otherParams={deletePromotion.data['otherParams']}
          ></ModalDeletePromotion>
        </FrameModals>
      )}
      {addMembers.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addMembers.name)}>
          <ModalAddMembers
            otherParams={addMembers.data['otherParams']}
          ></ModalAddMembers>
        </FrameModals>
      )}
      {memberResetPassword.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(memberResetPassword.name)}>
          <ModalMemberResetPassword
            otherParams={memberResetPassword.data['otherParams']}
          ></ModalMemberResetPassword>
        </FrameModals>
      )}
      {addSite.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addSite.name)}>
          <ModalAddSite></ModalAddSite>
        </FrameModals>
      )}
      {updateSite.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(updateSite.name)}>
          <ModalUpdateSite></ModalUpdateSite>
        </FrameModals>
      )}
      {deleteSite.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deleteSite.name)}>
          <ModalDeleteSite
            otherParams={deleteSite.data['otherParams']}
          ></ModalDeleteSite>
        </FrameModals>
      )}
      {addHelp.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addHelp.name)}>
          <ModalAddFaq></ModalAddFaq>
        </FrameModals>
      )}
      {addNotification.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addNotification.name)}>
          <AddNotificationModal></AddNotificationModal>
        </FrameModals>
      )}
      {modalDeleteRental.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(modalDeleteRental.name)}>
          <ModalDeleteRental
            otherParams={modalDeleteRental.data['otherParams']}
          ></ModalDeleteRental>
        </FrameModals>
      )}
      {modalAddFacilityBook.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(modalAddFacilityBook.name)}>
          <ModalAddFacilityBook
            otherParams={modalAddFacilityBook.data['otherParams']}
          ></ModalAddFacilityBook>
        </FrameModals>
      )}
      {facilityDeleteBooking.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(facilityDeleteBooking.name)}>
          <ModalDeleteBooking
            otherParams={facilityDeleteBooking.data['otherParams']}
          ></ModalDeleteBooking>
        </FrameModals>
      )}
      {addNewFacility.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(addNewFacility.name)}>
          <ModalAddFacility
            otherParams={addNewFacility.data['otherParams']}
          ></ModalAddFacility>
        </FrameModals>
      )}
      {deleteFacility.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deleteFacility.name)}>
          <ModalDeleteFacility
            otherParams={deleteFacility.data['otherParams']}
          ></ModalDeleteFacility>
        </FrameModals>
      )}
      {newTools.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(newTools.name)}>
          <ModalAddTools
            otherParams={newTools.data['otherParams']}
          ></ModalAddTools>
        </FrameModals>
      )}
      {newAdvertising.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(newAdvertising.name)}>
          <ModalAddAdvertising
            otherParams={newAdvertising.data['otherParams']}
          ></ModalAddAdvertising>
        </FrameModals>
      )}
      {deleteAdvertising.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deleteAdvertising.name)}>
          <ModalDeleteAdvertising
            otherParams={deleteAdvertising.data['otherParams']}
          ></ModalDeleteAdvertising>
        </FrameModals>
      )}
      {deleteFaq.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deleteFaq.name)}>
          <ModalDeleteFaq
            otherParams={deleteFaq.data['otherParams']}
          ></ModalDeleteFaq>
        </FrameModals>
      )}
      {deleteDoorInput.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(deleteDoorInput.name)}>
          <ModalDeleteDoorInput
            otherParams={deleteDoorInput.data['otherParams']}
          ></ModalDeleteDoorInput>
        </FrameModals>
      )}
       {facilityBookingInfo.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(facilityBookingInfo.name)}>
          <ModalBookingInfo
            otherParams={facilityBookingInfo.data['otherParams']}
          ></ModalBookingInfo>
        </FrameModals>
      )}
      {syncCreditMemo.data['isShow'] && (
        <FrameModals closeFunc={() => closeFunc(syncCreditMemo.name)}>
          <ModalSyncCreditMemo
            otherParams={syncCreditMemo.data['otherParams']}
          ></ModalSyncCreditMemo>
        </FrameModals>
      )}

      {loader.data['isShow'] && <ModalLoader />}
      {/* <div className={s.modalBox}>
                    <div className={s.title}> Are you sure you want to delete Promotion?  </div>
                    <div className={s.description}>This Promotion will be deleted immediately. You can not undo this action </div>
                    <div className={s.control}>
                        <Button text="No, Cancel" type="submit" class_type="secondary" />
                        <Button text="Yes, Delete Promotion" type="submit" class_type="main" />
                    </div>
                </div> */}
    </>
  );
};
