import moment from 'moment';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  { columnName: 'title', align: 'left' },
  { columnName: 'body', align: 'left' },
  { columnName: 'recipient_str', align: 'right' },
  { columnName: 'datetime', align: 'right' },
];

export const dataRows: DataRowsT[] = [
  {
    name: 'title',
    title: 'Notification Title',
    getCellValue: (row: { title: string }): string => row.title,
  },
  {
    name: 'body',
    title: 'Notification body',
    getCellValue: (row: { body: string }): string => row.body,
  },
  {
    name: 'recipient_str',
    title: 'ADDRESSEE',
    getCellValue: (row: { recipient: string }): string => row.recipient,
  },
  {
    name: 'datetime',
    title: 'Sending Date',
    getCellValue: (row: { datetime: moment.MomentInput }): string =>
      `${moment(row.datetime).format('DD.MM.YYYY')}`,
  },
];

export const dataSorting: DataSortingT[] = [
  { columnName: 'datetime', direction: 'desc' },
];
