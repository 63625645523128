import React from 'react'
import { EditIcon } from '../../../assets/EditIcon'
import s from './InputNotEdit.module.scss'

type InputNotEditT = {
    isShowIcon?: boolean,
    setIsEdit?: any,
    isInteracts?: boolean
    value: string
}

export const InputNotEdit = ({ ...props }: InputNotEditT) => {
    const { isShowIcon, setIsEdit, isInteracts, value } = props;

    let classComponent: string = `${s.inputNotEdit}`
    if (isInteracts) classComponent += ` ${s.notInteracts}`
    return (
        <div className={`${classComponent} ${!isShowIcon && s.disable}`}>
            <div className={s.value} onDoubleClick={() => setIsEdit()} >{value}</div>
            {isShowIcon && (<div className={s.iconEdit} onClick={() => setIsEdit()}>
                <EditIcon />
            </div>)}
        </div>
    )
}
