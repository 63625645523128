import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteAdvertising } from '../../../api';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import {
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/AdsData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';
import s from './ListAdvertising.module.scss';

export const ListAdvertising = () => {
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();

  const advertisingHandler = ({e, props, refresh}: any) => {
    e.stopPropagation();
    dispatch(
      changeShowModal({
        nameModal: 'deleteAdvertising',
        stateModal: true,
        otherParams: {
          id: props.tableRow.row.id,
          refresh,
          deleteFunc: deleteAdvertising(axios),
        },
      }),
    );
  };

  const defaultContent: DefaultContentT = {
    title: 'Sites Advertising',
    tableName: 'ads',
    tableAPI: '/ads/',
    sortingBySite: false,
    setButton: {
      column: 'site_target',
      text: 'Cancel Ads',
      style: s.book,
      clickEvent: advertisingHandler,
    },
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
