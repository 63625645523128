import { memberDataT } from '../../scenes/Members';
import { TYPE_REDUCER } from '../../utils/constants';
import { HeaderGetSiteDataT, TablesInfoT } from '../interface';
import { NameModals, NameTables } from '../reducers/SideMenu';

export const SelectSideMenuMain = (mainSelected: string, childSelected: null | string): any => ({
  type: TYPE_REDUCER.SELECT_SIDE_MENU,
  payload: {
    mainSelected,
    childSelected,
  },
});
type changeShowModalT = {
  nameModal: NameModals,
  stateModal: boolean,
  otherParams?: any,
}
export const changeShowModal = ({ ...data }: changeShowModalT) => ({
  type: TYPE_REDUCER.CHANGE_SHOW_MODALS,
  payload: data,
});

export const deletePromotionAction = ({ ...data }: any) => ({
  type: TYPE_REDUCER.DELETE_PROMOTION,
  payload: data,
});


type UpdateRowsT = {
  nameTable: NameTables,
  otherParams?: any,
}

export const UpdateRows = (data: UpdateRowsT) => ({
  type: TYPE_REDUCER.UPDATE_ROWS,
  payload: data,
});


export const updateQuery = (data: { tableName: NameTables, query: string }) => ({
  type: TYPE_REDUCER.UPDATE_QUERY,
  payload: data
})

export const changePageTable = (data: { nameTable: NameTables, page: number }) => ({
  type: TYPE_REDUCER.CHANGE_PAGE_NUMBER,
  payload: data
})

type HeaderUpdateSelectT = {
  selected: {
    value: string;
    label: string;
  }
}
export const HeaderUpdateSelect = (data: HeaderUpdateSelectT) => ({
  type: TYPE_REDUCER.HEADER_UPDATE_SELECT,
  payload: data,
});

type HeaderUpdateSearchT = {
  search: string
}
export const HeaderUpdateSearch = (data: HeaderUpdateSearchT) => ({
  type: TYPE_REDUCER.HEADER_UPDATE_SEARCH,
  payload: data,
});

export const HeaderGetSiteData = (data: HeaderGetSiteDataT[]) => ({
  type: TYPE_REDUCER.HEADER_GET_SITE_DATA,
  payload: data,
});

export const FetchInit = () => ({
  type: TYPE_REDUCER.FETCH_INIT,
  payload: true,
});

type UpdateLastQueryT = {
  lastQuery: any;
};
export const UpdateLastQuery = (lastQuery: UpdateLastQueryT) => ({
  type: TYPE_REDUCER.UPDATE_PREV_QUERY,
  payload: lastQuery,
});

type UpdatePrevQueryT = {
  prevQuery: any;
};
export const UpdatePrevQuery = (prevQuery: UpdatePrevQueryT) => ({
  type: TYPE_REDUCER.UPDATE_PREV_QUERY,
  payload: prevQuery,
});

type UpdateNextQueryT = {
  nextQuery: string;
};
export const UpdateNextQuery = (nextQuery: UpdateNextQueryT) => ({
  type: TYPE_REDUCER.UPDATE_NEXT_QUERY,
  payload: nextQuery,
});

export const StartLoading = (data: any) => ({
  type: TYPE_REDUCER.START_LOADING,
  payload: data,
});

export const setMemberInfo = (data: memberDataT) => ({
  type: TYPE_REDUCER.MEMBERS_INFO,
  payload: data,
});


export const setCurrentTableInfo = (data: TablesInfoT) => {
  return ({
    type: TYPE_REDUCER.CURRENT_DATA_INFO,
    payload: data,
  })
};



export default SelectSideMenuMain;
