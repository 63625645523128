import { Form, Formik } from 'formik';
import moment from 'moment';
import { equals } from 'ramda';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { getFacilitiesOption, saveFacilitiesT } from '../../../api';
import { DeleteIcon } from '../../../assets/DeleteIcon';
import axiosInstance from '../../../utils/axios';
import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
import { loadOptions } from '../../FormComponents/AsyncSelect/LoadingData/LoadingData';
import { Button } from '../../FormComponents/Button/Button';
import { DataPicker } from '../../FormComponents/DataPicker/DataPicker';
import { Input } from '../../FormComponents/Input/Input';
import { InputNotEdit } from '../../FormComponents/InputNotEdit/InputNotEdit';
import s from './AddNewPromotion.module.scss';

type AddNewPromotionT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidationSchema: any = () => {
  return Yup.object({
    facilityId: Yup.object().required('The field is required'),
    offer: Yup.number()
      .required('The field is required')
      .when('facilityId', (facilityId, schema) => {
        if (facilityId) {
          const maxPrice: number = facilityId['item']['price_per_hour'];
          return schema.max(
            maxPrice,
            'The price should be lower than the price of facility',
          );
        } else {
          return schema.max(-1, 'Select the desired facility');
        }
      }),
    promoted_from: Yup.string().required('The field is required'),
    promoted_to: Yup.string().required('The field is required'),
  });
};

export const AddNewPromotion = ({...props}: AddNewPromotionT) => {
  const {initialValues, defaultValue, onSubmit, onDelete, type, infoPromotion} =
    props;
  const [startDate, setStartDate] = useState(initialValues['promoted_from']);
  const [endDate, setEndDate] = useState(initialValues['promoted_to']);

  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);

  const history = useHistory();
  const axios = axiosInstance(history);

  const isEdit = type === 'edit';

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
      infoPromotion={infoPromotion}
    >
      {({values, ..._}) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
            promoted_from: new Date(
              moment(defaultValue?.promoted_from).toDate(),
            ),
            promoted_to: new Date(moment(defaultValue?.promoted_to).toDate()),
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        return (
          <Form>
            <div className={s.form}>
              <div className={s.InputBox}>
                <div className={s.title}>Facility ID *</div>

                {isEdit ? (
                  <InputNotEdit
                    value={values.facilityId.label}
                    isInteracts={true}
                  />
                ) : (
                  <AsyncSelect
                    noOptionsMessage={() => 'No data found'}
                    loadOptions={loadOptions(getFacilitiesOption(axios))}
                    loadingMessage={() => 'loading facility ...'}
                    placeholder="Enter Correct Facility ID"
                    name="facilityId"
                  />
                )}
              </div>
              <div className={s.InputBox}>
                <Input
                  type="text"
                  placeholder="Offer Per Hour *"
                  name="offer"
                  title="OfferPerHour"
                  typeEdit={isEdit}
                />
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <div className={s.title}>Campaign Period (From) *</div>
                  <DataPicker
                    placeholderText="Select campaign start date"
                    name="promoted_from"
                    dateFormat="yyyy-M-dd"
                    selected={startDate}
                    onChangeCustom={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    typeEdit={isEdit}
                    minDate={new Date()}
                  />
                </div>
                <div className={s.InputBox}>
                  <div className={s.title}>Campaign Period (To) *</div>
                  <DataPicker
                    placeholderText="Select campaign end date"
                    name="promoted_to"
                    dateFormat="yyyy-M-dd"
                    onChangeCustom={setEndDate}
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    typeEdit={isEdit}
                  />
                </div>
              </div>

              <div className={s.button}>
                {isEdit ? (
                  <div className={s.groupButton}>
                    <Button
                      text="Save Changes"
                      type="submit"
                      class_type={isSaveChangeParams ? 'main' : 'disabled'}
                    />
                    <Button
                      left_icon={<DeleteIcon />}
                      text="Delete promo"
                      onClick={() => onDelete({values})}
                      class_type="red"
                    />
                  </div>
                ) : (
                  <Button
                    text="Publish Promotion"
                    type="submit"
                    class_type="main"
                  />
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
