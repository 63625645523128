export type additionalOptionsT = {
  page_number: number;
  search?: string;
  [key: string]: any;
};

type responseData = {
  count: number;
  next: string;
  previous: string | null;
  results: any[];
};
type optionT = {
  value: string;
  label: string;
  item: any;
};

export const loadOptions =
  (getOptions: any) =>
    async (_: any, loadedOptions: any, ...props: additionalOptionsT[]) => {
      const { search, page_number } = props[0];

      const response: any = await getOptions({ page_number, search });

      const dataResponse: responseData = response.data;

      const options: optionT[] = dataResponse.results.map((item: any) => {
        const label: string = `Facility ID: ${item.id}, ${item.facility_title} (${item.site.name})`;
        return { value: item.id, label, item };
      });

      return {
        options: options,
        hasMore: !!dataResponse.next,
        additional: {
          search: search,
          page_number: page_number + 1,
        },
      };
    };

export const loadingDataFacilitiesUserInfo =
  (getOptions: any) =>
    async (_: any, loadedOptions: any, ...props: additionalOptionsT[]) => {
      const { search, page_number } = props[0];

      const response: any = await getOptions({ page_number, search });

      const dataResponse: responseData = response.data;

      const options: optionT[] = dataResponse.results.map((item: any) => {
        const label: string = `email: ${item.email} (${item.first_name} ${item.last_name})`;
        return { value: item.id, label, item };
      });

      return {
        options: options,
        hasMore: !!dataResponse.next,
        additional: {
          search: search,
          page_number: page_number + 1,
        },
      };
    };

export const loadRentalOptions =
  (getOptions: any) =>
    async (_: any, loadedOptions: any, ...props: additionalOptionsT[]) => {
      const { search, page_number } = props[0];

      const response: any = await getOptions({ page_number, search });

      const dataResponse: responseData = response.data;

      const options: optionT[] = dataResponse.results.map((item: any) => {
        const label: string = `UnitID: ${item.unit.unit_id}, ${item.unit.site.code} (${item.leased_from}:${item.leased_to})`;
        return { value: item.unit.id, label, item };
      });

      return {
        options: options,
        hasMore: !!dataResponse.next,
        additional: {
          search: search,
          page_number: page_number + 1,
        },
      };
    };

export const loadFacilityTypes =
  (getOptions: any) =>
    async (_: any, loadedOptions: any, ...props: additionalOptionsT[]) => {
      const { search, page_number } = props[0];

      const response: any = await getOptions({ page_number, search });

      const dataResponse: responseData = response.data;

      const options: optionT[] = dataResponse.results.map((item: any) => {
        const label: string = `${item.name}`;
        return { value: item.id, label, item };
      });

      return {
        options: options,
        hasMore: !!dataResponse.next,
        additional: {
          search: search,
          page_number: page_number + 1,
        },
      };
    };
