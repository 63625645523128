import { AxiosInstance } from 'axios';

export type findNotificationUserIdT = {
    search: string,
    page_number: string,
}
export const findNotificationUserId = (axios: AxiosInstance) => ({ page_number, search = '' }: findNotificationUserIdT) => {

    return (
        axios.get(`users/?search=${search}&page_number=${page_number}`, {
            params: {
                page: page_number,
                search: search,
            }
        })
    )
}

type sendNotificationT = {
    users_list: string,
    title: string,
    body: string
}

export const sendNotification = (axios: AxiosInstance) => (body: sendNotificationT) => {
    return axios.post('/notifications/', body)
}