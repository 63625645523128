import { Notyf } from 'notyf';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddMemberForm from '../../../components/Forms/AddMember/AddMemberForm';
import { setMemberInfo } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import s from './AddMember.module.scss';

export type memberDataT = {
  /* eslint-disable camelcase */
  email: string;
  first_name: string;
  last_name: string;
  salesforce_id: string;
  company_name: string;
  phone_number: string;
  correspondence_address: string;
  can_see_facilities_from_other_sites: boolean;
  activated: boolean;
  /* eslint-enable camelcase */
};

export const AddMember = () => {
  const notyf = new Notyf({
    duration: 5000,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const initValues: memberDataT = {
    email: '',
    first_name: '',
    last_name: '',
    salesforce_id: '',
    company_name: '',
    phone_number: '',
    correspondence_address: '',
    can_see_facilities_from_other_sites: false,
    activated: true
  };

  const onSubmit = (history: any) => (values: memberDataT) => {
    dispatch(setMemberInfo(values));
    history.push(PrivateRoutes.members.rental);
  };

  return (
    <div>
      <div className={s.title}>Add New User</div>
      <div className={s.boxForm}>
        <AddMemberForm initValues={initValues} onSubmit={onSubmit(history)} />
      </div>
    </div>
  );
};
