import React from 'react';
import { ArrowLeft } from '../../../assets/ArrowLeft';
import { ButtonT } from '../../../utils/headerData/headerTypes';
import s from './BackBtn.module.scss';
import { useHistory } from 'react-router-dom';
import { setCurrentTableInfo } from '../../../store/actions/SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { TablesInfoT } from '../../../store/interface';
import { getTableInfo } from '../../../store/selectors/selectors';

export default function BackBtn(props: ButtonT) {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableInfo: TablesInfoT = useSelector(getTableInfo);

  const { pathName, textContent } = props;

  function handlerClick() {
    dispatch(setCurrentTableInfo({
      backDetailBtn: true,
      currentPage: tableInfo.currentPage,
      currentIndexRow: tableInfo.currentIndexRow
    }));

    history.push(pathName);
  }

  return (
    <div onClick={handlerClick} className={s.btn}>
      <ArrowLeft className={s.arrow} />
      <div className={s.text}>{textContent}</div>
    </div>
  );
}
