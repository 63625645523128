import {useHistory} from 'react-router-dom';
import {LogoutIcon} from '../../../assets/LogoutIcon';
import {WPSLogoWhite} from '../../../assets/WPSLogoWhite';
import s from './SidePanelHeader.module.scss';
import React from 'react';

export const SidePanelHeader = () => {
  const history = useHistory();

  const logout = (history: any) => () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    sessionStorage.removeItem('selectCell')
    history.go('/login');
  };

  return (
    <div className={s.side_panel_header}>
      <div className={s.first_half}>
        <div>
          <WPSLogoWhite />
        </div>
        <div className={s.bottom_text}>Work+Store Administrator</div>
      </div>
      <div
        className={s.logoutButton}
        onClick={logout(history)}
        onKeyPress={logout(history)}
        role="button"
        tabIndex={0}
      >
        <LogoutIcon />
      </div>
    </div>
  );
};

export default SidePanelHeader;
