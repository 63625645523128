type toolsT = {
    value: string,
    label: string,
}
type toolsOptionsT = {
    options: toolsT[],
    [key: string]: any
}
type toolsDataT = {
    paid: string;
    unpaid: string;
    [key: string]: any
}
export const toolsData: toolsDataT = {
    paid: 'Paid',
    unpaid: 'Unpaid',
}
export const toolsOptions = (): toolsOptionsT => ({
    options: [
        {
            value: 'paid',
            label: 'Paid',
        },
        {
            value: 'unpaid',
            label: 'Unpaid',
        },
    ],
});