import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { addTools, deletePin, genPin } from "../../../api";
import { AddToolsForm } from "../../../components/Forms/AddToolsForm/AddToolsForm";
import { DoorPinForm } from "../../../components/Forms/DoorPinForm/DoorPinForm";
import { changeShowModal } from "../../../store/actions/SideMenu";
import axiosInstance from "../../../utils/axios";
import { popUp } from "../../../utils/helpersFunction";
import s from "./DoorPin.module.scss";

export type initValues = {
  pin: any;
};

export const DoorPin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePin, setActivePin]: any = useState(
    "The requested Door Passcode will be here"
  );
  const initValues: initValues = {
    pin: "",
  };

  const onSubmit = (history: any) => (values: initValues) => {
    const dataTools = {
      pin: values.pin,
    };

    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const axios = axiosInstance(history);
    genPin(axios)(dataTools)
      .then(async (res) => {
        const siteData = await res.data;
        if (siteData.info === "JobInProgress") {
          setActivePin("Processing in progress, try again in a minute");
        } else {
          setActivePin(siteData.info);
        }
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        setActivePin("error");
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error save Tools");
      });
  };

  const onDeactivate = (history: any) => (values: initValues) => {
    const dataTools = {
      pin: values.pin,
    };
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const axios = axiosInstance(history);
    deletePin(axios)(dataTools)
      .then(async (res) => {
        const siteData = await res.data;
        setActivePin("The requested Door Passcode will be here");
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        setActivePin("error");
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error save Tools");
      });
  };

  function onChangePinInput() {
    setActivePin("The requested Door Passcode will be here");
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>Door Passcode request</div>
      <div className={s.boxForm}>
        <DoorPinForm
          initialValues={initValues}
          onSubmit={onSubmit(history)}
          onDeactivate={onDeactivate(history)}
          onChangePinInput={onChangePinInput}
        />
        <div className={s.area_pin}>
          <span className={s.area_text}>{activePin}</span>
        </div>
      </div>
    </div>
  );
};
