import React from 'react';
import { HelpForm } from '../../components/Forms/Help/Help';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

type helpDataT = {
  id: number;
  question: string;
  response: string;
};

export const headerColumn: HeaderColumnT[] = [
  {columnName: 'question', width: 1170, align: 'left'},
  // {columnName: 'delete', width: 300, align: 'right'},
];

export const dataRows: DataRowsT[] = [
  {
    name: 'question',
    title: '',
    getCellValue: ({id, question, response}: helpDataT): JSX.Element => (
      <HelpForm id={id} type="edit" initValues={{question, response}} />
    ),
  },
  // {
  //     name: 'delete',
  //     title: '',
  //     getCellValue: ({id}: helpDataT): JSX.Element => (
  //         <DeleteFaqButton id={id} />
  //       )
  // },
];

export const dataSorting: DataSortingT[] = [
  {columnName: 'question', direction: 'asc'},
  // {columnName: 'delete', direction: 'asc'},
];
