export const Building = ({ ...props }) => (
  <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.40075 0V4.3621H4.22472C3.74793 4.3621 3.29066 4.54842 2.95352 4.88006C2.61637 5.21171 2.42697 5.66151 2.42697 6.13053V7.86947H2.397C1.76128 7.86947 1.15159 8.11789 0.702067 8.56009C0.252541 9.00228 0 9.60202 0 10.2274V27.4105C0 27.5669 0.0631353 27.7168 0.175517 27.8273C0.287898 27.9379 0.44032 28 0.599251 28H15.4007C15.5597 28 15.7121 27.9379 15.8245 27.8273C15.9369 27.7168 16 27.5669 16 27.4105V10.2274C16 9.60202 15.7475 9.00228 15.2979 8.56009C14.8484 8.11789 14.2387 7.86947 13.603 7.86947H13.573V6.13053C13.573 5.66151 13.3836 5.21171 13.0465 4.88006C12.7093 4.54842 12.2521 4.3621 11.7753 4.3621H8.59925V0H7.40075ZM10.4869 26.8211H5.21348V22.5474H10.4869V26.8211ZM14.8015 10.2274V26.8211H11.6854V21.9579C11.6854 21.8016 11.6223 21.6516 11.5099 21.5411C11.3975 21.4305 11.2451 21.3684 11.0861 21.3684H4.61423C4.4553 21.3684 4.30288 21.4305 4.1905 21.5411C4.07812 21.6516 4.01498 21.8016 4.01498 21.9579V26.8211H1.1985V10.2274C1.1985 9.91469 1.32477 9.61482 1.54954 9.39373C1.7743 9.17263 2.07914 9.04842 2.397 9.04842H13.603C13.9209 9.04842 14.2257 9.17263 14.4505 9.39373C14.6752 9.61482 14.8015 9.91469 14.8015 10.2274ZM12.3745 6.13053V7.86947H3.62547V6.13053C3.62547 5.97419 3.6886 5.82425 3.80099 5.71371C3.91337 5.60316 4.06579 5.54105 4.22472 5.54105H11.7753C11.9342 5.54105 12.0866 5.60316 12.199 5.71371C12.3114 5.82425 12.3745 5.97419 12.3745 6.13053Z" fill="#A8A8CD" />
    <path d="M6.71261 11.4948H3.29688V12.6737H6.71261V11.4948Z" fill="#A8A8CD" />
    <path d="M12.7048 11.4948H9.28906V12.6737H12.7048V11.4948Z" fill="#A8A8CD" />
    <path d="M6.71261 14.4421H3.29688V15.6211H6.71261V14.4421Z" fill="#A8A8CD" />
    <path d="M12.7048 14.4421H9.28906V15.6211H12.7048V14.4421Z" fill="#A8A8CD" />
    <path d="M6.71261 17.3895H3.29688V18.5685H6.71261V17.3895Z" fill="#A8A8CD" />
    <path d="M12.7048 17.3895H9.28906V18.5685H12.7048V17.3895Z" fill="#A8A8CD" />
  </svg>
);

export default Building;
