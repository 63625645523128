import { FieldArray } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Trashcan } from '../../../assets/Trashcan';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { Input } from '../Input/Input';
import s from './DynamicCreateInputs.module.scss';

type ValuesT = {
  value: string;
};
type DynamicCreateInputsT = {
  values: ValuesT[];
  isEdit: boolean;
  changeValue: any;
};
export const DynamicCreateInputs = ({...props}: DynamicCreateInputsT) => {
  const dispatch = useDispatch();
  const {values, isEdit, changeValue} = props;

  const deleteDoor = (id: any) => {
    const newDoorValues = values;
    newDoorValues.splice(id, 1);
    changeValue.setValues({
      ...changeValue.values,
      doors_numbers: newDoorValues,
    });
  };

  const openModal = ({...data}) => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteDoorInput',
        stateModal: true,
        otherParams: {
          data,
          deleteFunc: deleteDoor,
        },
      }),
    );
  };

  return (
    <div className={`${s.door__wrapper} ${!isEdit && s.active}`}>
      <FieldArray name="doors_numbers">
        {() =>
          values.map((_doors_numbers: any, i: any): any => {
            return (
              <div key={`doors_numbers_${i}`} className={s.input_multi}>
                <Input
                  type="number"
                  placeholder="Enter Correct Door Number"
                  name={`doors_numbers.${i}.value`}
                  typeEdit={isEdit}
                />
                <div
                  onClick={() => openModal({id: i})}
                  className={`${s.delete} ${isEdit ? s.edit : null}`}
                >
                  <Trashcan />
                </div>
              </div>
            );
          })
        }
      </FieldArray>
    </div>
  );
};
