import {useField} from 'formik';
import {useEffect, useState} from 'react';
import {InputNotEdit} from '../InputNotEdit/InputNotEdit';
import s from './Input.module.scss';
import React from 'react';

export const TextArea = ({...props}: any) => {
  const {placeholder, hint, title, typeEdit} = props;
  const [field, meta] = useField(props);

  let containerClass = `${s.input}`;

  let error: any = '';
  let isError = !!error;

  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }

  if (isError) containerClass += ` ${s.error}`;

  const [isEdit, setIsEdit] = useState(typeEdit);

  useEffect(() => {
    const textarea = document.getElementById('textarea');
    if(textarea) {
      textarea.style.height = `${(textarea.scrollHeight)}px`;
    }
  }, [meta])

  return (
    <div className={s.wrap}>
      <div className={s.title}>{title}</div>

      {isEdit ? (
        <InputNotEdit
          value={meta.value}
          setIsEdit={() => setIsEdit(!isEdit)}
          isShowIcon={true}
        />
      ) : (
        <>
          <div className={`${containerClass} ${s.textarea}`}>
            <textarea
              id="textarea" 
              {...props}
              {...field}
              placeholder={placeholder}
            ></textarea>
          </div>
        </>
      )}

      <div className={`${s.hint} ${isError ? s.error : null}`}>
        {isError ? error : hint}
      </div>
    </div>
  );
};
