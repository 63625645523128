import React from 'react';

export const ArrowRight = ({...props}) => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45718 14.957L0.0429687 13.5428L5.83586 7.74992L0.0429682 1.95703L1.45718 0.542818L8.66429 7.74992L1.45718 14.957Z"
        fill="#17172E"
      />
    </svg>
  );
};
