import { TYPE_REDUCER } from "../../utils/constants";
import { authState, } from "../interface";



const initialState: authState = {
  access_token: null,
  refresh_token: null

};

const Auth = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPE_REDUCER.SAVE_SESSION_TOKEN:
      {
        return {
          ...state,
          access_token: action.payload.access,
          refresh_token: action.payload.refresh
        }
      }
    default:
      return state;
  }
};

export default Auth;
