import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteFacility, getFacilityId, updateFacility } from '../../../api';
import { AddNewFacilities } from '../../../components/Forms/AddNewFacilities/AddNewFacilities';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './GetFacilities.module.scss';

export const GetFacilities = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const axios = axiosInstance(history);
  const [isShowForm, setIsShowForm]: any = useState(false);

  const deleteFacilities = (values: any) => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteFacility',
        stateModal: true,
        otherParams: { id, deleteFunc: deleteFacility(axios) },
      }),
    );
  };
  const updateFacilities = (props: any) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const formData = new FormData();
    formData.append('site_id', props.site.value);
    formData.append('facility_title', props.facility_name);
    formData.append('facility_type_id', props.type.value);
    formData.append('facility_description', props.description);
    if (!props.photo[0].oldPhoto) {
      formData.append('facility_image', props.photo[0], props.photo[0].name);
    }
    formData.append('door_number', props.door_number);
    formData.append('price_per_hour', props.points);
    formData.append('capacity_from', props.capacity_from);
    formData.append('capacity_to', props.capacity_to);
    formData.append('open_from', '09:00:00');
    formData.append('open_to', '18:00:00');

    updateFacility(axios)({ formData, id })
      .then((res: any) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        console.log('e: ', e);
        new Error('Error create Facility');
      });
  };

  const initialValues: any = {
    facility_name: '',
    site: '',
    door_number: '',
    type: '',
    points: '',
    description: '',
    capacity_from: '',
    capacity_to: '',
    photo: '',
  };

  const [initValues, setInitValues] = useState(initialValues);

  useEffect(() => {
    getFacilityId(axios)({ id: id })
      .then((res: any) => {
        const facilityData = res.data;

        setInitValues({
          ...initValues,
          ...{
            facility_name: facilityData.facility_title,
            site: {
              item: facilityData['site'],
              value: facilityData['site']['id'],
              label: facilityData['site']['name'],
            },
            door_number: facilityData.door_number,
            type: {
              item: facilityData['facility_type'],
              label: facilityData['facility_type']['name'],
              value: facilityData['facility_type']['id'],
            },
            points: facilityData.price_per_hour,
            description: facilityData.facility_description,
            capacity_from: facilityData.capacity_from,
            capacity_to: facilityData.capacity_to,
            photo: [{ name: facilityData.facility_image, oldPhoto: true }],
          },
        });
        setIsShowForm(true);
      })
      .catch((...e: any) => {
        popUp({ props: e });
        console.error('facility not found');
      });
  }, []);

  return (
    <div>
      <div className={s.title}>Facility #{id}</div>
      <div className={s.boxForm}>
        {isShowForm && (
          <AddNewFacilities
            type="edit"
            initialValues={initValues}
            defaultValue={JSON.parse(JSON.stringify(initValues))}
            onDelete={deleteFacilities}
            onSubmit={(props: any) => updateFacilities(props)}
          />
        )}
      </div>
    </div>
  );
};
