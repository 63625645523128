import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const ModalDeleteSite = ({...props}: any) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackSitesPage = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteSite',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goSitePage = (otherParams: any) => {
    const {
      deleteFunc,
      values: {values},
    } = otherParams;

    deleteFunc({id: values.id}).then(async () => {
      popUp({
        props: 'The action was successful',
        type: 'success',
        isCustomMessage: true,
      });
      await dispatch(
        changeShowModal({
          nameModal: 'deleteSite',
          stateModal: false,
          otherParams: undefined,
        }),
      );
      await history.push(PrivateRoutes.sites.list);
    });
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to delete this Site?{' '}
      </div>
      <div className={s.description}>
        This Site will be deleted immediately. All information it includes will
        automatically be deleted (Members, Facilities, Promotions). You can not
        undo this action
      </div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onBackSitesPage()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete Site"
          type="submit"
          onClick={() => goSitePage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
