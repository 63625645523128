import { set } from "date-fns";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  findFacilitiesUserId,
  getFacilitiesBookDate,
  getFacilityId,
  getPromotionByFacilityId,
  saveFacilitiesT,
} from "../../../api";
import { changeShowModal } from "../../../store/actions/SideMenu";
import axiosInstance from "../../../utils/axios";
import { AsyncSelect } from "../../FormComponents/AsyncSelect/AsyncSelect";
import { loadingDataFacilitiesUserInfo } from "../../FormComponents/AsyncSelect/LoadingData/LoadingData";
import { Button } from "../../FormComponents/Button/Button";
import { DataPicker } from "../../FormComponents/DataPicker/DataPicker";
import { getTodayAtSpecificHour } from "../../FormComponents/TimePicker/dataFacilityBooking";
import { TimePicker } from "../../FormComponents/TimePicker/TimePicker";
import s from "./FacilitiesBook.module.scss";

type FacilitiesBookT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidHours = (hourValue: string) => {
  const now = new Date();
  const getTodayAtSpecificHour = (hour = 12, minutes = 0) =>
    set(now, { hours: hour, minutes: minutes, seconds: 0, milliseconds: 0 });

  const returnTimesArray = hourValue.split(":");
  const hour = Number(returnTimesArray[0]);
  const minutes = returnTimesArray[1] == "00" ? 0 : Number(returnTimesArray[1]);
  return {
    start: getTodayAtSpecificHour(hour, minutes),
    end: getTodayAtSpecificHour(hour + 1, minutes),
  };
};

const getValidationSchema: any = () => {
  return Yup.object({
    date: Yup.string().required("The field is required"),
    booked: Yup.object().required("Choose free hours"),
    user_id: Yup.object().required("The field is required"),
  });
};

export const FacilitiesBook = ({ ...props }: FacilitiesBookT) => {
  const { initialValues, onSubmit, infoPromotion } = props;
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const [startDate, setStartDate] = useState("");
  const [pointsInfo, setPointsInfo] = useState({
    hours: 0,
    price: 0,
    discount: false,
    title: "",
  });
  const [excludeTimes, setExcludeTimes] = useState([]);

  useEffect(() => {
    console.log('excludeTimes12223: ', excludeTimes);
  }, [excludeTimes])
  const timelineInterval = [
    getTodayAtSpecificHour(8),
    getTodayAtSpecificHour(19),
  ];
  const [firstLoadHour, setFirstLoadHour]: any = useState({
    info: timelineInterval,
    detail: {
      open_from: "08:00:00",
      open_to: "18:00:00",
    },
    selected: undefined,
  });

  function getActualFixedHour({
    info = firstLoadHour.info,
    detail = firstLoadHour.detail,
    selected = firstLoadHour.selected,
  }: any) {
    // str.split(' ');
    setFirstLoadHour({
      info,
      detail,
      selected,
    });
  }

  function loadActualPrice() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));

    function getPromotionInfo({
      promotionData,
      open_from,
      open_to,
      site_name,
      facility_title,
      standartPrice,
    }: any) {
      const offerArray: any[] = [];
      if (promotionData.length > 0) {
        promotionData.map((item: any) => {
          const date = moment(startDate).format("YYYY-MM-DD");
          let d1: any = new Date(date);
          let d2: any = new Date(item.promoted_to);
          let dm = d1 - d2;
          if (dm <= 0) {
            offerArray.push(item.offer);
          }
        });
      }

      const kitKatDetail = {
        open_from: open_from.split(":"),
        open_to: open_to.split(":"),
      };
      const detail = {
        open_from: `${kitKatDetail.open_from[0]}:00:00`,
        open_to: `${kitKatDetail.open_to[0]}:00:00`,
      };

      const timelineInterval = [
        getTodayAtSpecificHour(
          Number(kitKatDetail.open_from[0]),
          Number(kitKatDetail.open_from[1]),
          Number(kitKatDetail.open_from[2])
        ),
        getTodayAtSpecificHour(
          Number(kitKatDetail.open_to[0]),
          Number(kitKatDetail.open_to[1]),
          Number(kitKatDetail.open_to[2])
        ),
      ];

      getActualFixedHour({
        info: timelineInterval,
        detail: detail,
        selected: [
          getTodayAtSpecificHour(Number(kitKatDetail.open_from[0])),
          getTodayAtSpecificHour(Number(kitKatDetail.open_from[0]) + 1),
        ],
      });

      const result =
        offerArray.length > 0 ? Math.min(...offerArray) : standartPrice;
      if (result) {
        setPointsInfo({
          ...pointsInfo,
          price: result,
          discount: offerArray.length > 0 ? true : false,
          title: `${site_name} (${facility_title})`,
        });
      }
    }
    const p1 = getFacilityId(axios)({ id: id }).then((res: any) => {
      const facilityData = res.data;

      return facilityData;
    });

    const p2 = getPromotionByFacilityId(axios)({
      params: `no_page&facility__id=${id}`,
    }).then((res: any) => {
      const promotionData = res.data;
      return promotionData;
    });

    Promise.all([p1, p2]).then((values) => {
      const facilityData = values[0];
      const promotionData = values[1];

      if (promotionData > 0) {
        getPromotionInfo({
          promotionData,
          open_from: promotionData[0].facility.open_from,
          open_to: promotionData[0].facility.open_to,
          site_name: promotionData[0].facility.site.name,
          facility_title: promotionData[0].facility.facility_title,
          standartPrice: facilityData.price_per_hour,
        });
      } else {
        getPromotionInfo({
          promotionData,
          open_from: facilityData.open_from,
          open_to: facilityData.open_to,
          site_name: facilityData.site.name,
          facility_title: facilityData.facility_title,
          standartPrice: facilityData.price_per_hour,
        });
      }

      // setPointsInfo({
      //   ...pointsInfo,
      //   price: facilityData.price_per_hour,
      //   title: `${facilityData.site.name} (${facilityData.facility_title})`,
      // });
      dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
    });
  }

  useEffect(() => {
    console.log("firstLoadHour: ", firstLoadHour);
  }, [firstLoadHour]);

  useEffect(() => {
    loadActualPrice();
  }, []);

  useEffect(() => {
    loadActualPrice();
  }, [startDate]);
  const getCurrentDate = (data: any) => {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const date = moment(data).format("YYYY-MM-DD");
    getFacilitiesBookDate(axios)({ date, id })
      .then(async (res: any) => {
        const userData = await res.data;
        const excludeTimesArr: any = [];
        const newDate = new Date();
        const currentDate = moment(newDate).format("YYYY-MM-DD");
        const calendarNewDate = new Date(date);
        const currentNewDate = new Date(currentDate);

        if (+calendarNewDate == +currentNewDate) {
          // excludeTimesArr.push(getValidHours('08:00:00'));
          let min = Number(firstLoadHour.detail.open_from.split(":")[0]);
          let max = Number(firstLoadHour.detail.open_to.split(":")[0]);
          const hours = newDate.getHours() <= 17 ? newDate.getHours() : 17;
          for (let i = min; i <= hours; i++) {
            const hour = i <= max ? `0${i}` : i;
            excludeTimesArr.push(getValidHours(`${hour}:00:00`));
          }
        }

        // excludeTimesArr.push(getValidHours(firstLoadHour.detail.open_from));
        // excludeTimesArr.push(getValidHours(firstLoadHour.detail.open_to));

        // В Олега баг, тому потрібно тимчасово забрати букання 23:00 до 00:00
        excludeTimesArr.push(getValidHours("23:00:00"));

        userData.booked_hours.map((time: string) =>
          excludeTimesArr.push(getValidHours(time))
        );

        console.log('excludeTimesArr123: ', excludeTimesArr);
        setExcludeTimes(excludeTimesArr);
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        new Error("Error get Current Date");
      });
    setStartDate(data);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
      infoPromotion={infoPromotion}
    >
      {({ values, ...formikProps }) => {
        return (
          <Form>
            <div className={s.form}>
              <div className={s.title}>{pointsInfo.title}</div>
              <div className={s.InputBox}>
                <div className={s.title}>Date *</div>
                <DataPicker
                  placeholderText="Select Booking date"
                  name="date"
                  dateFormat="yyyy-M-dd"
                  selected={startDate}
                  minDate={new Date()}
                  onChangeCustom={getCurrentDate}
                />
              </div>
              <div className={s.time__wrapper}>
                {firstLoadHour.selected && (
                  <div className={s.InputBox}>
                    <TimePicker
                      title="Booking Period (From - To) *"
                      name="booked"
                      excludeTimes={excludeTimes}
                      firstLoadHour={firstLoadHour.info}
                      setTotalHour={setPointsInfo}
                      selectedIterval={firstLoadHour.selected}
                      TotalHour={pointsInfo}
                    />
                  </div>
                )}
              </div>
              {/* <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <div className={s.title}>Booking Period (From) *</div>
                  <DataPicker
                    placeholderText="Select booking Start time"
                    name="booked_from"
                    dateFormat="HH:00"
                    selected={startTime}
                    onChangeCustom={setStartTime}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    timeCaption={false}
                    timeIntervals={60}
                    timeFormat={'HH:mm'}
                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                    maxTime={
                      endTime == ''
                        ? setHours(setMinutes(new Date(), 0), 20)
                        : endTime
                    }
                    excludeTimes={excludeTimes}
                  />
                </div>
                <div className={s.InputBox}>
                  <div className={s.title}>Booking Period (To) *</div>
                  <DataPicker
                    placeholderText="Select booking End time"
                    name="booked_to"
                    dateFormat="HH:00"
                    onChangeCustom={setEndTime}
                    selected={endTime}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    timeCaption={false}
                    timeIntervals={60}
                    timeFormat={'HH:mm'}
                    minTime={
                      startTime == ''
                        ? setHours(setMinutes(new Date(), 0), 9)
                        : startTime
                    }
                    maxTime={setHours(setMinutes(new Date(), 0), 20)}
                    excludeTimes={excludeTimes}
                  />
                </div>
              </div> */}
              <div className={s.InputBox}>
                <div className={s.input_title}>User Name *</div>
                <AsyncSelect
                  noOptionsMessage={() => "No data found"}
                  loadOptions={loadingDataFacilitiesUserInfo(
                    findFacilitiesUserId(axios)
                  )}
                  loadingMessage={() => "loading ..."}
                  placeholder="Search by User Name, Email"
                  name="user_id"
                  title="User Name *"
                />
              </div>
              <div className={s.button__block}>
                <div className={s.points}>
                  Payment Due{" "}
                  <span>
                    {" "}
                    - {pointsInfo.price * pointsInfo.hours} W+S points
                  </span>
                  {pointsInfo.discount && (
                    <div className={s.discount}>discount activated</div>
                  )}
                </div>
                <div className={s.button}>
                  <Button
                    text="Book"
                    type="submit"
                    class_type={
                      Object.keys(formikProps.errors).length == 0 &&
                      formikProps.dirty
                        ? "main"
                        : "disabled"
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
