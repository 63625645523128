import { AxiosInstance } from "axios";

export type syncCreditMemoT = {
  sync_from: string;
  sync_to: string;
};

export const syncCreditMemo = (axios: AxiosInstance) => (props: syncCreditMemoT) => {
  return axios.post(`/utilities/force_sync_credit_memo/`, { ...props });
};

// export type PinT = {
//   pin: any;
// };

// export const genPin = (axios: AxiosInstance) => (props: PinT) => {
//   return axios.get(`/utilities/door_action/${props.pin}`);
// };



// export const deletePin = (axios: AxiosInstance) => (props: PinT) => {
//     return axios.delete(`/utilities/door_action/${props.pin}`);
//   };