import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { getUrlWithParams } from '../../../utils/helpersFunction';
import {
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/FacilitiesData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';
import s from './ListFacilities.module.scss';

export const ListFacilities = () => {
  const history = useHistory();
  const path = PrivateRoutes.facilities.book;

  const bookHandler = ({e, props}: any) => {
    console.log('props: ', props);
    e.stopPropagation();
    const id = props.row.id;
    const pathLink = getUrlWithParams(path, {id});
    history.push(pathLink);
  };

  const defaultContent: DefaultContentT = {
    title: 'Facilities',
    tableName: 'Facilities',
    tableAPI: '/facilities/',
    filterBySiteName: 'site__name',
    // customizeCell: {
    //   'Booked for today': {color: '#EC5D5D'},
    //   Error: {color: '#EC5D5D'},
    // },
    // customizeCoLumn: {
    //   status: {color: '#5FA784'},
    // },
    setButton: {
      column: 'price_per_hour',
      text: 'Book',
      style: s.book,
      clickEvent: bookHandler,
    },
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
