import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteAdvertising.module.scss';

type otherParamsT = {
  deleteFunc: any;
  id: string;
  refresh?: any;
};

export const ModalDeleteAdvertising = ({...props}) => {
  // Create an instance of Notyf
  const notyf = new Notyf();

  const {otherParams} = props;
  const dispatch = useDispatch();
  const history: any = useHistory();

  const onBackRental = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteAdvertising',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goMemberPage = (otherParams: any) => {
    const {deleteFunc, id, refresh}: otherParamsT = otherParams;

    
    deleteFunc({id: id})
      .then(async (res: {data: any}) => {
        await dispatch(
          changeShowModal({
            nameModal: 'deleteAdvertising',
            stateModal: false,
            otherParams: undefined,
          }),
        );
        if (refresh != undefined) {
          refresh();
        } else {
          history.push(PrivateRoutes.advertising.list);
        }
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
      })
      .catch(({...e}: any) => {
        const errors = e.response.data.error;
        if (errors) {
          errors.map((error: string) => notyf.error(error));
        }
        dispatch(
          changeShowModal({
            nameModal: 'deleteAdvertising',
            stateModal: false,
          }),
        );
      });
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to delete this Advertising?
      </div>
      <div className={s.description}>
        This Advertising will be deleted immediately. All information it
        includes will automatically be deleted (Picture, title, date). You can
        not undo this action
      </div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onBackRental()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete Advertising"
          type="submit"
          onClick={() => goMemberPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
