import { useField } from 'formik';
import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { InputNotEdit } from '../InputNotEdit/InputNotEdit';
import s from './AsyncSelect.module.scss';

const customStyles = (isError: boolean) => ({
  option: (provided: any, state: any) => {
    const {isSelected} = state;

    provided = {
      ...provided,
      padding: '20px',
      fontSize: '15px',
      lineHeight: '1.3',
      letterSpacing: '0.16px',
      color: '#17172E',
      backgroundColor: isSelected ? '#F7F7F7' : '#fff',
      '&:hover': {
        backgroundColor: !isSelected && '#F7F7F7',
        cursor: !isSelected && 'pointer',
      },
    };
    return {...provided};
  },
  control: (provided: any) => {
    provided = {
      ...provided,
      minHeight: '60px',
      outline: 'none',
      border: isError ? '2px solid #ec5d5d' : '2px solid #E8E8EF',
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '6px 0px',
      backgroundColor: isError ? '#fff7f7' : '#fff',
      // "&:hover": {
      //     border: '1px solid #9E00FF',
      // },
      '&:focus-within': {
        border: '1px solid #9E00FF',
      },
    };
    return {...provided};
  },
  multiValue: (provided: any) => {
    provided = {
      ...provided,
      background: '#F1F1F5',
      borderRadius: '5px',
      padding: '5px',
      fontSize: '16px',
      // maxWidth: '150px',

      '& div[role=button]:hover': {
        background: 'none',
        cursor: 'pointer',
      },
    };
    return {...provided};
  },
  indicatorSeparator: (provided: any) => {
    provided = {
      ...provided,
      backgroundColor: 'transparent',
    };
    return {...provided};
  },
});

export const AsyncSelect = ({...props}: any) => {
  const [field, meta, otherParams, , ...propsVa] = useField(props);
  const {typeEdit, title, checkOnHover = false} = props;
  const [inputValue, onInputChange] = useState(field?.value?.label);
  const [isEdit, setIsEdit] = useState(typeEdit);
  const defaultAdditional = {
    page_number: 1,
    search: inputValue,
  };

  let error = null;
  let isError = false;

  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }
  return (
    <div className={s.wrap}>
      {isEdit ? (
        <InputNotEdit
          value={title}
          setIsEdit={() => setIsEdit(!isEdit)}
          isShowIcon={true}
        />
      ) : (
        <>
          <AsyncPaginate
            styles={customStyles(isError)}
            additional={defaultAdditional}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onFocus={(e) => checkOnHover && checkOnHover(false)}
            onChange={(value: any) => {
              otherParams.setValue(value);
              onInputChange(value);
            }}
            {...props}
          />
          <div className={`${s.hint} ${isError ? s.error : null}`}>
            {isError && error}
          </div>
        </>
      )}
    </div>
  );
};
