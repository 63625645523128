import moment from "moment";
import { Notyf } from "notyf";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  createRentalDetails,
  deletePin,
  deleteRentalDetails,
  getRentalDetails,
  updateRentalDetails,
} from "../../../../../../api";
import { roleData } from "../../../../../../components/Forms/MemberRentalForm/dataMembersForm";
import { MemberRentalForm } from "../../../../../../components/Forms/MemberRentalForm/MemberRentalForm";
import { changeShowModal } from "../../../../../../store/actions/SideMenu";
import axiosInstance from "../../../../../../utils/axios";
import { PrivateRoutes } from "../../../../../../utils/constants/routes";
import {
  getUrlWithParams,
  popUp,
} from "../../../../../../utils/helpersFunction";
import s from "./ChangeMemberRental.module.scss";

export type doors_numbersT = {
  value: string;
  [key: string]: any;
};

export type InitValuesFormikT = {
  site_id: any;
  role: any;
  unit_id: string | number;
  doors_numbers: doors_numbersT[];
  leased_from: string;
  leased_to: string;
  details: string;
  [key: string]: any;
};

type formDataT = {
  id: number;
  isActiveRentals: boolean;
  initValues: InitValuesFormikT;
};

const getStringDoorFormat = (values: any) => {
  const doors_numbers: any = [];
  values.map((item: { value: any }) => {
    doors_numbers.push(item.value);
  });
  return doors_numbers.join();
};

export const getObjectDoorFormat = (values: string) => {
  const doors_numbersArray: any = [];

  values.split(",").map((item: any, index: any) => {
    doors_numbersArray.push({ value: item });
  });

  return doors_numbersArray;
};

export const ChangeMemberRental = ({ ...props }: any) => {
  const { type } = props;

  const defaultInitValues: InitValuesFormikT = {
    site_id: "",
    role: "",
    unit_id: "",
    doors_numbers: [{ value: "" }],
    leased_from: "",
    leased_to: "",
    details: "",
  };
  const [isShowForm, setIsShowForm]: any = useState({ show: false, type: "" });
  const { params }: any = useRouteMatch();
  const pathName = getUrlWithParams(
    PrivateRoutes.members.tabComponents?.rental,
    { id: params.id }
  );
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();

  const notyf = new Notyf({
    duration: 5000,
  });
  const [rentalForms, setRentalForms]: any = useState([
    {
      id: Number(params.id),
      isActiveRentals: false,
      initValues: defaultInitValues,
    },
  ]);

  useEffect(() => {
    if (type == "edit") {
      loadRentalForm("noEdit");
    } else {
      setIsShowForm({
        show: true,
        type: type,
      });
    }
  }, []);

  const loadRentalForm = (editType: string) => {
    setIsShowForm({
      show: false,
      type: "NoEdit",
    });
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    getRentalDetails(axios)({ id: Number(params.id) })
      .then(async (res: any) => {
        const userData = await res.data;
        const formData: any = [];

        if (userData.results.length > 0) {
          userData.results.map((itemForm: any) => {
            const doorObj = getObjectDoorFormat(itemForm.doors_numbers);
            formData.push({
              id: itemForm.id,
              isActiveRentals: false,
              initValues: {
                ...defaultInitValues,
                ...{
                  site_id: {
                    label: itemForm["unit"]["site"]["name"],
                    value: itemForm["unit"]["site"]["id"],
                  },
                  role: {
                    label: roleData[itemForm["user_role"]],
                    value: itemForm["user_role"],
                  },
                  unit_id: itemForm["unit"]["unit_id"],
                  doors_numbers: doorObj,
                  leased_from: new Date(
                    moment(itemForm["leased_from"]).toDate()
                  ),
                  leased_to: new Date(moment(itemForm["leased_to"]).toDate()),
                },
              },
            });
            setRentalForms(formData);
            setIsShowForm({
              show: true,
              type: type,
            });
          });
        } else {
          setRentalForms([
            {
              id: Number(params.id),
              isActiveRentals: true,
              initValues: defaultInitValues,
            },
          ]);
          setIsShowForm({
            show: true,
            type: editType,
          });
        }
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error get rental details");
      });
  };

  const getResults = (
    values: any,
    id: string,
    type: string,
    reload: boolean = true
  ) => {
    const results = values;

    results.doors_numbers = getStringDoorFormat(values.doors_numbers);
    // ${item.unit.unit_id},${item.unit.site.code},${item.leased_from},${item.leased_to}
    results.user_id = id;
    results.user_role = values.role.value;

    if (results.user_role == "SU" && type != "edit") {
      results.unit_id = results.details.item.unit.unit_id;
      results.site_id = results.details.item.unit.site.id;
      results.leased_from = moment(results.details.item["leased_from"]).format(
        "YYYY-M-DD"
      );
      results.leased_to = moment(results.details.item["leased_to"]).format(
        "YYYY-M-DD"
      );
    } else {
      results.site_id = values.site_id.value;
      results.leased_from = moment(values["leased_from"]).format("YYYY-M-DD");
      results.leased_to = moment(values["leased_to"]).format("YYYY-M-DD");
    }
    if (reload) {
      loadRentalForm("edit");
    }
    return results;
  };

  const onDeleteMemberRental = ({ ...values }: any) => {
    const otherValues = {
      ...values,
      refresh: () => loadRentalForm("noType"),
    };
    dispatch(
      changeShowModal({
        nameModal: "deleteRentalDetails",
        stateModal: true,
        otherParams: { otherValues, deleteFunc: deleteRentalDetails(axios) },
      })
    );
  };

  function sendRequestForDeactivationPin(pin: string) {
    const arrayOfPinCodes = pin.split(",");
    arrayOfPinCodes.map((pin: any, index: number) => {
      const dataTools = {
        pin,
      };
      deletePin(axios)(dataTools)
        .then(async (res) => {
          const siteData = await res.data;
          console.log("siteData: ", siteData);
        })
        .catch((...e: any) => {
          // popUp({ props: e });
          new Error("Error save Pin");
        });
    });
  }

  const onSubmitMemberRental = ({ ...data }: any) => {
    const { values } = data;
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const dataResult = getResults(values, params.id, isShowForm.type);

    createRentalDetails(axios)(dataResult)
      .then(async (res: { data: any }) => {
        const userData = await res.data;
        sendRequestForDeactivationPin(dataResult.doors_numbers);
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        history.push(pathName);
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error submit Users rental ");
      });
  };

  const onUpdateMemberRental = ({ ...data }: any) => {
    const { values, id } = data;
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const dataResult = getResults(values, params.id, isShowForm.type, false);

    updateRentalDetails(axios)(dataResult, id)
      .then(async (res: { data: any }) => {
        popUp({
          props: "The action was successful",
          type: "success",
          isCustomMessage: true,
        });
        const userData = await res.data;

        if (values.needDeactivatedPin) {
          sendRequestForDeactivationPin(dataResult.doors_numbers);
        }

        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        loadRentalForm("edit");
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error updating Users rental ");
      });
  };

  return (
    <>
      {" "}
      {isShowForm.show && (
        <div className={s.container}>
          {rentalForms.map((formData: formDataT, index: number) => {
            return (
              <div key={`${formData.id}-${index}`} className={s.boxForm}>
                <MemberRentalForm
                  type={isShowForm.type}
                  rentalId={formData.id}
                  customTitle="Please, fill the form below to add new rental details"
                  customBtn="Save Rental Details"
                  isOldMember={true}
                  isActiveRentals={formData.isActiveRentals}
                  initValues={formData.initValues}
                  defaultValue={JSON.parse(JSON.stringify(formData.initValues))}
                  onDelete={onDeleteMemberRental}
                  onSubmit={
                    isShowForm.type == "edit"
                      ? onUpdateMemberRental
                      : onSubmitMemberRental
                  }
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
