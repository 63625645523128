import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addFacility } from '../../../api';
import { AddNewFacilities } from '../../../components/Forms/AddNewFacilities/AddNewFacilities';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './NewFacilities.module.scss';

export const NewFacilities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = axiosInstance(history);

  const initialValues = {
    facility_name: '',
    site: '',
    door_number: '',
    type: '',
    points: '',
    description: '',
    capacity_from: '',
    capacity_to: '',
    photo: '',
  };

  const createFacility = (props: any) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    const formData = new FormData();
    formData.append('site_id', props.site.value);
    formData.append('facility_title', props.facility_name);
    formData.append('facility_type_id', props.type.value);
    formData.append('facility_description', props.description);
    formData.append('facility_image', props.photo[0], props.photo[0].name);
    formData.append('door_number', props.door_number);
    formData.append('price_per_hour', props.points);
    formData.append('capacity_from', props.capacity_from);
    formData.append('capacity_to', props.capacity_to);
    formData.append('open_from', '09:00:00');
    formData.append('open_to', '18:00:00');

    addFacility(axios)(formData)
      .then((res: any) => {
        dispatch(
          changeShowModal({
            nameModal: 'addNewFacility',
            stateModal: true,
            otherParams: res.data,
          }),
        );
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error create Facility');
      });
  };

  return (
    <div>
      <div className={s.title}>Add New Facility</div>
      <div className={s.boxForm}>
        <AddNewFacilities
          initialValues={initialValues}
          onSubmit={(props: any) => createFacility(props)}
        />
      </div>
    </div>
  );
};
