type roleT = {
    value: string,
    label: string,
}
type OptionsT = {
    options: roleT[],
    [key: string]: any
}
type roleDataT = {
    TE: string;
    SU: string;
    [key: string]: any
}
export const roleData: roleDataT = {
    TE: 'User',
    SU: 'Subuser',
}
export const roleOptions = (): OptionsT => ({
    options: [
        {
            value: 'TE',
            label: 'User',
        },
        {
            value: 'SU',
            label: 'Subuser',
        },
    ],
});

