import { FacilitiesT } from '../../store/interface';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  { columnName: 'id', align: 'left' },
  { columnName: 'site__name', align: 'left' },
  { columnName: 'facility_title', align: 'left' },
  { columnName: 'facility_type', align: 'left' },
  { columnName: 'capacity_to', align: 'right' },
  { columnName: 'price_per_hour', align: 'right' },
  { columnName: '', width: 150, align: 'right' },
];

export const dataRows: DataRowsT[] = [
  {
    name: 'id',
    title: 'FACILITY ID',
    getCellValue: (row: FacilitiesT) => row.id,
  },
  {
    name: 'site__name',
    title: 'SITE',
    getCellValue: (row: FacilitiesT) => row.site.name,
  },
  {
    name: 'facility_title',
    title: 'FACILITY NAME',
    getCellValue: (row: FacilitiesT) => row.facility_title,
  },
  {
    name: 'facility_type',
    title: 'TYPE',
    getCellValue: (row: FacilitiesT) => row.facility_type.type,
  },
  {
    name: 'capacity_to',
    title: 'CAPACITY',
    getCellValue: (row: FacilitiesT) =>
      `${row.capacity_from}-${row.capacity_to}`,
  },
  {
    name: 'price_per_hour',
    title: 'POINTS / HOUR',
    getCellValue: (row: FacilitiesT) => row.price_per_hour,
  },
  {
    name: '',
    title: '',
    getCellValue: (row: FacilitiesT) => 'button',
  },
];

export const dataSorting: DataSortingT[] = [
  { columnName: 'id', direction: 'asc' },
];
