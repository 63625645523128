import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    getMember,
    memberResetPassword,
    switchMemberIsActive,
    updateMember,
    updateMemberT
} from '../../../../../api';
import AddMemberForm from '../../../../../components/Forms/AddMember/AddMemberForm';
import { changeShowModal } from '../../../../../store/actions/SideMenu';
import axiosInstance from '../../../../../utils/axios';
import { popUp } from '../../../../../utils/helpersFunction';
import s from './General.module.scss';

export const General = () => {
  const defaultInitValues = {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    salesforce_id: '',
    company_name: '',
    phone_number: '',
    correspondence_address: '',
    can_see_facilities_from_other_sites: false,
  };
  const history = useHistory();
  const axios = axiosInstance(history);
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();

  const [infoMember, setInfoMember] = useState();
  const [initValues, setInitValues] = useState(defaultInitValues);

  useEffect(() => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    getMember(axios)({ id: id })
      .then(async (res: any) => {
        const userData = await res.data;

        setInfoMember(userData);
        setInitValues({
          ...initValues,
          ...{
            id: userData['id'],
            email: userData['email'],
            first_name: userData['first_name'],
            last_name: userData['last_name'],
            salesforce_id: userData['salesforce_id'],
            company_name: userData['company_name'],
            phone_number: userData['phone_number'],
            correspondence_address: userData['correspondence_address'],
            can_see_facilities_from_other_sites:
              userData['can_see_facilities_from_other_sites'],
          },
        });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        console.error('User not found');
      });
  }, []);

  const onDeactivateMember = ({ ...values }: any) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    switchMemberIsActive(axios)(values)
      .then(async (res) => {
        const userData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error updating Users info ');
      });
  };

  const onUpdateMember = ({ ...values }: updateMemberT, id: string) => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    updateMember(axios)(values, id)
      .then(async (res) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        const userData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error updating Users info ');
      });
  };

  const onResetPassword = ({...values}: any) => {
    // console.log(values)
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    memberResetPassword(axios)(values)
      .then(async (res) => {
        const responce = await res.statusText;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        dispatch(
          changeShowModal({
            nameModal: 'memberResetPassword',
            stateModal: true,
            otherParams: { id: id },
          }),
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error updating Users info ');
      });
  };


  return (
    <>
      {' '}
      {!!initValues['id'] && (
        <div>
          <div className={s.boxForm}>
            <AddMemberForm
              type="edit"
              infoMember={infoMember}
              initValues={initValues}
              defaultValue={JSON.parse(JSON.stringify(initValues))}
              onDeactivate={onDeactivateMember}
              onUpdate={onUpdateMember}
              onReset={onResetPassword}
              onSubmit={(props: any) => {
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
