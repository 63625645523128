import {MouseEventHandler} from 'react';
import s from './InnerSideCell.module.scss';
import React from 'react';
import {Link} from 'react-router-dom';

type InnerSideCellT = {
  label: string;
  onClick: MouseEventHandler;
  isActive: boolean;
  link: string | any;
};

export const InnerSideCell = ({...props}: InnerSideCellT) => {
  const {label, onClick, isActive, link} = props;

  let containerClass = `${s.inner_side_cell}`;
  if (isActive) containerClass += ` ${s.active}`;

  return (
    <Link to={link}>
      <div className={containerClass} onClick={onClick}>
        {label}
      </div>
    </Link>
  );
};

export default InnerSideCell;
