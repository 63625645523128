import React from 'react'

export const CloseSVG = ({ ...props }) => {
    return (
        <svg {...props} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.59296 7.5L14.7735 1.31944C15.0755 1.01773 15.0755 0.528187 14.7735 0.226476C14.4716 -0.0754921 13.9825 -0.0754921 13.6806 0.226476L7.5 6.40704L1.31944 0.226476C1.01773 -0.0754921 0.528187 -0.0754921 0.226476 0.226476C-0.0754921 0.528187 -0.0754921 1.01773 0.226476 1.31944L6.40704 7.5L0.226476 13.6806C-0.0754921 13.9823 -0.0754921 14.4718 0.226476 14.7735C0.377203 14.9245 0.575338 15 0.772957 15C0.970576 15 1.16845 14.9245 1.31944 14.7735L7.5 8.59296L13.6806 14.7735C13.8315 14.9245 14.0294 15 14.227 15C14.4247 15 14.6225 14.9245 14.7735 14.7735C15.0755 14.4718 15.0755 13.9823 14.7735 13.6806L8.59296 7.5Z" fill="#A8A8CD" />
        </svg>

    )
}
