import React from 'react';

export const SvgError = ({...props}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 0C7.15675 0 0 7.15606 0 16C0 24.8433 7.15606 32 16 32C24.8433 32 32 24.8439 32 16C32 7.15669 24.8439 0 16 0ZM16 29.5C8.5385 29.5 2.5 23.4621 2.5 16C2.5 8.53844 8.53794 2.5 16 2.5C23.4615 2.5 29.5 8.53794 29.5 16C29.5 23.4616 23.4621 29.5 16 29.5Z"
        fill="#F7F7F7"
      />
      <path
        d="M21.4707 19.7068L17.7643 16.0004L21.4707 12.294C21.9588 11.8059 21.9589 11.0145 21.4707 10.5263C20.9825 10.0381 20.191 10.0382 19.703 10.5263L15.9965 14.2327L12.2901 10.5263C11.802 10.0381 11.0105 10.0381 10.5224 10.5263C10.0342 11.0145 10.0342 11.8059 10.5224 12.294L14.2288 16.0004L10.5224 19.7068C10.0342 20.195 10.0342 20.9864 10.5224 21.4745C11.0107 21.9628 11.8021 21.9626 12.2901 21.4745L15.9965 17.7682L19.703 21.4745C20.191 21.9627 20.9825 21.9627 21.4707 21.4745C21.9589 20.9864 21.9589 20.1949 21.4707 19.7068Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
