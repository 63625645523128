import React from 'react';
import s from './ModalLoader.module.scss';

export const ModalLoader = () => {
    return (
        <div className={s.blurBackground}>
            <div className={s.modalBox}>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id={s.long} d="M44.4886 16.2584C44.9341 16.2584 45.3238 16.147 45.7693 15.9243C46.9942 15.2005 47.384 13.6971 46.6601 12.5278C45.9363 11.3029 44.433 10.9131 43.2637 11.637C42.0388 12.3051 41.649 13.8641 42.3728 15.0891C42.7626 15.8129 43.5978 16.2584 44.4886 16.2584Z" fill="#7A7A96" />
                    <path id={s.dot1} d="M34.9667 7.68372C35.3565 7.90644 35.8019 8.0178 36.2473 8.0178C37.0825 8.0178 37.9734 7.57237 38.4188 6.73718C39.087 5.56791 38.6972 4.00889 37.528 3.34074C36.303 2.67259 34.7997 3.06234 34.0759 4.23161C33.4077 5.45655 33.7975 6.95989 34.9667 7.68372Z" fill="#7A7A96" />
                    <path id={s.dot2} d="M25 50C38.8085 50 50 38.8085 50 25C50 23.608 48.8864 22.4944 47.4944 22.4944C46.1025 22.4944 44.9889 23.608 44.9889 25C44.9889 36.0245 36.0245 44.9889 25 44.9889C13.9755 44.9889 5.01114 36.0245 5.01114 25C5.01114 13.9755 13.9755 5.01114 25 5.01114C26.392 5.01114 27.5056 3.89755 27.5056 2.50557C27.5056 1.11359 26.392 0 25 0C11.1915 0 0 11.1915 0 25C0 38.8085 11.1915 50 25 50Z" fill="#7A7A96" />
                </svg>
            </div>
        </div>
    )
}
