type toolsT = {
    value: boolean,
    label: string,
}
type toolsOptionsT = {
    options: toolsT[],
    [key: string]: any
}
type toolsDataT = {
    enable: boolean;
    disable: boolean;
    [key: string]: any
}
export const toolsData: toolsDataT = {
    enable: true,
    disable: false,
}
export const toolsOptions = (): toolsOptionsT => ({
    options: [
        {
            value: true,
            label: 'Enable',
        },
        {
            value: false,
            label: 'Disable',
        },
    ],
});