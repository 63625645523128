import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {TableComponent} from '../../../../../components/TableComponent/TableComponent';
import {
  dataRows,
  dataSorting,
  headerColumn,
} from '../../../../../utils/tablesData/CreditMemoData';
import {DefaultContentT} from '../../../../../utils/tablesData/tablesType';
import s from "./CreditMemo.module.scss"

export const CreditMemo = () => {
  const {params}: any = useRouteMatch();
  const defaultContent: DefaultContentT = {
    rowClickable: false,
    sortingBySite: false,
    customizeCell: {
      Unpaid: {color: '#EC5D5D'},
      Paid: {color: '#5FA784'},
      Processing: {color: '#eac64a'},
    },
    tableName: 'PaymentHistoryData',
    tableAPI: '/units/rental/credit_memo/',
    customParams: `&customer_number__user__id=${params.id}`,
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <div>
      <TableComponent data={defaultContent} />
    </div>
  );
};
