import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import Image from '../../../assets/Image';
import { Trashcan } from '../../../assets/Trashcan';
import s from './InputFile.module.scss';

const addPhoto = ({event, setValue, name, setActiveFile, setSrcImage}: any) => {
  const files = event.target.files;
  const myFiles = Array.from(files);
  setValue(name, myFiles);
  setActiveFile(true);
  // FileReader support

  if (FileReader && files && files.length) {
    const fr = new FileReader();
    fr.onload = function () {
      const image = fr.result;
      setSrcImage(image);
    };
    fr.readAsDataURL(files[0]);
  } // Not supported
  else {
    // fallback -- perhaps submit the input to an iframe and temporarily store
    // them on the server until the user's session ends.
  }
};

const deletePhoto = ({setValue, setActiveFile}: any) => {
  setValue('photo', []);
  setActiveFile(false);
};

type InputFileT = {
  hint?: string;
  title?: string;
  name: string;
  typeEdit: boolean;
  placeholder: string;
  setValue: any;
  typeFile?: 'image' | 'file';
  initialValues: any;
};
export const InputFile = ({...props}: InputFileT) => {
  const {
    hint,
    title,
    typeEdit,
    placeholder,
    setValue,
    typeFile,
    initialValues,
    name,
  } = props;
  const [field, meta]: any = useField(name);
  let containerClass = `${s.input_photo_area}`;
  let error = '';
  let isError = !!error;
  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }

  if (isError) containerClass += ` ${s.error}`;

  const [activeFile, setActiveFile] = useState(false);
  const [srcImage, setSrcImage] = useState('');

  useEffect(() => {
    if (typeEdit) {
      setSrcImage(initialValues[0].name);
    }

    field.value != '' && setActiveFile(true);
  }, []);

  return (
    <div className={s.input__wrap}>
      <div className={s.input_photo_box}>
        {title && <div className={s.input_title}>{title}</div>}
        {activeFile && <img className={s.image} src={srcImage} alt="image" />}
        <div
          className={`${containerClass} ${activeFile && s.hidden} ${
            typeFile == 'image' ? s.image : null
          }`}
        >
          <input
            type="file"
            {...props}
            id={s.input_photo}
            onChange={(e) =>
              addPhoto({
                event: e,
                setValue,
                name,
                setActiveFile,
                setSrcImage,
              })
            }
            draggable
            accept="image/png, image/jpeg"
          />
          <div className={s.input_file}>
            <div className={s.input_photo_text}>
              {placeholder}
              <br />
              OR
            </div>
            <label className={s.input_photo_label} htmlFor={s.input_photo}>
              Browse Files
            </label>
          </div>
          <div className={s.input_image}>
            <Image />
            <span>ADD Picture</span>
          </div>
        </div>
      </div>

      <div className={`${s.hint} ${isError ? s.error : null}`}>
        {isError ? error : hint}
      </div>
      {activeFile && (
        <div className={s.file_settings}>
          {/* <div
            onClick={() => deletePhoto({setValue, setActiveFile})}
            className={s.file_delete}
          >
            <Pencil />
            <span>Remove photo</span>
          </div> */}
          <label htmlFor={s.input_photo} className={s.file_change}>
            <Trashcan />
            <span>Change photo</span>
          </label>
        </div>
      )}
    </div>
  );
};
