import React, { useEffect, useRef } from 'react';
import { CloseSVG } from '../../../assets/Close';

import s from './FrameModals.module.scss';

export const FrameModals = ({ closeFunc, ...props }: any) => {
    const wrapperRef: any = useRef(null);
    useEffect(() => {
        const handleClickOutside = (wrapperRef: any, closeFunc: any) => (event: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeFunc();
            }
        }
        document.addEventListener("mousedown", handleClickOutside(wrapperRef, closeFunc));
        return () => {
            document.removeEventListener("mousedown", handleClickOutside(wrapperRef, closeFunc));
        };
    }, [wrapperRef]);



    return (
        <div className={`${s.wrap_modal}`}>
            <div ref={wrapperRef} className={`${s.modal_content}`}>
                <header className={`${s.header}`}><CloseSVG onClick={() => closeFunc()} /></header>
                <main className={`${s.main}`}>{props.children}</main>
            </div>

        </div>
    )
}
