import { useField } from 'formik';
import { useState } from 'react';
import { InputNotEdit } from '../InputNotEdit/InputNotEdit';
import s from './Input.module.scss';

export const Input = ({ ...props }) => {
  const { value, type, placeholder, hint, title, left_icon, right_icon, typeEdit, disableEdit = false } =
    props;

  const [field, meta] = useField(props);

  let containerClass = `${s.input}`;

  switch (type) {
    case 'text':
      break;
    case 'password':
      containerClass += ` ${s.password}`;
      break;
    default:
      break;
  }

  let error = '';
  let isError = !!error;

  if (meta.touched) {
    error = meta.error;
    isError = !!error;
  }

  if (isError) containerClass += ` ${s.error}`;

  const [isActive, setIsActive] = useState(false);

  const handleRightIconClick = () => {
    if (type === 'password') setIsActive(!isActive);
  };

  const handleTypeInNumberType = ({ e, type }) => {

    if (type == 'number') {
      return e.keyCode === 8 || e.charCode >= 48 && e.charCode <= 57
    }
    // return false
  };

  const [isEdit, setIsEdit] = useState(typeEdit);

  const clickHandler = () => {
    if (!disableEdit) {
      return setIsEdit(!isEdit)
    } else {
      return false
    }
  }
  return (
    <div className={s.wrap}>
      <div className={s.title}>{title}</div>

      {isEdit ? (
        <InputNotEdit
          value={meta.value ? meta.value : value}
          setIsEdit={clickHandler}
          isShowIcon={disableEdit ? false : true}
        />
      ) : (
        <>
          <div className={containerClass}>
            <div className={s.left_icon}>{left_icon}</div>
            <input
              {...props}
              {...field}
              value={value}
              onKeyPress={(e) => handleTypeInNumberType({ e, type })}
              type={isActive ? 'text' : type}
              placeholder={placeholder}
            />
            <div className={s.right_icon} onClick={handleRightIconClick}>
              {right_icon}
            </div>
          </div>
        </>
      )}
      <div className={`${s.hint} ${isError ? s.error : null}`}>
        {isError ? error : hint}
      </div>
    </div>
  );
};
