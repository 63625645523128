import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteFacility.module.scss';

export const ModalDeleteFacility = ({...props}) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackFacility = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteFacility',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goFacilityPage = (otherParams: any) => {
    const {deleteFunc, id} = otherParams;

    deleteFunc({id: id}).then(async () => {
      popUp({
        props: 'The action was successful',
        type: 'success',
        isCustomMessage: true,
      });
      await dispatch(
        changeShowModal({
          nameModal: 'deleteFacility',
          stateModal: false,
          otherParams: undefined,
        }),
      );
      await history.push(PrivateRoutes.promotions.list);
    });
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to delete Facility Page?{' '}
      </div>
      <div className={s.description}>
        This facility page will be deleted immediately. You can not undo this
        action
      </div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onBackFacility()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete Facility Page"
          type="submit"
          onClick={() => goFacilityPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
