import React from 'react';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import {
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/SitesData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';

export const ListSites = () => {
  const defaultContent: DefaultContentT = {
    title: 'Sites',
    tableName: 'Sites',
    tableAPI: '/sites/',
    sortingBySite: false,
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
