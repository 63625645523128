import React from 'react';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import {
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/FacilitiesPromotionData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';

export const FacilitiesPromotion = () => {
  const defaultContent: DefaultContentT = {
    title: 'Facilities Promotion',
    tableName: 'FacilitiesPromotion',
    tableAPI: '/facilities/promotions/',
    filterBySiteName: 'facility__site__name',
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
    customParams: '&show_all'
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
