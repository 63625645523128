import moment from 'moment';
import { Notyf } from 'notyf';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { addFacilitiesBook } from '../../../api';
import { FacilitiesBook } from '../../../components/Forms/FacilitiesBook/FacilitiesBook';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './BookFacilities.module.scss';

export const BookFacilities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const axios = axiosInstance(history);
  const notyf = new Notyf({
    duration: 5000,
  });
  const initValues = {
    date: '',
    booked: '',
    user_id: '',
  };
  const submitFacilityBook = (props: any) => {
    dispatch(changeShowModal({nameModal: 'loader', stateModal: true}));
    const infoPromotion: any = {
      date: moment(props.date).format('YYYY-M-DD'),
      booked_from: props.booked.start.split('-').join(':'),
      booked_to: props.booked.end.split('-').join(':'),
      user: props.user_id.item.id,
      facility_id: Number(params.id),
    };

    addFacilitiesBook(axios)({...infoPromotion})
      .then((res: any) => {
        dispatch(
          changeShowModal({
            nameModal: 'modalAddFacilityBook',
            stateModal: true,
            otherParams: res.data,
          }),
        );
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
      })
      .catch(({...e}: any) => {
        popUp({props: e});
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        new Error('Error Facilities Book');
      });
  };

  return (
    <div className={s.container}>
      <FacilitiesBook
        initialValues={initValues}
        onSubmit={(props: any) => submitFacilityBook(props)}
      />
    </div>
  );
};
