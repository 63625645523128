import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteFaq } from '../../../api';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import {
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/HelpData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';
import s from './ListHelp.module.scss';

export const HelpList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = axiosInstance(history);

  const handleClick = ({e, props, refresh}: any) => {
    e.stopPropagation();
    dispatch(
      changeShowModal({
        nameModal: 'deleteFaq',
        stateModal: true,
        otherParams: {props, deleteFunc: deleteFaq(axios), refresh},
      }),
    );
  };

  const defaultContent: DefaultContentT = {
    title: 'Help',
    tableName: 'Help',
    tableAPI: '/help/',
    sortingBySite: false,
    sortingParams: false,
    customStyle: s.table_help,
    setButton: {
      column: 'question',
      svg: true,
      text: 'Delete FAQ',
      style: s.delete,
      clickEvent: handleClick,
    },
    dataRows: dataRows,
    dataSorting: dataSorting,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
      <TableComponent data={defaultContent} />
    </>
  );
};
