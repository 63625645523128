import { combineReducers } from 'redux';
import Auth from './Auth';
import SideMenu from './SideMenu';

const rootReducer = combineReducers({
  auth: Auth,
  sideMenu: SideMenu,
});

export default rootReducer;
