import React from 'react';

export const ArrowLeft = ({...props}) => {
  return (
    <svg
    width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5428 0.542969L11.957 1.95718L6.16414 7.75008L11.957 13.543L10.5428 14.9572L3.33571 7.75008L10.5428 0.542969Z"
        fill="#17172E"
      />
    </svg>
  );
};
