import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../assets/Arrow';
import s from './SideCell.module.scss';

type SideCellT = {
  Icon: JSX.Element;
  link?: string | any;
  label: string;
  isActive: boolean;
  onClick: MouseEventHandler;
  children?: JSX.Element | JSX.Element[] | undefined;
};

export const SideCell = ({...props}: SideCellT) => {
  const {Icon, label, children, isActive, link, onClick} = props;

  // const [isActive, setIsActive] = useState(false);

  // function handleClick() {
  //   setIsActive(!isActive);
  // }

  let containerClass = `${s.side_cell_body}`;

  if (isActive) containerClass += ` ${s.active}`;
  return (
    <Link to={link}>
      <div className={containerClass} onClick={onClick}>
        <div className={s.iconContainer}>{Icon}</div>
        <div className={s.label}>{label}</div>
        {children && (
          <div className={s.arrow}>
            <Arrow />
          </div>
        )}
      </div>
      {isActive && children}
    </Link>
  );
};

export default SideCell;
