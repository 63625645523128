import { Form, Formik } from "formik";
import moment from "moment";
import { equals } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import * as Yup from "yup";
import {
  getRentalTenantInfo,
  getRentalUnitOption,
  getRentalDetails,
} from "../../../api";
import { Trashcan } from "../../../assets/Trashcan";
import { InitValuesFormikT } from "../../../scenes/Members/GetMember/TabComponents/Rental/ChangeMemberRental/ChangeMemberRental";
import { getHeaderInfo } from "../../../store/selectors/selectors";
import axiosInstance from "../../../utils/axios";
import { AsyncSelect } from "../../FormComponents/AsyncSelect/AsyncSelect";
import { loadRentalOptions } from "../../FormComponents/AsyncSelect/LoadingData/LoadingData";
import { Button } from "../../FormComponents/Button/Button";
import { DataPicker } from "../../FormComponents/DataPicker/DataPicker";
import { DynamicCreateInputs } from "../../FormComponents/DynamicCreateInputs/DynamicCreateInputs";
import { Input } from "../../FormComponents/Input/Input";
import { getObjectDoorFormat } from "./../../../scenes/Members/GetMember/TabComponents/Rental/ChangeMemberRental/ChangeMemberRental";
import { roleOptions } from "./dataMembersForm";
import s from "./MemberRentalForm.module.scss";

type AddMemberFromT = {
  initValues: InitValuesFormikT;
  onSubmit: any;
  type?: string;
  customIsActiveSubmit?: boolean;
  onRegisterUsers?: any;
  isOldMember?: boolean;
  [key: string]: any;
};

const addDoors = (values: InitValuesFormikT) => (setValues: any) => {
  const doors_numbers = [...values.doors_numbers];
  doors_numbers.push({ value: "" });
  setValues({ ...values, doors_numbers });
};

const depDep = {
  TE: ["unit_id", "leased_from", "leased_to", "doors_numbers", "site_id"],
  SU: ["doors_numbers", "details"],
  prohibition: ["details"],
};
const getViewInput =
  (depDep: any, isEdit: boolean) =>
  ({ role, nameInput }: any) => {
    let isCurrentInput;
    const prohibition = depDep.prohibition.includes(nameInput);

    if (role == undefined) {
      isCurrentInput = false;
    } else {
      const isViewAllInput: any = depDep[role];
      isCurrentInput = isViewAllInput.includes(nameInput);
    }

    const checkIsEdit = isEdit ? true : isCurrentInput;
    isCurrentInput = isEdit && prohibition ? false : checkIsEdit;

    return isCurrentInput;
  };

export const MemberRentalForm = ({ ...props }: AddMemberFromT) => {
  const {
    initValues,
    defaultValue,
    onSubmit,
    onDelete,
    type,
    rentalId,
    isActiveRentals,
    customTitle,
    customBtn,
    onRegisterUsers,
    isOldMember = false,
    customIsActiveSubmit = true,
  } = props;

  const { siteData } = useSelector(getHeaderInfo);
  const { params }: any = useRouteMatch();
  const history = useHistory();
  const axios = axiosInstance(history);

  const initialValueForm = { ...initValues };
  const isEdit = type === "edit";
  const isAddMemberPage = !!onRegisterUsers;
  const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
  const [dateWrapperActive, setDateWrapperActive] = useState(false);
  const [valueForm, setValueForm] = useState(initialValueForm);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxDate, setMaxDate]: any = useState(undefined);
  const getViewInputForm = getViewInput(depDep, isEdit);

  let validationSchema;

  // eslint-disable-next-line prefer-const
  validationSchema = Yup.object({
    site_id: Yup.object().when("role", (role, schema): any => {
      if (role && !!role.value) {
        if (isEdit || role.value == "TE") {
          return Yup.object().required("Site is a required field");
        } else {
          return Yup.object();
        }
      }
    }),
    role: Yup.object().required("Role is a required"),
    unit_id: Yup.string().when("role", (role, schema): any => {
      if (role && !!role.value) {
        if (isEdit || role.value == "TE") {
          return Yup.string().required("Unit ID is a required field");
        } else {
          return Yup.string();
        }
      }
    }),
    leased_from: Yup.string().when("role", (role, schema): any => {
      if (role && !!role.value) {
        if (isEdit || role.value == "TE") {
          return Yup.string()
            .nullable()
            .required("Lease Period (From) is a required field");
        } else {
          return Yup.string().nullable();
        }
      }
    }),
    leased_to: Yup.string().when("role", (role, schema): any => {
      if (role && !!role.value) {
        if (isEdit || role.value == "TE") {
          return Yup.string()
            .nullable()
            .required("Lease Period (To) is a required field");
        } else {
          return Yup.string().nullable();
        }
      }
    }),
    // doors_numbers: Yup.array().of(
    //   Yup.object().shape({
    //     value: Yup.string().required('The field is required'),
    //   }),
    // ),
    details: Yup.object().when("role", (role, schema): any => {
      if (role && !!role.value) {
        if (!isEdit && !!role && role.value != "TE") {
          return Yup.object().required("details is a required field");
        } else {
          return Yup.object();
        }
      }
    }),
  });

  useEffect(() => {
    setStartDate(initValues["leased_from"]);
    setEndDate(initValues["leased_to"]);

    const role = initValues.role.value;
    const unitId: any = initValues.unit_id;
    if (role === "SU" && isEdit) {
      getRentalTenantInfo(axios)({ id: unitId }).then(async (res: any) => {
        const userData = await res.data;
        if (userData.results.length == 1) {
          const leasedTo = userData.results[0].leased_to.split("-");
          console.log("leasedTo: ", leasedTo);
          let year: number = Number(leasedTo[0]);
          let month: number = Number(leasedTo[1]);
          let day: number = Number(leasedTo[2]);

          if (month === 1) {
            month = 12;
            year--;
          } else {
            month--;
          }

          console.log("year: ", year);
          console.log("month: ", month);
          console.log("day: ", day);
          setMaxDate(new Date(year, month, day));
        }
      });
    }
  }, [initValues]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValueForm}
      validationSchema={validationSchema}
      onSubmit={(e, ...prop) => {
        let allValues = e;
        const currentRole = e.role;
        if (!isEdit) {
          prop[0].resetForm({});
          const value = {
            ...defaultValue,
            doors_numbers: [{ value: "" }],
            role: currentRole,
          };

          setStartDate("");
          setEndDate("");
          prop[0].setValues(value);
          setValueForm(value);
          prop[0].setStatus({ success: true });
        } else {
          let leas_from_old = moment(allValues["leased_from"]).format(
            "YYYY-M-DD"
          );
          let leas_to_old = moment(allValues["leased_to"]).format("YYYY-M-DD");

          let doorOld: any = "";

          allValues.doors_numbers.map((item: any, index: number) => {
            doorOld += `${item.value},`;
          });
          doorOld = doorOld.substring(0, doorOld.length - 1);

          let leas_from_actual = moment(initValues["leased_from"]).format(
            "YYYY-M-DD"
          );
          let leas_to_actual = moment(initValues["leased_to"]).format(
            "YYYY-M-DD"
          );
          let doorActual: any = "";

          initValues.doors_numbers.map((item: any, index: number) => {
            doorActual += `${item.value},`;
          });
          doorActual = doorActual.substring(0, doorActual.length - 1);

          if (
            leas_from_old !== leas_from_actual ||
            leas_to_old !== leas_to_actual ||
            doorOld !== doorActual
          ) {
            allValues["needDeactivatedPin"] = true;
          } else {
            allValues["needDeactivatedPin"] = false;
          }
        }

        return onSubmit({ values: allValues, id: rentalId });
      }}
    >
      {({ values, ...props }) => {
        if (isEdit) {
          const defaultTest = {
            ...defaultValue,
            leased_from: new Date(moment(defaultValue?.leased_from).toDate()),
            leased_to: new Date(moment(defaultValue?.leased_to).toDate()),
          };

          const similarObjects: boolean = equals(values, defaultTest);

          if (!similarObjects && !isSaveChangeParams)
            setIsSaveChangeParams(true);
          if (similarObjects && isSaveChangeParams)
            setIsSaveChangeParams(false);
        }

        setValueForm(values);
        return (
          <Form>
            {isActiveRentals && (
              <div className={s.description}>
                <div className={s.description_title}>
                  This user does not have an active unit rentals yet
                </div>
                <div className={s.description_subtitle}>
                  Please, fill the form below to add new rental details
                </div>
              </div>
            )}
            {!isActiveRentals && !isEdit && (
              <div className={s.description_title}>{customTitle}</div>
            )}

            <div className={s.form}>
              <div className={`${s.lineParams} ${s.lineParams_role}`}>
                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "site_id",
                }) && (
                  <div className={s.InputBox}>
                    <div className={s.input_title}>Site</div>
                    <AsyncSelect
                      noOptionsMessage={() => "No data found"}
                      loadOptions={() => ({
                        options: siteData,
                      })}
                      checkOnHover={setDateWrapperActive}
                      loadingMessage={() => "loading Site ..."}
                      placeholder="Choose Correct site"
                      name="site_id"
                      title={initValues.site_id.label}
                      typeEdit={isEdit}
                    />
                  </div>
                )}

                <div className={s.InputBox}>
                  <div className={s.input_title}>Role</div>
                  <AsyncSelect
                    noOptionsMessage={() => "No data found"}
                    loadOptions={roleOptions}
                    checkOnHover={setDateWrapperActive}
                    loadingMessage={() => "loading Role ..."}
                    placeholder="Choose Correct role"
                    name="role"
                    title={initValues.role.label}
                    typeEdit={isEdit}
                  />
                </div>

                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "details",
                }) && (
                  // !isEdit && (
                  //   <div className={s.InputBox}>
                  //     <Input
                  //       type="text"
                  //       left_icon={<Search className={s.details_svg} />}
                  //       placeholder="Search by Tenant or Unit ID"
                  //       name="details"
                  //       title="Rental Details *"
                  //       typeEdit={isEdit}
                  //     />
                  //   </div>
                  // )
                  <div className={s.InputBox}>
                    <div className={s.input_title}>Rental Details *</div>
                    <AsyncSelect
                      noOptionsMessage={() => "No data found"}
                      loadOptions={loadRentalOptions(
                        getRentalUnitOption(axios)
                      )}
                      loadingMessage={() => "loading ..."}
                      placeholder="Search by User or Unit ID"
                      name="details"
                      title={initValues.details}
                      typeEdit={isEdit}
                    />
                  </div>
                )}
              </div>
              <div className={`${s.lineParams} ${s.lineParams_door}`}>
                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "unit_id",
                }) && (
                  <div className={s.InputBox}>
                    <Input
                      type="text"
                      placeholder="Enter Correct Unit ID"
                      name="unit_id"
                      title="Unit ID"
                      typeEdit={isEdit}
                    />
                  </div>
                )}

                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "doors_numbers",
                }) && (
                  <div className={`${s.InputBox}`}>
                    <div className={s.input_title}>Access (door numbers)</div>
                    <div className={s.door_container}>
                      {!isEdit && (
                        <div
                          onClick={() => addDoors(values)(props.setValues)}
                          className={s.door_add}
                        >
                          +
                        </div>
                      )}
                      <DynamicCreateInputs
                        values={
                          typeof values.doors_numbers === "string"
                            ? getObjectDoorFormat(values.doors_numbers)
                            : values.doors_numbers
                        }
                        changeValue={{ values, setValues: props.setValues }}
                        isEdit={isEdit}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`${s.lineParams} ${s.lineParams_date} ${
                  dateWrapperActive ? s.active : null
                }`}
              >
                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "leased_from",
                }) && (
                  <div className={s.InputBox}>
                    <div className={s.title}>Lease Period (From)</div>
                    <DataPicker
                      placeholderText="Select Lease start date"
                      name="leased_from"
                      dateFormat="yyyy-M-dd"
                      selected={startDate}
                      checkOnHover={setDateWrapperActive}
                      onChangeCustom={setStartDate}
                      startDate={startDate}
                      maxDate={endDate}
                      endDate={endDate}
                      typeEdit={isEdit}
                    />
                  </div>
                )}
                {getViewInputForm({
                  role: valueForm.role.value,
                  nameInput: "leased_to",
                }) && (
                  <div className={s.InputBox}>
                    <div className={s.title}>Lease Periods (To)</div>
                    <DataPicker
                      placeholderText="Select Lease End date"
                      name="leased_to"
                      dateFormat="yyyy-M-dd"
                      checkOnHover={setDateWrapperActive}
                      onChangeCustom={setEndDate}
                      selected={endDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={maxDate}
                      typeEdit={isEdit}
                    />
                  </div>
                )}
              </div>

              <div className="ButtonBox">
                {isEdit ? (
                  <div className={s.groupButton}>
                    <Button
                      text="Save Changes"
                      type="submit"
                      class_type={isSaveChangeParams ? "main" : "disabled"}
                    />
                    <Button
                      left_icon={<Trashcan />}
                      text={"Delete Rental Details"}
                      class_type="red"
                      onClick={() => {
                        onDelete({
                          id: rentalId,
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div className={s.form_btn__wrap}>
                    {isAddMemberPage && (
                      <Button
                        text="Save"
                        class_type="secondary"
                        type="submit"
                      />
                    )}
                    <Button
                      onClick={onRegisterUsers}
                      text={customBtn}
                      type={isOldMember ? "submit" : "button"}
                      class_type={customIsActiveSubmit ? "main" : "disabled"}
                    />
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
