import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const ModalAddMembers = ({...props}: any) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const backToMemberPage = (otherParams: any) => {
    history.push(`/members`);
    dispatch(
      changeShowModal({
        nameModal: 'addMembers',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  const toMemberRentalPage = (otherParams: any) => {
    history.push(`/members/${otherParams['id']}/rental`);

    dispatch(
      changeShowModal({
        nameModal: 'addMembers',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Done! <br /> New user registration was successful
      </div>
      <div className={s.description}>
        We have sent a unique access code to the user's email address.
      </div>
      <div className={s.control}>
        <Button
          text="Go to Member’s page"
          type="submit"
          onClick={() => backToMemberPage(otherParams.memberData)}
          class_type="secondary"
        />
        <Button
          text="Add Rental Details"
          type="submit"
          onClick={() => toMemberRentalPage(otherParams.memberData)}
          class_type="main"
        />
      </div>
    </div>
  );
};
