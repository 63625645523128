import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveFacilities, saveFacilitiesT } from '../../../api';
import { AddNewPromotion } from '../../../components/Forms/AddNewPromotion/AddNewPromotion';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './NewPromotion.module.scss';

export const NewPromotion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = axiosInstance(history);

  const initValues = {
    facilityId: '',
    offer: '',
    promoted_from: '',
    promoted_to: '',
  };

  return (
    <div>
      <div className={s.title}>Add New Promotion</div>
      <div className={s.boxForm}>
        <AddNewPromotion
          initialValues={initValues}
          onSubmit={(props: any) => {
            dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
            const facility: any = props['facilityId'];
            const infoPromotion: saveFacilitiesT = {
              facility_id: facility.item.id,
              offer: Number(props['offer']),
              promoted_from: moment(props['promoted_from']).format('YYYY-M-DD'),
              promoted_to: moment(props['promoted_to']).format('YYYY-M-DD'),
            };
            saveFacilities(axios)({ ...infoPromotion })
              .then((res: any) => {
                dispatch(
                  changeShowModal({
                    nameModal: 'addPromotion',
                    stateModal: true,
                    otherParams: res.data,
                  }),
                );
                dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
              })
              .catch((...e: any) => {
                popUp({ props: e });
                dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
                new Error('Error save Facilities ');
              });
          }}
        />
      </div>
    </div>
  );
};
