import { PromotionFacilitiesT } from '../../store/interface';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  { columnName: 'id', align: 'left' },
  { columnName: 'facility__facility_title', align: 'left' },
  { columnName: 'facility__site__name', align: 'left' },
  { columnName: 'offer', align: 'center' },
  { columnName: 'promoted_from', align: 'left' },
];

export const dataRows: DataRowsT[] = [
  {
    name: 'id',
    title: 'PROMO  ID',
    getCellValue: (row: PromotionFacilitiesT) => row.id,
  },
  {
    name: 'facility__facility_title',
    title: 'Facility Name',
    getCellValue: (row: PromotionFacilitiesT) =>
      `${row.facility.facility_title}`,
  },
  {
    name: 'facility__site__name',
    title: 'SITE',
    getCellValue: (row: PromotionFacilitiesT) => row.facility.site.name,
  },
  {
    name: 'offer',
    title: 'OFFER per hour',
    getCellValue: (row: PromotionFacilitiesT) => row.offer,
  },
  {
    name: 'promoted_from',
    title: 'CAMPAIGN PERIOD',
    getCellValue: (row: PromotionFacilitiesT) =>
      `${row.promoted_from} - ${row.promoted_to}`,
  },
];

export const dataSorting: DataSortingT[] = [
  { columnName: 'id', direction: 'asc' },
];
