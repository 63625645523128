import React from 'react'

export const EditIcon = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0401 9.50553V16.5511H2.94889V2.45995H9.99447C10.2728 2.45995 10.5397 2.34938 10.7365 2.15257C10.9334 1.95576 11.0439 1.68883 11.0439 1.4105C11.0439 1.13217 10.9334 0.865238 10.7365 0.668429C10.5397 0.471619 10.2728 0.361053 9.99447 0.361053H1.89945C1.62112 0.361053 1.35419 0.471619 1.15738 0.668429C0.960567 0.865238 0.85 1.13217 0.85 1.4105V17.6006C0.85 17.8789 0.960567 18.1458 1.15738 18.3426C1.35419 18.5394 1.62112 18.65 1.89945 18.65H18.0895C18.3678 18.65 18.6348 18.5394 18.8316 18.3426C19.0284 18.1458 19.1389 17.8789 19.1389 17.6006V9.50553C19.1389 9.22719 19.0284 8.96026 18.8316 8.76345C18.6348 8.56665 18.3678 8.45608 18.0895 8.45608C17.8112 8.45608 17.5442 8.56665 17.3474 8.76345C17.1506 8.96026 17.0401 9.22719 17.0401 9.50553ZM11.6359 9.34807L11.6359 9.34805L18.8296 2.1543C18.8299 2.15399 18.8303 2.15368 18.8306 2.15336C18.9304 2.05674 19.01 1.94128 19.0648 1.81366C19.1198 1.68562 19.1488 1.54791 19.15 1.40857C19.1512 1.26922 19.1246 1.13103 19.0719 1.00206C19.0191 0.873084 18.9412 0.755911 18.8426 0.657376C18.7441 0.55884 18.6269 0.480916 18.4979 0.428149C18.369 0.375381 18.2308 0.348829 18.0914 0.35004C17.9521 0.35125 17.8144 0.380201 17.6863 0.435201C17.5587 0.490025 17.4432 0.569638 17.3466 0.669419C17.3463 0.669741 17.346 0.670062 17.3457 0.670384L10.1519 7.8641L10.1519 7.86412C9.95519 8.06092 9.84467 8.3278 9.84467 8.60608C9.84467 8.88435 9.95519 9.15124 10.1519 9.34804L10.152 9.34807C10.3488 9.54481 10.6156 9.65533 10.8939 9.65533C11.1722 9.65533 11.4391 9.54481 11.6359 9.34807Z" fill="#A8A8CD" stroke="#A8A8CD" strokeWidth="0.3" />
        </svg>

    )
}
