import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { PrivateRoutes } from '../../../utils/constants/routes';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalAddFacility.module.scss';

export const ModalAddFacility = ({...props}: any) => {
  const {otherParams} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackFacilityList = () => {
    history.push(PrivateRoutes.facilities.list);
    dispatch(
      changeShowModal({
        nameModal: 'addNewFacility',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  const goFacilityPage = (otherParams: any) => {
    history.push(`/facilities/${otherParams['id']}`);
    dispatch(
      changeShowModal({
        nameModal: 'addNewFacility',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };
  return (
    <div className={s.modalBox}>
      <div className={s.title}> Done! </div>
      <div className={s.description}>
        The New Facility page was successfully added
      </div>
      <div className={s.control}>
        <Button
          text="Back to Facilities list"
          type="submit"
          onClick={() => onBackFacilityList()}
          class_type="secondary"
        />
        <Button
          text="Go to Facility page"
          type="submit"
          onClick={() => goFacilityPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
