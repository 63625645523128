import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addHelp, saveFaqT } from '../../../api';
import { AddHelpForm } from '../../../components/Forms/AddHelp/AddHelpForm';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './AddHelp.module.scss';

export const AddHelp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (history: any) => (values: saveFaqT) => {
    dispatch(changeShowModal({nameModal: 'loader', stateModal: true}));
    const axios = axiosInstance(history);
    addHelp(axios)(values)
      .then(async (res: any) => {
        const helpData = await res.data;
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        dispatch(
          changeShowModal({
            nameModal: 'addHelp',
            stateModal: true,
            otherParams: helpData,
          }),
        );
      })
      .catch((...e: any) => {
        popUp({props: e});
        dispatch(changeShowModal({nameModal: 'loader', stateModal: false}));
        new Error('Error save Site ');
      });
  };

  return (
    <div>
      <div className={s.title}>Add new FAQ</div>
      <div className={s.boxForm}>
        <AddHelpForm onSubmit={onSubmit(history)} />
      </div>
    </div>
  );
};
