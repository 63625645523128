import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteRental.module.scss';

type otherParamsT = {
  deleteFunc: any;
  otherValues: {
    id: string;
    refresh: any;
  };
};

export const ModalDeleteRental = ({...props}) => {
  // Create an instance of Notyf
  const notyf = new Notyf();

  const {otherParams} = props;
  const dispatch = useDispatch();
  const history: any = useHistory();

  const onBackRental = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteRentalDetails',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goMemberPage = (otherParams: any) => {
    const {deleteFunc, otherValues}: otherParamsT = otherParams;

    deleteFunc({id: otherValues.id})
      .then(async (res: {data: any}) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        await dispatch(
          changeShowModal({
            nameModal: 'deleteRentalDetails',
            stateModal: false,
            otherParams: undefined,
          }),
        );
        // await history.push('/members/14/rental');
        otherValues.refresh('noType');
      })
      .catch(({...e}: any) => {
        popUp({props: e});
        dispatch(
          changeShowModal({
            nameModal: 'deleteRentalDetails',
            stateModal: false,
          }),
        );
      });
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}>
        {' '}
        Are you sure you want to delete rental details?{' '}
      </div>
      <div className={s.description}>
        This Rental Details will be deleted immediately. You can not undo this
        action{' '}
      </div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onBackRental()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete rental details"
          type="submit"
          onClick={() => goMemberPage(otherParams)}
          class_type="main"
        />
      </div>
    </div>
  );
};
