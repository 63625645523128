import React from 'react';
import s from './Button.module.scss';

type ButtonT = {
  /* eslint-disable camelcase */
  class_type: string;
  text: string;
  left_icon?: JSX.Element;
  right_icon?: JSX.Element;
  type?: 'button' | 'submit';
  onSubmit?: any;
  wide?: string;
  onClick?: any;
  /* eslint-enable camelcase */
};

export const Button = ({type = 'button', ...props}: ButtonT) => {
  // eslint-disable-next-line camelcase
  const {
    class_type: classType,
    wide,
    text,
    left_icon,
    right_icon,
    onClick,
  } = props;

  let containerClass = `${s.button}`;

  switch (classType) {
    case 'main':
      containerClass += ` ${s.main}`;
      break;
    case 'secondary':
      containerClass += ` ${s.secondary}`;
      break;
    case 'disabled':
      containerClass += ` ${s.disabled}`;
      break;
    case 'red':
      containerClass += ` ${s.red}`;
      break;
    case 'green':
      containerClass += ` ${s.green}`;
      break;
    case 'gray':
      containerClass += ` ${s.gray}`;
      break;
    default:
      break;
  }

  if (wide) {
    containerClass += ` ${s.wide}`;
  }

  return (
    <button type={type} onClick={onClick} className={containerClass}>
      <div className={`${s.icon} ${s.left_icon}`}>{left_icon}</div>
      <div className="text">{text}</div>
      <div className={`${s.icon} ${s.right_icon}`}>{right_icon}</div>
    </button>
  );
};
