import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { saveFacilitiesT } from '../../../api';
import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
import { Button } from '../../FormComponents/Button/Button';
import { DataPicker } from '../../FormComponents/DataPicker/DataPicker';
import s from './AddToolsForm.module.scss';
import { toolsOptions } from './dataToolsForm';
import { start } from 'repl';

type AddToolsFormT = {
  initialValues: any;
  onSubmit: any;
  defaultValue?: saveFacilitiesT;
  onDelete?: any;
  type?: string;
  [key: string]: any;
};

const getValidationSchema: any = () => {
  return Yup.object({
    invoices_type: Yup.object().required('The field is required').nullable(),
    sync_from: Yup.string().required('The field is required').nullable(),
    sync_to: Yup.string().required('The field is required').nullable(),
  });
};

export const AddToolsForm = ({...props}: AddToolsFormT) => {
  const {initialValues, onSubmit} = props;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleInvoiceChange: any = (invoice: any, formikProps: any) => {
    var startDate1 = new Date();
    startDate1.setFullYear(startDate1.getFullYear() - 1);
    if(invoice.value == "unpaid") {
      initialValues.sync_from=new Date(startDate1);
      initialValues.sync_to=new Date()
      formikProps.setValues(initialValues)
    }
    else{
      initialValues.sync_from = '';
      initialValues.sync_to = '';
    }
    setStartDate(initialValues.sync_from)
    setEndDate(initialValues.sync_to)
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {({values, ...formikProps}) => {
        return (
          <Form>
            <div className={s.form}>
              <div className={s.InputBox}>
                <div className={s.input_title}>Invoice</div>
                <AsyncSelect
                  noOptionsMessage={() => 'No data found'}
                  loadOptions={toolsOptions}
                  loadingMessage={() => 'loading type ...'}
                  placeholder="Choose type"
                  name="invoices_type"
                  title={initialValues.invoices_type.label}
                  onChange={(value: any) => {
                    initialValues.invoices_type = value;
                    handleInvoiceChange(value, formikProps, initialValues);
                  }}
                />
              </div>
              <div className={s.lineParams}>
                <div className={s.InputBox}>
                  <div className={s.title}>Period start *</div>
                  <DataPicker
                    placeholderText="Select sync start date"
                    name="sync_from"
                    dateFormat="yyyy-M-dd"
                    selected={startDate}
                    onChangeCustom={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    value={initialValues.sync_from}
                  />
                </div>

                <div className={s.InputBox}>
                  <div className={s.title}>Period end *</div>
                  <DataPicker
                    placeholderText="Select sync End date"
                    name="sync_to"
                    dateFormat="yyyy-M-dd"
                    onChangeCustom={setEndDate}
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    value={initialValues.sync_to}
                  />
                </div>
              </div>
              <div className={s.button}>
                <Button text="Submit" type="submit" class_type="main" />
              </div>
            </div>
          </Form>
        );
        
      }}
    </Formik>
  );
};
