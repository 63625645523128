import { AxiosInstance } from 'axios';

export * from './Advertising';
export * from './Facilities';
export * from './Help';
export * from './Member';
export * from './Notification';
export * from './Promotion';
export * from './Sites';
export * from './Tools';
export * from "./Invoices";
export * from "./SyncCreditMemo";

export type getTableDataT = {
    params: string,
    tableAPI: string
}

export const getTableData = (axios: AxiosInstance) => ({ ...props }: getTableDataT) => {
    const { params, tableAPI } = props;
    return axios.get(`${tableAPI}?${params}`);
}

type getNextDataT = {
    link: string
}

export const getNextData = (axios: AxiosInstance) => ({ link }: getNextDataT) => {
    return axios.get(link)
}