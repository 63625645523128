import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import { popUp } from '../../../utils/helpersFunction';
import { Button } from '../../FormComponents/Button/Button';
import s from './ModalDeleteFaq.module.scss';

type otherParamsT = {
  deleteFunc: any;
  values: {
    id: string;
  };
};

export const ModalDeleteFaq = ({...data}) => {
  // Create an instance of Notyf
  const notyf = new Notyf();
  const {props, deleteFunc, refresh} = data.otherParams;
  const id = props.value.props.id;
  const dispatch = useDispatch();
  const history: any = useHistory();

  const onCancel = () => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteFaq',
        stateModal: false,
        otherParams: undefined,
      }),
    );
  };

  const goBackPage = (otherParams: any) => {
    deleteFunc(id)
      .then(async (res: {data: any}) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        await dispatch(
          changeShowModal({
            nameModal: 'deleteFaq',
            stateModal: false,
            otherParams: undefined,
          }),
        );
        if (refresh != undefined) {
          refresh();
        }
      })
      .catch(({...e}: any) => {
        popUp({props: e});
        dispatch(
          changeShowModal({
            nameModal: 'deleteFaq',
            stateModal: false,
          }),
        );
      });
  };

  return (
    <div className={s.modalBox}>
      <div className={s.title}> Are you sure you want to delete this FAQ?</div>
      <div className={s.description}>This FAQ will be deleted immediately.</div>
      <div className={s.control}>
        <Button
          text="No, Cancel"
          type="submit"
          onClick={() => onCancel()}
          class_type="secondary"
        />
        <Button
          text="Yes, Delete FAQ"
          type="submit"
          onClick={() => goBackPage(id)}
          class_type="main"
        />
      </div>
    </div>
  );
};
