import {DataRowsT, DataSortingT, HeaderColumnT} from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  {columnName: 'document_number', align: 'left'},
  {columnName: 'total', align: 'left'},
  {columnName: 'due', align: 'left'},
  {columnName: 'site', align: 'left'},
  {columnName: 'billing_datetime', align: 'right'},
  {columnName: 'is_paid', align: 'right'},
  {columnName: 'payment_datetime', align: 'right'},
];

type paymentHistoryT = {
  document_number: number;
  total: number;
  due: number;
  site: {
    name: string;
  };
  billing_datetime: string;
  is_paid: boolean;
  payment_datetime: string;
  [key: string]: any;
};
export const dataRows: DataRowsT[] = [
  {
    name: 'document_number',
    title: 'INVOICE NUMBER',
    getCellValue: (row: paymentHistoryT) => row?.document_number,
  },
  {
    name: 'total',
    title: 'Amount (S$)',
    getCellValue: (row: paymentHistoryT) => {
      return row?.total?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  },
  {
    name: 'due',
    title: 'Due amount (S$)',
    getCellValue: (row: paymentHistoryT) => {
      return row?.due?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  },
  {
    name: 'site',
    title: 'SITE',
    getCellValue: (row: paymentHistoryT) => row?.site?.name,
  },
  {
    name: 'billing_datetime',
    title: 'BILLING DATE',
    getCellValue: (row: paymentHistoryT) => {
      const text = row?.billing_datetime;
      const to = text?.search('T');
      const result = text?.substring(0, to);
      return result;
    },
  },
  {
    name: 'is_paid',
    title: 'STATUS',
    getCellValue: (row: paymentHistoryT) => {
      let result = '';
      if (row.is_paid) {
        result = 'Paid';
      } else if (!row?.is_paid && row?.is_processing) {
        result = 'Processing';
      } else {
        result = 'Unpaid';
      }
      return result;
    },
  },
  {
    name: 'payment_datetime',
    title: 'PAYMENT DATE',
    getCellValue: (row: paymentHistoryT) => {
      let result;
      if (row?.payment_datetime == null) {
        result = '-';
      } else {
        const text = row?.payment_datetime;
        const to = text?.search('T');
        result = text?.substring(0, to);
      }
      return result;
    },
  },
];

export const dataSorting: DataSortingT[] = [
  {columnName: 'document_number', direction: 'asc'},
];
