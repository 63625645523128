import {DataRowsT, DataSortingT, HeaderColumnT} from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  {columnName: 'document_number', align: 'left'},
  {columnName: 'credit_number', align: 'left'},
  {columnName: 'amouont', align: 'left'},
  {columnName: 'due', align: 'left'},
  {columnName: 'credit_memo_date', align: 'left'},
];

type creditMemoT = {
  document_number: string;
  credit_number: string;
  amouont: number;
  due: number;
  credit_memo_date: string;
  [key: string]: any;
};
export const dataRows: DataRowsT[] = [
  {
    name: 'document_number',
    title: 'INVOICE NUMBER',
    getCellValue: (row: creditMemoT) => row.document_number,
  },
  {
    name: 'credit_number',
    title: 'CREDIT MEMO NUMBER',
    getCellValue: (row: creditMemoT) => row.credit_number,
  },
  {
    name: 'amouont',
    title: 'AMOUNT (S$)',
    getCellValue: (row: creditMemoT) => row.amount
    // {
    //   return row?.amouont?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // },
  },
  {
    name: 'due',
    title: 'DUE AMOUNT (S$)',
    getCellValue: (row: creditMemoT) => row.due
    // {
    //   return row?.amouont?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // },
  },
  {
    name: 'credit_memo_date',
    title: 'CREDIT MEMO DATE',
    // getCellValue: (row: creditMemoT) => row.credit_memo_date,
    getCellValue: (row: creditMemoT) => {
      let result;
      if (row?.credit_memo_date == null) {
        result = '-';
      } else {
        const text = row?.credit_memo_date;
        const to = text?.search('T');
        result = text?.substring(0, to);
      }
      return result;
    },
  },
];

export const dataSorting: DataSortingT[] = [
  {columnName: 'credit_memo_date', direction: 'asc'},
];
