import { AxiosInstance } from 'axios';

export type saveFaqT = {
    question: string,
    response: string
}

export const addHelp = (axios: AxiosInstance) => (props: saveFaqT) => {
    return axios.post('/help/', props)
}

type updateHelpT = {
    question?: string,
    response?: string,
}

export const updateHelp = (axios: AxiosInstance) => (id: number, body: updateHelpT) => {
    return axios.patch(`/help/${id}/`, body)
}

export const deleteFaq = (axios: AxiosInstance) => (id: number) => {
    return axios.delete(`/help/${id}/`)
}