import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeShowModal } from "../../../store/actions/SideMenu";
import { PrivateRoutes } from "../../../utils/constants/routes";
import { popUp } from "../../../utils/helpersFunction";
import { Button } from "../../FormComponents/Button/Button";
import s from "./ModalBookingInfo.module.scss";

type otherParamsT = {
  deleteFunc: any;
  props: any;
  refresh: any;
};

export const ModalBookingInfo = ({ ...props }) => {
  // Create an instance of Notyf
  const { otherParams } = props;

  return (
    <div className={s.modalBox}>
      <div className={s.title}>Detailed Information</div>

      <div className={s.info}>
        <div className={s.info_item}>
          <div className={s.info_name}>Facility Title: </div>
          <div className={s.info_value}>
            {otherParams.props.booking.facility.facility_title}
          </div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Site: </div>
          <div className={s.info_value}>
            {otherParams.props.booking.facility.site.name}
          </div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>User Email:</div>
          <div className={s.info_value}>{otherParams.props.user.email}</div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Booking Date: </div>
          <div className={s.info_value}>{otherParams.props.booking.date}</div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Time Start-End:</div>
          <div
            className={s.info_value}
          >{`${otherParams.props.booking.booked_from}-${otherParams.props.booking.booked_to}`}</div>
        </div>
        <div className={s.info_item}>
          <div className={s.info_name}>Refundable points amount:</div>
          <div className={s.info_value}>{otherParams.props.total}</div>
        </div>
      </div>
    </div>
  );
};
