import { AddsDataT } from '../../store/interface';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';


// wordWrapEnabled: true
export const headerColumn: HeaderColumnT[] = [
  { columnName: 'id', align: 'left' },
  { columnName: 'promoted_from', align: 'left' },
  { columnName: 'title', align: 'left' },
  { columnName: 'site_target', align: 'left' },
];

export const dataRows: DataRowsT[] = [
  {
    name: 'id',
    title: 'ID',
    getCellValue: (row: AddsDataT) => row.id,
  },
  {
    name: 'promoted_from',
    title: 'CAMPAIGN PERIOD',
    getCellValue: (row: AddsDataT) =>
      `${row.promoted_from} - ${row.promoted_to}`,
  },
  {
    name: 'title',
    title: 'PROMO TITLE',
    getCellValue: (row: AddsDataT) => row.title,
  },
  {
    name: 'site_target',
    title: 'SITES LIST',
    getCellValue: (row: AddsDataT) => row.site_target,
  },
];

export const dataSorting: DataSortingT[] = [
  { columnName: 'id', direction: 'asc' },
];
